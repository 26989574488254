<div class="orderModal">
  <app-loader *ngIf="loading.api"></app-loader>
  <form *ngIf="!loading.api">
    <div class="modal-header">
      <h5 class="modal-title">{{ params.title }} <span *ngIf="params?.data?.isPosOrder">(POS Order)</span></h5>

      <div class="btn-grp" *ngIf="params?.data?.status === 'placed'">
        <button [hidden]="!isAcceptRejectAvailable && permissions && permissions.length" (click)="cancelOrder()" class="btn-white next">Decline</button>
        <button [hidden]="!isAcceptRejectAvailable && permissions && permissions.length" *ngIf="params?.data?.type == 'delivery' || params?.data?.type === 'pickup' || params?.data?.type === 'bulkorder' || params?.data?.type === 'curbside'"  (click)="acceptOrder()" class="btn next" >Accept</button>
        <button [hidden]="!isAcceptRejectAvailable && permissions && permissions.length" *ngIf="params?.data?.type === 'reservation' || params?.data?.type === 'dinein'" (click)="markAsReady('reservation')" class="btn next" >Confirm</button>
      </div>

      <!-- if label Process order detail -->
      <div class="header-btn btn-grp" *ngIf="params?.data?.status !== 'placed'">
        <!-- <a *ngIf="params?.data?.status !== 'placed'" href="javascript: //" class="receiptIcon"><span><img class="img-fluid" src="assets/images/receiptIcon.svg" /></span>View
          Order Receipt</a> -->

        <!-- <a *ngIf="params?.data?.status !== 'placed'" href="javascript: //" class="btn-white icon"><span class="Ricon"><img class="img-fluid"
              src="assets/images/helpIcon1.svg" /></span> Help</a> -->
        <a  data-toggle="modal" data-target="#yesNo" href="javascript:void(0);" (click)="cancelOrder()" class="btn next">Cancel</a>
        <a *ngIf="params?.data?.status === 'preparing'" data-toggle="modal" data-target="#yesNo" href="javascript:void(0);" (click)="markAsReady('ready')" class="btn next">Mark Ready</a>
        <a *ngIf="params?.data?.status === 'packed' && (params?.data.type !== 'dinein' && params?.data.type !== 'reservation')" data-toggle="modal" data-target="#yesNo" href="javascript:void(0);" (click)="markAsReady('picked')" class="btn next">Mark as Picked</a>
        <a *ngIf="params?.data?.status === 'packed' && (params?.data.type === 'dinein' || params?.data.type === 'reservation')" data-toggle="modal" data-target="#yesNo" href="javascript:void(0);" (click)="markAsReady('served')" class="btn next">Mark as Served</a>
        <a *ngIf="params?.data?.status === 'picked'" data-toggle="modal" data-target="#yesNo" href="javascript:void(0);" (click)="markAsReady('delivered')" class="btn next">Mark as Delivered</a>
      </div>
      <!-- *ngIf="params?.data?.type === 'dinein' || params?.data?.type === 'reservation'" -->
      <!-- till here -->
    </div>

    <div class="modal-body">

      <div class="orderBody">
        <div class="OrderNumberBody">
          <h5>#{{params?.data?.orderId}} <span class="OrderTime">
              {{params?.data?.createdAt | date: 'fullDate'}} {{params?.data?.createdAt | date:' HH:mm a'}}
            </span></h5>
            <div *ngIf="params?.data?.type !== 'reservation'">
              <label class="orderLabel lg"
              [ngClass]="{'scheduled': params?.data?.isScheduled, 'placed': params?.data?.status === 'placed', 'pending': params?.data?.status === 'pending', 'preparing': params?.data?.status === 'preparing',
              'packed': params?.data?.status === 'packed', 'transit': params?.data?.status === 'transit', 'picked': params?.data?.status === 'picked', 'delivered': params?.data?.status === 'delivered',
              'cancelled': params?.data?.status === 'cancelled'}">
                {{ params?.data?.status.toUpperCase() }}</label>
            </div>
            <div *ngIf="params?.data?.type === 'reservation'">
              <label class="orderLabel lg"
              [ngClass]="(params?.data?.reservation.status)? 'order' + params?.data?.reservation.status.toLowerCase() : 'orderUnknown'">
                {{ params?.data?.reservation?.status.toUpperCase() }}</label>
            </div>
        </div>

        <!-- if label Process order detail -->
        <div class="riderInfo" *ngIf="params?.data?.status === 'isProcess'">
          <div class="orderRider">
            <div class="riderImg"><img class="img-fluid" src="images/rider.png"></div>
            <div class="riderText">
              <div class="riderName">Jane Cooper</div>
              <span>Rider Assigned</span>
            </div>
          </div>
          <!-- till here -->

          <div class="riderPhone">
            <span class="phoneIcon"><img class="img-fluid" src="assets/images/phoneIcon.svg"></span>
            <p class="text mb-0">Call to Rider</p>
            <p class="mb-0 f14">+971 123 7637 738</p>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type == 'delivery'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Delivery Address</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Customer contact</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>

          <div class="custContact" *ngIf="params?.data?.isScheduled">
            <p class="sm mb-0">Scheduled</p>
            <p class="text mb-0">{{params?.data?.estimateDelivery | date: 'fullDate'}} {{params?.data?.estimateDelivery
              | date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.name">
            <p class="sm mb-0">Assigned Rider Name</p>
            <p class="text mb-0">{{ params?.data?.rider?.name }}</p>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.phone">
            <p class="sm mb-0">Assigned Rider Number</p>
            <p class="text mb-0">({{params?.data?.rider?.phoneCode}}) {{ params?.data?.rider?.phone }}</p>
          </div>

        </div>


        <div class="orderAddress" *ngIf="params?.data?.type == 'dinein'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">No. of guests</p>
            <p class="text mb-0">{{ params?.data?.dineIn?.guests }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Table Number</p>
            <p class="text mb-0">{{ params?.data?.dineIn?.tableNumber }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash" ><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type == 'reservation'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.reservation?.fullName }}</p>
          </div>

           <div class="custContact">
            <p class="sm mb-0">Email Id</p>
            <p class="text mb-0">{{params?.data?.reservation?.email}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.reservation?.phoneCode}}) {{ params?.data?.reservation?.phoneNumber }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">No. of guests</p>
            <p class="text mb-0">{{ params?.data?.reservation?.guests }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Reservation Date</p>
            <p class="text mb-0"> {{params?.data?.reservation?.selectedTime | date: 'fullDate'}} {{params?.data?.reservation?.selectedTime | date:' HH:mm a' : 'UTC'}}</p>
          </div>
          
          <div class="deliveryAddress">
            <p class="sm mb-0">Reservation Till Date</p>
            <p class="text mb-0"> {{params?.data?.reservation?.reservedTill | date: 'fullDate'}} {{params?.data?.reservedTill?.selectedTime | date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type == 'bulkorder'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Address</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Type</p>
            <p class="text mb-0">{{params?.data?.bulkType | titlecase}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Delivery Time</p>
            <p class="text mb-0">{{params?.data?.deliveryTime | date: 'fullDate'}} {{params?.data?.deliveryTime | date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>

            <div class="custContact" *ngIf="params?.data?.rider?.name">
            <p class="sm mb-0">Assigned Rider</p>
            <p class="text mb-0">{{ params?.data?.rider?.name }}</p>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.phone">
            <p class="sm mb-0">Assigned Rider Number</p>
            <p class="text mb-0">({{params?.data?.rider?.phoneCode}}) {{ params?.data?.rider?.phone }}</p>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type == 'pickup'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <!-- <div class="deliveryAddress">
            <p class="sm mb-0">Address</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div> -->

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
        </div>


        <div class="orderAddress" *ngIf="params?.data?.type == 'curbside'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Parking number</p>
            <p class="text mb-0">{{params?.data?.curbside?.parkingNumber}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Vehicle number</p>
            <p class="text mb-0">{{ params?.data?.curbside?.vehicleNumber }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Vehicle color</p>
            <p class="text mb-0">{{ params?.data?.curbside?.vehicleColor }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Vehicle model</p>
            <p class="text mb-0">{{ params?.data?.curbside?.vehicleModel }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
          
        </div>

        <h5 *ngIf="params?.data?.items && params?.data?.items.length">Order Details</h5>
        <div class="orderSum borderDetail" *ngFor="let item of params?.data?.items">
          <div class="orderSumIn">
            <div class="itemName" [ngClass]="{'Veg': item.isVeg, 'nonVeg': !item.isVeg}">
              <p>{{item?.name}} <span>{{item?.description}}</span></p>
            </div>

            <div class="itemQuatity">
              <p>x{{ item?.quantity }}</p>
            </div>

            <div class="itemPrice">
              <p>{{currencycode}} {{item?.basePrice | number : '1.2-2'}}</p>
            </div>
          </div>

          <!-- <div class="orderExtras">
            <span >Quantity</span> 
            <p>Full <span></span></p>
          </div> -->


          <div class="orderExtras" *ngFor="let modifier of item.modifierGroups">
            <span class="extraH">{{modifier?.name}}</span>
            <p *ngFor="let mod of modifier?.modifiers">
              <span class="modifiers-title">{{mod?.name}}</span>
              <span class="extraH">x{{mod.quantity}}</span>
              <span>{{currencycode}} {{mod?.price | number : '1.2-2'}}</span></p>
          </div>
        </div>

        <div class="instructionSec borderDetail">
          <p class="f14 text mb-1">Cooking instructions</p>
          <div class="instructionDiv"><span><img class="img-fluid" src="assets/images/quotes.svg" /></span>
            {{params?.data?.notes || 'No Instructions' }}</div>
        </div>

        <div class="instructionSec borderDetail">
          <p class="f14 text mb-1">{{params?.data?.type | titlecase}} instructions</p>
          <div class="instructionDiv"><span><img class="img-fluid" src="assets/images/quotes.svg" /></span>
            {{params?.data?.instructions || 'No Instructions' }}</div>
        </div>

        <div class="instructionSec borderDetail" *ngIf="params?.data?.isCuterly">
          <p class="f14 text mb-1">Cutlery</p>
          <div class="instructionDiv"><span><img class="img-fluid" src="assets/images/quotes.svg" /></span>
            {{params?.data?.isCuterly }}</div>
        </div>


        <div class="subtotal borderDetail" *ngIf="params?.data?.items && params?.data?.items.length">
          <p class="text">Subtotal <span>{{currencycode}} {{params?.data?.subTotal | number : '1.2-2'}}</span></p>

          <div class="subtotalIn">
            <!-- <p>Packing charges <span>{{currencycode}} {{params?.data?.subTotal}}</span></p> -->
            <p *ngIf="params?.data?.type === 'delivery'">Delivery charges <span>{{currencycode}} {{params?.data?.totalDelivery | number : '1.2-2'}}</span></p>
            <p class="discount" *ngIf="params?.data?.totalDiscount">Discount <span>- {{currencycode}} {{params?.data?.totalDiscount | number : '1.2-2'}}</span></p>
            <p>Extra Charges <span>{{currencycode}} {{params?.data?.totalCharges | number : '1.2-2'}}</span></p>
            <p>Tax <span>{{currencycode}} {{params?.data?.totalTaxes | number : '1.2-2'}}</span></p>
          </div>
        </div>

        <div class="totalDiv borderDetail" *ngIf="params?.data?.items && params?.data?.items.length">
          <div class="totalDivIn">
            <h4>Total <span class="info">(Inclusive of all taxes)</span></h4>
            <h4>{{currencycode}} {{params?.data?.total| number : '1.2-2'}}</h4>
          </div>
        </div>

        <div class="paymentType">
          <p class="text">Payment type <span class="text-green">{{ params?.data?.payMethod == 'cod' ? 'COD' :
              params?.data?.payMethod == 'card' ? 'CARD' :
              params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : params?.data?.payMethod == 'offline' ? 'OFFLINE' : '---'
              }}</span></p>
        </div>
      </div>
    </div>
  </form>
</div>
export const API_URL = {
  // user
  _POST_LOGIN: 'vendor/login',
  _POST_FORGOT_PASSWORD: 'vendor/forgotPassword',
  _POST_RESET_PASSWORD: 'vendor/resetPassword',
  _POST_CHANGE_PASSWORD: 'vendor/changePassword',
  _GET_ALL_NOTIFICATIONS: 'vendor/notifications',
  _POST_NOTIFICATION_READ: 'vendor/notificationRead',
  _GET_PROFILE_DETAILS: 'vendor/profile',
  _GET_ALL_BRANDS: 'vendor/brands',
  _GET_ANALYTICS: 'vendor/analytics',
  _GET_ANALYTICS_EARNINGS: 'vendor/analytics/earnings',
  _POST_CREATE_BRAND: 'vendor/brand',
  _GET_CURRENCY: '',
  _POST_IMAGE_UPLAOD: 'common/uploadFile',
  _GET_OUTLETS: 'vendor/outlets',
  _POST_OUTLET_SERVICES: 'vendor/outlet/services',
  _POST_OUTLET_PAYMENTS: 'vendor/outlet/payment',
  _POST_OUTLET_IMAGES: 'vendor/outlet/images',
  _POST_OUTLET_HOURS: 'vendor/outlet/hours',
  _POST_OUTLET_DETAILS: 'vendor/outlet/details',
  _POST_OUTLET_ADDRESS: 'vendor/outlet/address',
  _POST_DELIVERY_SETTINGS: 'vendor/deliverySetting',
  _GET_DELIVERY_SETTINGS: 'vendor/deliverySettings',
  // _POST_EDIT_DELIVERY_SETTINGS: 'vendor/editDeliverySettings',
  _POST_PICKUP_SETTINGS: 'vendor/pickupSetting',
  _GET_PICKUP_SETTINGS: 'vendor/pickupSettings',
  // _POST_EDIT_PICKUP_SETTINGS: 'vendor/editPickupSettings',
  _GET_ALL_LANGUAGES: 'common/languages',
  _POST_DELETE_RECORD: 'vendor/deleteRecord',
  _POST_UPDATE_STATUS: 'vendor/updateStatus',
  _GET_ALL_CUISINES: 'vendor/cuisines',
  _GET_ALL_SERVICES: 'vendor/services',
  _GET_COUNTRY_CODE: 'common/countries',
  _GET_ALL_PAYMENT_METHODS: 'common/paymentMethods',
  _GET_BULK_ORDER_SETTINGS: 'vendor/bulkOrderSettings',
  _POST_BULK_ORDER_SETTINGS: 'vendor/bulkOrderSetting',
  // _POST_EDIT_BULK_ORDER_SETTINGS: 'vendor/editBulkOrderSetting',
  _GET_RESERVATION_SETTINGS: 'vendor/reservationSettings',
  _POST_RESERVATION_SETTINGS: 'vendor/reservationSetting',
  // _POST_EDIT_RESERVATION_SETTINGS: 'vendor/editReservationSetting',
  _GET_ALL_SETTINGS: 'vendor/allSettings',
  _GET_CUSTOMERS: 'vendor/customers',
  _GET_ROLES: 'vendor/roles',
  _POST_ROLE: 'vendor/role',
  _GET_ALL_PERMISSIONS: 'vendor/permissions',
  _GET_WALLET_DATA: 'vendor/customer/wallets',
  _POST_WALLET_DATA: 'vendor/customer/wallet',
  _GET_USER_VIEW_TRANSACTION: 'vendor/wallet/transactions',
  _POST_SALES_CHANNEL: 'vendor/saleChannel',
  _GET_SINGLE_SALES_CHANNEL: 'vendor/saleChannel',
  _GET_ALL_SALES_CHANNEL: 'vendor/saleChannels',
  _GET_ALL_USERS: 'vendor/managers',
  _POST_USER: 'vendor/manager',
  _GET_ALL_REGIONS: '/vendor/regions',
  _POST_REGIONS: '/vendor/region',
  _GET_DEALS: 'vendor/deals',
  _POST_DEALS: 'vendor/deal',
  _GET_BLOGS: 'vendor/blogs',
  _GET_CHARGES: 'vendor/charges',
  _GET_CATEGORIES: 'vendor/categories',
  _POST_CREATE_CHARGE: 'vendor/charge',
  _GET_ALL_AREAS: 'vendor/areas',
  _POST_AREA: 'vendor/area',
  _GET_ALL_TAXES: 'vendor/taxes',
  _POST_CATEGORY: 'vendor/category',
  _GET_CATEGORY: 'vendor/categories',
  _POST_TAX: 'vendor/tax',
  _GET_DATA_AFTER_REORDERING: 'vendor/deal/reorder',
  _GET_MENU_CATEGORY_LIST: 'vendor/menu/categories',
  _POST_MENU_CATEGORY: 'vendor/menu/category',
  _GET_MENU_ITEMS: 'vendor/menu/items',
  _POST_MENU_ITEM: 'vendor/menu/item',
  _GET_MENU_MODIFIERS: 'vendor/menu/modifiers',
  _POST_MENU_MODIFIER: 'vendor/menu/modifier',
  _GET_ALL_MENUS: 'vendor/menus',
  _POST_ASSIGN_MENU_ITEM: 'vendor/menu/assignItem',
  _GET_CASHBACK_SETTINGS: 'vendor/cashbackSettings',
  _POST_CASHBACK_SETTING: 'vendor/cashbackSetting',
  _GET_VIP_CARD: 'vendor/vipcards',
  _POST_VIP_CARD: 'vendor/vipcard',
  _GET_OUTLET_MENUS: 'vendor/outlet/menus',
  _POST_OUTLET_MENU_TIME: 'vendor/outlet/menu',
  _POST_OUTLET_EDIT_MENU_ITEM: 'vendor/outlet/menuItemUpdate',
  _POST_OUTLET_MENU_ITEM_STATUS: 'vendor/outlet/menuItemStatus',
  _POST_MENU_COPY: 'vendor/menu/copy',
  _GET_USER_GROUP: 'vendor/userGroups',
  _POST_USER_GROUP: 'vendor/userGroup',
  // _GET_LOYALTY_POINTS: 'vendor/loyalitySettings',
  _POST_LOYALTY_POINTS: 'vendor/loyalitySettings',
  _POST_DISCOUNT_SETTINGS: 'vendor/discount',
  _GET_DISCOUNT_SETTINGS: 'vendor/discounts',
  _GET_BRANDING: 'vendor/branding',
  _POST_BRANDING: 'vendor/branding',
  _GET_ALL_ORDERS: 'vendor/orders',
  _POST_CHANGE_ORDER_STATUS: 'vendor/order/status',
  _POST_ORDER_PREPARE_TIMING: 'vendor/order/prepareTime',
  _POST_ORDER_REFUND: 'vendor/order/refund',
  _GET_DASHBORD_DATA: 'vendor/dashboard',
  _GET_ALL_CASHBACK: 'vendor/customer/cashbacks',
  _GET_ALL_LOYALTY_POINTS: 'vendor/customer/loyaltyPoints',
  _GENERAL_SETTING: 'vendor/settings',
  _GET_ALL_MARKETING_REQUESTS: 'vendor/marketings',
  _POST_MARKETING_REQUEST: 'vendor/marketing',
  _POST_CASH_COLLECT: 'vendor/order/cashCollect',
  _GET_FAQS: 'vendor/faqs',
  _GET_LOYALTY_POINT_TIER: 'vendor/loyaltyTiers',
  _GET_LOYALTY_EARNING_PATTERNS: 'vendor/loyalityEarningPatterns',
  _GET_USER_GROUPS: 'vendor/userGroups',
  _POST_USER_GROUPS: 'vendor/userGroup',
  _GET_OUTLET_FORM: 'vendor/outlet/form',
  _POST_INITIAL_SETTINGS: 'vendor/generalSetting',
  _GET_RIDERS: 'vendor/riders',
  _POST_RIDER: 'vendor/rider',
  _POST_RIDER_AVAILABILITY: 'vendor/rider/availability',
  _POST_ASSIGN_RIDER: 'vendor/rider/assign',
  _POST_IS_RIDER_FREE: 'vendor/rider/free',
  _POST_GENERAL_SETTINGS: 'vendor/outlet/generalSettings',
  _GET_QUIQUP_JOBS: 'vendor/quiqup/retrieveJob',
  _POST_QUIQUP_JOBS: 'vendor/quiqup/createJob',
  _GET_DAILY_SALES: 'vendor/dailySaleReport',
  _GET_CATEGORY_WISE_SALE: 'vendor/categoryWiseSalesReport',
  _GET_PROMOCODE: 'vendor/promoCodeUsageReport',
  _GET_COUPONS: 'vendor/coupons',
  _GET_DISCOUNT_USED: 'vendor/discountUsed',
  _GET_TRANSACTION_REPORTS: 'vendor/transactionReport',
  _GET_MOSTLEASTSELLINGS: 'vendor/mostToLeastSellingDishes',
  _GET_LOYALITY_SUMMARY: 'vendor/loyalitySummaryReport',
  _GET_LOYALITY_HISTORY: 'vendor/loyaltyHistory',
  _GET_SALES_REPORT: 'vendor/salePerService',
  _GET_NEW_VS_OLD_SERVICE : 'vendor/newVsOldCustomer',
  _GET_SALES_VS_ORDER : 'vendor/totalSalesVsTotalOrders',
  _GET_DISCOUNT_VS_SALES: 'vendor/discountVsSales',
  _GET_DISH_CATEGORY: 'vendor/dishCategories',
  _GET_PER_HOUR_SALE: 'vendor/hourlySales',
  _GET_DISCOUNT_REPORT: 'vendor/discountReport',
  _GET_TOP_TEN_EARNERS: 'vendor/topEarners',
  _GET_TOP_TEN_REDEEMERS: 'vendor/topReedemers',
  _GET_LOYALITY_DASHBOARD: 'vendor/loyalityDashboard',
  // _POST_OUTLET_GENERAL_SETTINGS: 'vendor/outlet/generalSettings',
  _GET_LEAD_INFO: '/market/renewPurchasePlanLink',
  _POST_LOGOUT: 'vendor/logout'
};
import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { SearchService } from 'src/app/shared/search.service';
import { DeclineScheduledOrderPopupComponent } from '../decline-scheduled-order-popup/decline-scheduled-order-popup.component';

@Component({
  selector: 'app-mark-status-ready-popup',
  templateUrl: './mark-status-ready-popup.component.html',
  styleUrls: ['./mark-status-ready-popup.component.scss']
})
export class MarkStatusReadyPopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService,
    private toastrService: ToastrService,
    private popupService: BsModalService,
    private searchService: SearchService,
    private helperService: HelperService
  ) { }

  params;
  loading = {api: false, form: false};

  ngOnInit(): void {
  }

  MarkAsReadStatus() {
    this.loading.form = true;
    const url = API_URL._POST_CHANGE_ORDER_STATUS;
    let params: any = {
      id: this.params?.id,
    }

    if(this.params?.status === 'preparing'){
      params.status = 'packed';
    }else if((this.params?.status === 'packed' && this.params?.type === 'pickup') || (this.params?.status === 'packed' && this.params?.bulkType === 'takeaway') || 
    (this.params?.status === 'packed' && this.params?.type === 'dinein') || (this.params?.status === 'packed' && this.params?.type === 'reservation')){
      params.status = 'completed';
    }else if(this.params?.status === 'packed'){
      params.status = 'picked';
    }
    else if(this.params?.status === 'placed'){
      params.status = 'preparing';
    }else{
      params.status = 'completed';
    }

    this.httpRequestService.postData(url, params).subscribe(
      (resp: any) => {
        this.loading.form = false;
        if(this.params?.status === 'preparing'){
          this.toastrService.success('Status Marked as Packed');
          this.helperService.refreshApi({status: 'packed'});
        }else if((this.params?.status === 'packed' && this.params?.type === 'pickup') || (this.params?.status === 'packed' && this.params?.bulkType === 'takeaway') || 
        (this.params?.status === 'packed' && this.params?.type === 'dinein') || (this.params?.status === 'packed' && this.params?.type === 'reservation')){
          this.toastrService.success('Status Marked as Completed');
          this.helperService.refreshApi({status: 'completed'});
        }else if(this.params?.status === 'packed'){
          this.toastrService.success('Status Marked as Picked');
          this.helperService.refreshApi({status: 'picked'});
        }else if(this.params?.status === 'placed'){
          this.toastrService.success('Status Marked as Accepted');
          this.helperService.refreshApi({status: 'placed'});
        }else{
          this.toastrService.success('Status Marked as Delivered');
          this.helperService.refreshApi({satus: 'completed'});
        }
        this.popupService.hide();
        this.popupService.hide(this.params?._id);
      }, (error) => {
        this.loading.form = false;
      }
    );
  }

  cancel(){
    this.popupService.hide();
    // const initialState = {
    //   params: {...{id: this.params.id, status: this.params?.status}},
    // };
    // this.popupService.show(DeclineScheduledOrderPopupComponent,
    //   {id: 16, animated: true, class: 'cancelPop', initialState: {...initialState}});
    // this.popupService.hide(18);
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { API_URL, HttpRequestService } from 'src/app/core';
import { ListingTableSettings } from '@app/directives/listing-table/listing-table.model';

@Component({
  selector: 'loyality-history-popup',
  templateUrl: './loyality-history-popup.component.html',
  styleUrls: ['./loyality-history-popup.component.scss']
})
export class LoyaltyHistoryPopup implements OnInit {

  params: any;
  allCounts: any;
  allRecords: any = [];
  loading: any = { api: true };
  tableSettings: ListingTableSettings = {
    static: false,
    perpage: 10,
    canAdd: false,
    canView: false,
    canEdit: false,
    filterRows: true,
    canDelete: false,
    canManageFund: false,
    canViewTransactions: false,

    columns: [
      // { id: 1, name: 'userName', label: 'User', sort: true, display: 'text' },
      { id: 2, name: 'orderId', label: 'Order ID', sort: true, display: 'text' },
      { id: 3, name: 'typeName', label: 'Type', sort: true, display: 'text' },
      { id: 3, name: 'points', label: 'Points', sort: true, display: 'number' },
      { id: 4, name: 'expiryDate', label: 'Expiry Date', sort: true, display: 'date' },
      { id: 5, name: 'createdAt', label: 'Processed Date', sort: true, display: 'date' },
    ],
  };

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService) { }

  ngOnInit(): void {
    this.getLoyalityHistory({});
  }

  // get loyalty history
  getLoyalityHistory(params) {
    this.loading.api = true;
    const url = API_URL._GET_LOYALITY_HISTORY;
    params = { user: this.params.userId, ...params };
    // params.brand = this.brandId;
    this.httpRequestService.getData(url, params).subscribe(
      (resp: any) => {
        this.allRecords = this.parseRecords(resp?.data?.data);
        this.allCounts = { count: resp.data?.total };
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    );
  }

  parseRecords(records) {
    return records.map(_rec => {
      _rec.userName = _rec.user.name;
      _rec.typeName = (_rec.type) ? _rec.type.toUpperCase() : 'NA';
      _rec.orderId = (_rec.orderId)? `#${_rec.orderId}` : 'NA';
      return _rec;
    })
  }

  refreshRecords(_applied) {
    const params = {
      skip: _applied.skip,
      limit: _applied.limit
    };
    this.loading.api = true;
    this.getLoyalityHistory(params);
  }

  close(){
    this.popupService.hide();
  }

}

<div>
  <div class="modal-body">
    <form class="yesNoBody">
      <h5 *ngIf="params?.title !== 'reservation'" class="fw500">Do you want to set your order status {{this.params?.title}}?</h5>
      <h5 *ngIf="params?.title === 'reservation'" class="fw500">Do you want to confirm this reservation?</h5>
      <!-- <h5 *ngIf="params?.title === 'reservation'" class="fw500">Do you want to confirm this reservation?</h5> -->
      <div class="btn-grp">
        <button type="button" class="btn sm" [disabled]="loading.form" [ngClass]="{'loader': loading.form}" (click)="MarkAsReadStatus()">Yes</button>
        <button type="button" class="btn-white sm" (click)="cancel()">No</button>
      </div>
    </form>
  </div>
</div>
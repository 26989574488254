import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { ConstantPool } from '@angular/compiler';

/*
 * Custom Form Validator
 */
export class FormValidator {

  // if field is empty
  static isEmpty(control: AbstractControl) {
    if (!control.value || (control.value && '' === control.value.trim())) {
      return { required: true };
    }
    return null;
  }

    static maxLength(max: number): ValidatorFn | any {
      return (control: AbstractControl[]) => {
          if (!(control instanceof FormArray)) return;
          return control.length > max ? { maxLength: true } : null;
      }
  }

  static matchPassword(control: AbstractControl) {
    const password = control.get('password').value; // to get value in input tag
    const confirmPassword = control.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
      control.get('confirmPassword').setErrors({ matchPassword: true });
    } else {
      return null;
    }
  }

  // if is valid email
  static validEmail(control: AbstractControl) {
    const EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;
    if (control.value && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { invalidEmail: true };
    }
    return null;
  }

  // valid mobile number for 9 digits
  // static validPhone(control: AbstractControl) {
    
  //   const PHONE_REGEXP = /(?!0{6})\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
  //   if (control.value && !PHONE_REGEXP.test(control.value)) {
      
  //     return { invalidPhone: true };
  //   }
  //   return null;
  // }

  // valid mobile number for 10 digits
  static validPhone(control: AbstractControl) {
    const PHONE_REGEXP = /(?!0{6})\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (control.value && !PHONE_REGEXP.test(control.value)) {
      return {invalidPhone: true};
    }
    return null;
  }

  // validate password
  static validPassword(control: AbstractControl) {
    const PASS_REGEXP = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=<>]).*$/;
    if (control.value && !PASS_REGEXP.test(control.value)) {
      return { invalidPass: true };
    }
    return null;
  }

  // only numbers allowed
  static onlyNumbers(event) {
    // skip hyphen (-)
    const pattern = /^-?(0|[1-9]\d*)?$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode == 45 || !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // only numbers allowed which also includes decimal
  static onlyNumbersWithDecimal(event) {
    const pattern = /^-?(.|0|[1-9]\d*)?$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode == 45 || !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

   // only numbers allowed which also includes decimal
   static onlyNumbersWithHyphen(event) {
    const pattern = /^[-0-9]+/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // only letters
  static onlyLetters(event) {
    // skip hyphen (-)
    const pattern = /^[a-zA-Z -]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode == 45 || !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // only valid website
  static validWebsite(control: AbstractControl) {
    const URL_REGEXP = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (control.value && !URL_REGEXP.test(control.value)) {
      return {invalidWebsite: true};
    }
    return null;
  }

  // format card number
  static formatCardNumber(value) {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = value.replace(/[^\d]/g, '')
    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter(group => !!group).join(' ')
    )
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactThankyouComponent} from './contact-thankyou.component';
import { CNavBarModule } from '../../common/cnav-bar/cnav-bar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectPickerModule } from '../../directives/select-picker/select-picker.module';

@NgModule({
  declarations: [
    ContactThankyouComponent
  ],
  imports: [
    CommonModule,
    CNavBarModule,
    FormsModule,
    ReactiveFormsModule,
    SelectPickerModule
  ],
  exports: [
    ContactThankyouComponent
  ]
})
export class ContactThankYourModule {
}

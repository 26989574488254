import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  notify = this.socket.fromEvent<string[]>('notify');
  newOrder = this.socket.fromEvent<string[]>('newOrder');

  constructor(
    private socket: Socket
  ) {}

  disconnectNotifySocket(){
    this.socket.disconnect();
  }
}

<!-- The Modal -->
<!-- <div class="modal manageFunds fade" id="Transactions"> -->
    <div class="InvoicesModal">
      <!-- <div class="modal-content"> -->
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 class="modal-title">ID: {{ params.id }}</h5>
          <button type="button" class="close" (click)="close()">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <div class="transactionBody">
            <table>
              <thead>
                <tr>
                  <th>INVOICE ID</th>
                  <th>PLAN</th>
                  <th>PAID</th>
                  <th>DATE</th>
                  <th></th>
                </tr>
              </thead>
  
              <tbody>
                <tr *ngFor="let transaction of allTransactions">
                    <td>{{ transaction.invoiceId }}</td>
                    <td>{{ transaction.plan }}</td>
                    <td>{{currencycode}} {{ transaction.paid }}</td>
                    <td>{{ transaction.date }}</td>
                    <td><div class="dlCSV"><a href="javascript: //">Download CSV</a></div></td>
                </tr>
            
              </tbody>
            </table>
          </div>
        </div>
  
  
      <!-- </div> -->
    </div>
  <!-- </div> -->
<div>
    <div class="modal-body">
      <form class="yesNoBody">
        <h5 class="fw500">Do you want to make it {{this.params?.isActive ? 'Online' : 'Offline'}}?</h5>
        <div class="btn-grp">
          <button type="button" class="btn sm" (click)="yes()" >Yes</button>
          <button type="button" class="btn-white sm" (click)="cancel()">No</button>
        </div>
      </form>
    </div>
</div>


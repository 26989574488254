import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { FormValidator } from 'src/validators';

@Component({
  selector: 'app-accept-scheduled-order-popup',
  templateUrl: './accept-scheduled-order-popup.component.html',
  styleUrls: ['./accept-scheduled-order-popup.component.scss']
})
export class AcceptScheduledOrderPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private formBuilder: FormBuilder,
    // private toastrService: ToastrService,
    private helperService: HelperService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    time: new FormControl('', [Validators.compose([Validators.required])]),
    timeType: new FormControl('', [Validators.compose([Validators.required])])
  }, { validators: this.timeValidation.bind(this) });

  loading = { api: false, form: false };
  params;
  statusArray: any[] = [
    {
      name: 'Minutes',
      value: 'MINUTES'
    },
    {
      name: 'Hours',
      value: 'HOURS'
    },
    {
      name: 'Days',
      value: 'DAYS'
    }
  ];

  ngOnInit(): void {
    const outlet = JSON.parse(localStorage.getItem('_outlet'));
    const outletId = localStorage.getItem('OutletId');
    if (outlet && outlet.length && outletId) {
      const foundData = outlet.find(outlet => outlet?._id == outletId);
      if (foundData) {
        this.formGroup.get('time').patchValue(parseInt(foundData?.prepareTime?.split('-')?.[0]) ?? 0);
      }
    }
    this.formGroup.get('timeType').patchValue('MINUTES');

  }

  onlyNumbers(event) {
    FormValidator.onlyNumbers(event);
  }

  increment(data) {
    const value = (isNaN(parseInt(data)) ? 0 : parseInt(data)) + 5;
    this.formGroup.get('time').patchValue(value);
    // const formValue = this.formGroup.get('time').value;
    // return formValue + 1;
  }

  decrement(data) {
    const value = (isNaN(parseInt(data)) ? 0 : parseInt(data)) - 5;
    value > -1 && this.formGroup.get('time').patchValue(value);
  }

  timeValidation(control: AbstractControl) {
    const time = control.get('time').value;
    if (time > 1000) {
      control.get('time').setErrors({ maxValue: true });
    } else if (time < 1) {
      control.get('time').setErrors({ minValue: true });
    } else {
      control.get('time').setErrors(null);
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_ORDER_PREPARE_TIMING;
      const payload = this.formGroup.value;
      payload.id = this.params?.id;
      this.httpRequestService.postData(url, payload).subscribe(
        (resp: any) => {
          this.loading.form = false;
          this.helperService.refreshApi({ status: 'preparing' });
          // let isSocketTrue = JSON.parse(localStorage.getItem('isSocket'));
          localStorage.setItem('isSocket', 'false');
          this.popupService.hide();
          this.popupService.hide(this.params?.orderId);
        }, (error) => {
          this.loading.form = false;
        }
      )
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

}

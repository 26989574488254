import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  logger: boolean = environment.logger;

  log(...message: any): void {
    if (this.logger) {
    }
  }

  // console info message
  info(...message: any): void {
    if (this.logger) {
    }
  }

  // console warn message
  warn(...message: any): void {
    if (this.logger) {
    }
  }
}



import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cnav-bar',
  templateUrl: './cnav-bar.component.html',
  styleUrls: ['./cnav-bar.component.scss']
})
export class CNavBarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  logIn() {
    this.router.navigate(['/login']);
  }

}

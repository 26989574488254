import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BaseModifier } from 'popper.js';
import { forkJoin, Subscriber, Subscription } from 'rxjs';
import { API_URL, HttpRequestService, Utils } from 'src/app/core';
import { FormValidator } from 'src/validators';

@Component({
  selector: 'app-create-customer-group-popup',
  templateUrl: './create-customer-group-popup.component.html',
  styleUrls: ['./create-customer-group-popup.component.scss']
})
export class CreateCustomerGroupPopupComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private toastr: ToastrService,
    private bsModalService: BsModalService,
    private helperService: HelperService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    name: new FormControl('', [Validators.compose([Validators.required, FormValidator.isEmpty])]),
    users: new FormControl([], Validators.compose([Validators.required])),
    type: new FormControl('', Validators.compose([Validators.required]))
  });

  customerGroupType: any[] = [
    {
      name: 'Customer',
      value: 'customer'
    }, {
      name: 'Manager',
      value: 'manager'
    }];
  params;
  brandId;
  allUsers: any[] = [];
  loading = { api: false, form: false };

  ngOnInit(): void {
    this.brandId = this.params?.brandId;
    this.getSettings();
  }

  getSettings() {
    this.loading.api = true;
    const getCustomersAPI = this.httpRequestService.getData(API_URL._GET_CUSTOMERS, {});
    forkJoin([getCustomersAPI]).subscribe(
      (resp: any) => {
        this.loading.api = false;
        this.allUsers = resp[0].data.customers;
        // this.getUsers();
      }, (error) => {
        this.loading.api = false;
      }
    );
  }

  onFocusOut(_event) {
    _event.target.value = parseInt(_event.target.value);
  }

  onlyNumbers(event) {
    FormValidator.onlyNumbers(event);
  }

  submit() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_USER_GROUP;
      const payload = this.formGroup.value;
      payload.brand = this.brandId;
      this.httpRequestService.postData(url, payload).subscribe(
        (resp: any) => {
          this.loading.form = false;
          this.toastr.success(`Customer Group saved successfully!`, `Success`, { timeOut: 1500 });
          this.helperService.refreshApi(resp.data);
          this.bsModalService.hide();
        }, (error) => {
          this.loading.form = false;
        }
      )
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  close() {
    this.bsModalService.hide();
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewScheduledOrderPopupComponent} from './new-scheduled-order-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule, SelectPickerModule } from '@app/directives';

const routes: Routes = [
    { path: '', component: NewScheduledOrderPopupComponent, 
}];

@NgModule({
  declarations: [
    NewScheduledOrderPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    SelectPickerModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    NewScheduledOrderPopupComponent
  ],
})
export class NewScheduledOrderPopupModule {
}

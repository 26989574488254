import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-copy-popup',
  templateUrl: './copy-popup.component.html',
  styleUrls: ['./copy-popup.component.scss']
})
export class CopyPopupComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private httpRequestService: HttpRequestService,
    private toastrService: ToastrService,
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    services: new FormControl([],[Validators.compose([Validators.required])])
  });

  loading = {api:false, form:false};
  params;
  selectedServices: any[] = [];
  itemArray: any[] = [];
  services: any[] = [];

  ngOnInit(): void {
    const allSettings = JSON.parse(localStorage.getItem('_settings'));
    if (allSettings) {
      this.services = allSettings?.services.filter(service=> service.type !== 'feature' && service._id !== this.params?.serviceId);
    }
    this.itemArray = this.params.selectedModifier;
  }

  isCheckedAllItems(checked,service){
    this.selectedServices = this.formGroup.get('services').value;
    
    if(Array.isArray(this.selectedServices)){
      const indexFound = this.isItemSelected(service._id);
      if(checked){
        if(indexFound == -1){
          this.selectedServices.push({_id: service._id, name: service.name});
        }
      }else{
        if(indexFound > -1){
          this.selectedServices.splice(indexFound,1);
        }
      }
    }else{
      this.selectedServices = [{_id: service._id, name: service.name}];
    }
    this.formGroup.get('services').patchValue(this.selectedServices);
  }

  isItemSelected(id){
    const indexFound = this.selectedServices.findIndex(item => item._id === id);
    return indexFound;
  }

  submit(){
    if(this.formGroup.valid){
      this.loading.form = true;
      const url = API_URL._POST_MENU_COPY;
      const payload ={
        ...this.formGroup.value,
        categories: this.params.selectedModifier,
        brand: this.params.brandId
      }
      // payload.brand = this.params.brandId;
      this.httpRequestService.postData(url,payload).subscribe(
        (resp)=>{
        this.loading.form = false;
        this.toastrService.success('Item copied successfully');
        this.bsModalService.hide();
        },(error)=>{
          this.loading.form = false;
        });
    }else{
      this.formGroup.markAllAsTouched();
    }
  }

  cancel(){
    this.bsModalService.hide();
  }

}

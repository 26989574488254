import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-decline-scheduled-order-popup',
  templateUrl: './decline-scheduled-order-popup.component.html',
  styleUrls: ['./decline-scheduled-order-popup.component.scss']
})
export class DeclineScheduledOrderPopupComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService,
    private toastrService: ToastrService,
    private helperService: HelperService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    reasons: new FormControl([], Validators.compose([Validators.required])),
    other: new FormControl('',[])
  });

  params;
  loading = { api: false, form: false };
  selectedReasons: any[] = [];
  DeclineOrderReasons: any[] = [
    {
      id: 1,
      name: 'Too busy',
      isTrue: false
    },
    {
      id: 2,
      name: 'Food unavailable',
      isTrue: false
    },
    {
      id: 3,
      name: 'Unknown address',
      isTrue: false
    }, {
      id: 4,
      name: 'Don’t deliver to area',
      isTrue: false
    }, {
      id: 5,
      name: 'Other',
      isTrue: false
    }
  ]

  ngOnInit(): void {
  }

  selectUnSelectItem(check, item) {
    this.selectedReasons = this.formGroup.get('reasons').value;
    if (Array.isArray(this.selectedReasons)) {
      const foundIndex = this.isItemSelected(item.id)
      if (check) {
        if (foundIndex == -1) {
          if(item.id === 5){
            item.isTrue = true;
            this.formGroup.get('other').setValidators([Validators.compose([Validators.required])]);
            this.formGroup.get('other').updateValueAndValidity({onlySelf: true});
          }
          this.selectedReasons.push(item);
        }
      } else {
        if (foundIndex > -1) {
          if(item.id === 5){
            item.isTrue = false;
            this.formGroup.get('other').setValidators(null);
            this.formGroup.get('other').updateValueAndValidity({onlySelf: true});
          }
          this.selectedReasons.splice(foundIndex, 1);
        }
      }
    } else {
      item.isTrue = true;
      this.selectedReasons = [item];
    }

    this.formGroup.get('reasons').patchValue(this.selectedReasons);
  }

  //for single item checked
  isItemSelected(itemId): number {
    this.selectedReasons = this.formGroup.get('reasons').value;
    const foundIndex = this.selectedReasons.findIndex(item => item.id === itemId);
    return foundIndex;
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_CHANGE_ORDER_STATUS;
      let payload = this.formGroup.value;
      if(payload.reasons){
        payload.reasons = payload.reasons.map(_reason=> _reason.name);
      }
      payload.id = this.params?.id;
      payload.status = 'declined';
      this.httpRequestService.postData(url, payload).subscribe(
        (resp: any) => {
          this.loading.form = false;
          this.toastrService.success('Status Marked as Declined');
          this.helperService.refreshApi({status: 'declined',type: this.params?.status});
          this.popupService.hide();
          this.popupService.hide(this.params?.orderId);
        }, (error) => {
          this.loading.form = false;
        }
      );
    }else{
      this.formGroup.markAllAsTouched();
    }
  }

}
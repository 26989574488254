<!-- The Modal -->
<div>
        <!-- Modal Header -->
        <div class="modal-header">
          <h5>Create discount</h5>
          <button type="button" class="close" (click)="close()" data-dismiss="modal">&times;</button>
        </div>
        <!-- [queryParams]="{brandId: params?.brandId,isAutomatic: false}" -->
        <!-- [queryParams]="{brandId: params?.brandId,isAutomatic: true}" -->
        <!-- Modal body -->
        <div class="modal-body">
          <div class="cardCard">
            <a href="javascript: //"  [routerLink]="['/discount-settings']" [queryParams]="getQueryParams(2)"  (click)="close()">
              <div class="cardCardIcon"><img class="img-fluid" src="assets/images/discountIcon1.svg"/></div>
              <div class="cardCardText">
                  <p class="text-black fw500">Discount code</p>
                  <p class="f13">Customer will get a discount if they enter a code at checkout</p>
              </div>
            </a>
          </div>
  
          <div class="cardCard">
            <a href="javascript: //" [routerLink]="['/discount-settings']" [queryParams]="getQueryParams(1)"  (click)="close()">
              <div class="cardCardIcon"><img class="img-fluid" src="assets/images/cartIcon.svg"/></div>
              <div class="cardCardText">
                  <p class="text-black fw500">Automatic discount</p>
                  <p class="f13">Customer will get a discount automatically in their cart</p>
              </div>
            </a>
          </div>
        </div>
  
        <!-- Modal footer -->
        <div class="modal-footer">
          <p class="f15 mb-0">Customers can't combine discounts at checkout.</p>
        </div>
  
  </div>

import {OUTLET_DATA_CONSTANTS, OutletDataActionTypes} from '../actions';


const initialState = [];

export function OutletDataReducer(state = initialState, action: OutletDataActionTypes) {
  switch (action.type) {
    case OUTLET_DATA_CONSTANTS.SAVE_OUTLET_DATA:
      return action.payload;

    case OUTLET_DATA_CONSTANTS.RESET_OUTLET_DETAIL:
      return initialState;

    default:
      return state;
  }
}

import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {API_URL, HttpRequestService, Utils} from 'src/app/core';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../shared';
import { HelperService } from 'src/app/services';
import { FormValidator } from 'src/validators';

@Component({
  selector: 'app-edit-menu-item-popup',
  templateUrl: './edit-menu-item-popup.component.html',
  styleUrls: ['./edit-menu-item-popup.component.scss']
})
export class EditMenuItemPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private helperService: HelperService
  ) {
  }

  formGroup: FormGroup = this.formBuilder.group({
    price: new FormControl('', [Validators.compose([Validators.required])]),
    quantity: new FormControl('', []),
  });

  loading = {api: false, form: false};
  params;
  walletData: any[] = [];

  ngOnInit(): void {
    if(this.params){
      this.formGroup.patchValue(this.params?.item);
    }
  }

  onlyNumbers(event) {
    FormValidator.onlyNumbers(event);
  }

  updateMeniItem() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_OUTLET_EDIT_MENU_ITEM;
      const payload = this.formGroup.value;
      payload.id = this.params?.item?.itemId;
      payload.type = 'outeletMenuItem';
      payload.quantity = +payload.quantity;
      this.httpRequestService.postData(url, payload).subscribe(
      (resp) => {
        this.loading.form = false;
        this.toastr.success('Menu Item updated successfully.');
        this.helperService.refreshApi(API_URL._GET_OUTLET_MENUS);
        this.popupService.hide();
      }, (error) => {
        this.loading.form = false;
      });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  close() {
    this.popupService.hide();
  }
}

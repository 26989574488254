import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {
  AcceptScheduledOrderPopupComponent,
  AddMoneyWalletPopupComponent,
  CopyPopupComponent,
  CreateCuisinePopupComponent,
  DeclineScheduledOrderPopupComponent,
  DeletePopupComponent,
  DiscountPopupComponent,
  MarkStatusReadyPopupComponent,
  MerchantInvoicePopupComponent,
  NewScheduledOrderPopupComponent,
  OrderHistoryPopupComponent,
  OutletCreatedSuccessfullyPopupComponent,
  SalesChannelPopupComponent,
  SearchAreaPopupComponent,
  ViewTransactionPopupComponent
} from './';
import { AssignItemPopupComponent } from './assign-item-popup/assign-item-popup.component';
import { AssignRiderPopupComponent } from './assign-rider-popup/assign-rider-popup.component';
import { CashPopupComponent } from './cash-popup/cash-popup.component';
import { ChangePasswordPopupComponent } from './change-password-popup/change-password-popup.component';
import { CreateChargePopupComponent } from './create-charge-popup/create-charge-popup.component';
import { CreateCustomerGroupPopupComponent } from './create-customer-group-popup/create-customer-group-popup.component';
import { CreateNewTaxesPopupComponent } from './create-new-taxes-popup/create-new-taxes-popup.component';
import { CustomerDetailPopupComponent } from './customer-detail-popup/customer-detail-popup.component';
import { EditMenuCategoryPopupComponent } from './edit-menu-category-popup/edit-menu-category-popup.component';
import { EditMenuItemPopupComponent } from './edit-menu-item-popup/edit-menu-item-popup.component';
import { LoyaltyHistoryPopup } from './loyality-history-popup/loyality-history-popup.component';
import {ManageRefundPopupComponent} from './manage-refund-popup/manage-refund-popup.component';
import { OnlineOfflinePopupComponent } from './online-offline-popup/online-offline-popup.component';
import { SearchSingleProductPopupComponent } from './search-single-product-popup/search-single-product-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private popupService: BsModalService
  ) {
  }

  OutletModalRef: BsModalRef; // 1
  DisountModalRef: BsModalRef;
  AdminDisountModalRef: BsModalRef;
  SearchAreaModalRef: BsModalRef;
  ConnectingSalesModalRef: BsModalRef;
  ViewTransactionModalRef: BsModalRef;
  AddMoneyWalletModalRef: BsModalRef;
  OrderHistoryModalRef: BsModalRef;
  NewScheduledOrderModalRef: BsModalRef;
  deleteModalRef: BsModalRef;
  signUpModalRef: BsModalRef; // 2
  otpModalRef: BsModalRef; // 3
  editPhoneNumberRef: BsModalRef; // 4
  addressRef: BsModalRef; // 5
  locationRef: BsModalRef; // 6
  customRef: BsModalRef; // 7
  orderRateRef: BsModalRef; // 8
  couponRef: BsModalRef; // 10
  DeclineOrderModalRef: BsModalRef;
  AcceptOrderModalRef: BsModalRef;
  MarkStatusReadyModalRef: BsModalRef;
  copyStatusModalRef: BsModalRef;
  leadRequestModalRef: BsModalRef;
  MerchantDetailModalRef: BsModalRef;
  MerchantInvoiceModalRef: BsModalRef;
  CreateCuisineModalRef: BsModalRef;
  ManageRefundModalRef: BsModalRef;
  assignMenuItemModalRef: BsModalRef;
  editMenuCategoryModalRef: BsModalRef;
  editMenuItemModalRef: BsModalRef;
  createChargeModalRef: BsModalRef;
  createCustomerGroupModalRef: BsModalRef;
  createTaxModalRef: BsModalRef;
  loyaltyHistoryModalRef: BsModalRef;
  customerDetailModalRef: BsModalRef;
  changePasswordModalRef: BsModalRef;
  onlineOfflineModalRef: BsModalRef;
  cashModalRef:BsModalRef;
  SearchSingleProductModalRef: BsModalRef;
  assignRiderModalRef: BsModalRef;

  OutletCreatedSuccessfullyPopupComponent(params) {
    const initialState = {
      params: {...params},
    };

    this.OutletModalRef = this.popupService.show(OutletCreatedSuccessfullyPopupComponent,
      {id: 1, animated: true, class: 'thankyouModal', initialState: {...initialState}});
    this.OutletModalRef.content.ref = this.OutletModalRef;
  }

  DiscountPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.DisountModalRef = this.popupService.show(DiscountPopupComponent,
      {id: 2, animated: true, class: 'cardModal', initialState: {...initialState}});
    this.DisountModalRef.content.ref = this.DisountModalRef;
    }


  SalesChannelPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.ConnectingSalesModalRef = this.popupService.show(SalesChannelPopupComponent,
      {id: 4, animated: true, class: 'salesModal', initialState: {...initialState}});
    this.ConnectingSalesModalRef.content.ref = this.ConnectingSalesModalRef;
  }

  DeletePopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.deleteModalRef = this.popupService.show(DeletePopupComponent,
      {id: 5, animated: true, class: 'yesNo', initialState: {...initialState}});
    this.deleteModalRef.content.ref = this.deleteModalRef;
  }

  SearchAreaPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.SearchAreaModalRef = this.popupService.show(SearchAreaPopupComponent,
      {id: 6, animated: true, initialState: {...initialState}});
    this.SearchAreaModalRef.content.ref = this.SearchAreaModalRef;
  }


  ViewTransactionPopupComponent(params) {
    const initialState = {
      params: {...params},
    };

    this.ViewTransactionModalRef = this.popupService.show(ViewTransactionPopupComponent,
      {id: 7, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.ViewTransactionModalRef.content.ref = this.ViewTransactionModalRef;
  }

  OrderHistoryPopupComponent(params) {    
    const initialState = {
      params: {...params},
    };

    this.OrderHistoryModalRef = this.popupService.show(OrderHistoryPopupComponent,
      {id: 8, animated: true, class: 'manageFunds modalOrders modal-lg', initialState: {...initialState}});
    this.OrderHistoryModalRef.content.ref = this.OrderHistoryModalRef;
  }

  NewScheduledOrderPopupComponent(params) {
    // ? params.data.orderId : params.data._id
    const orderId = params.data.hashOrderId ? params.data.hashOrderId :params.data.orderId;
    params.orderId = orderId;
    
    const initialState = {
      params: {...params},
    };

    this.NewScheduledOrderModalRef = this.popupService.show(NewScheduledOrderPopupComponent,
      {id: orderId, animated: true, class: 'orderModal modal-lg', initialState: {...initialState}});
    this.NewScheduledOrderModalRef.content.ref = this.NewScheduledOrderModalRef;
  }

  AddMoneyWalletPopupComponent(params) {
    const initialState = {
      params: {...params},
    };

    this.AddMoneyWalletModalRef = this.popupService.show(AddMoneyWalletPopupComponent,
      {id: 10, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.AddMoneyWalletModalRef.content.ref = this.AddMoneyWalletModalRef;
  }

  AcceptOrderPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.AcceptOrderModalRef = this.popupService.show(AcceptScheduledOrderPopupComponent,
      {id: 16, animated: true, class: 'acceptPop', initialState: {...initialState}});
    this.AcceptOrderModalRef.content.ref = this.AcceptOrderModalRef;
  }

  DeclineOrderPopupComponent(params) {
    const initialState = {
      params: {...params},
    };

    this.DeclineOrderModalRef = this.popupService.show(DeclineScheduledOrderPopupComponent,
      {id: 17, animated: true, class: 'cancelPop', initialState: {...initialState}});
    this.DeclineOrderModalRef.content.ref = this.DeclineOrderModalRef;
  }

  MarkStatusReadyPopupComponent(params) {
    const initialState = {
      params: {...params},
    };

    this.MarkStatusReadyModalRef = this.popupService.show(MarkStatusReadyPopupComponent,
      {id: 18, animated: true, class: 'yesNo', initialState: {...initialState}});
    this.MarkStatusReadyModalRef.content.ref = this.MarkStatusReadyModalRef;
  }

  CopyPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.copyStatusModalRef = this.popupService.show(CopyPopupComponent,
      {id: 19, animated: true, class: 'copyModal', initialState: {...initialState}});
    this.copyStatusModalRef.content.ref = this.copyStatusModalRef;
  }

  MerchantInvoicesPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.MerchantInvoiceModalRef = this.popupService.show(MerchantInvoicePopupComponent,
      {id: 22, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.MerchantInvoiceModalRef.content.ref = this.MerchantInvoiceModalRef;
  }

  CreateNewCuisinePopupComponent(params?) {
    const initialState = {
      params: {...params},
    };
    this.CreateCuisineModalRef = this.popupService.show(CreateCuisinePopupComponent,
      {id: 23, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.CreateCuisineModalRef.content.ref = this.CreateCuisineModalRef;
  }

  ManageRefundPopupComponent(params?) {
    const initialState = {
      params: {...params},
    };
    this.ManageRefundModalRef = this.popupService.show(ManageRefundPopupComponent,
      {id: 24, animated: true, class: 'ordeRefund', initialState: {...initialState}});
    this.ManageRefundModalRef.content.ref = this.ManageRefundModalRef;
  }

  AssignMenuPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.assignMenuItemModalRef = this.popupService.show(AssignItemPopupComponent,
      {id: 25, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.assignMenuItemModalRef.content.ref = this.assignMenuItemModalRef;
  }

  editMenuCategoryPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.editMenuCategoryModalRef = this.popupService.show(EditMenuCategoryPopupComponent,
      {id: 25, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.editMenuCategoryModalRef.content.ref = this.editMenuCategoryModalRef;
  }

  editMenuItemPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.editMenuItemModalRef = this.popupService.show(EditMenuItemPopupComponent,
      {id: 25, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.editMenuItemModalRef.content.ref = this.editMenuItemModalRef;
  }

  createChargePopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.createChargeModalRef = this.popupService.show(CreateChargePopupComponent,
      {id: 26, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.createChargeModalRef.content.ref = this.createChargeModalRef;
  }

  createCustomerGroupPopupComponent(params){
    const initialState = {
      params: {...params},
    };
    this.createCustomerGroupModalRef = this.popupService.show(CreateCustomerGroupPopupComponent,
      {id: 27, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.createCustomerGroupModalRef.content.ref = this.createCustomerGroupModalRef;
  }

  createTaxesPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.createTaxModalRef = this.popupService.show(CreateNewTaxesPopupComponent,
      {id: 28, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.createTaxModalRef.content.ref = this.createTaxModalRef;
  }

  customerDetailPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.customerDetailModalRef = this.popupService.show(CustomerDetailPopupComponent,
      {id: 29, animated: true, class: '', initialState: {...initialState}});
    this.customerDetailModalRef.content.ref = this.customerDetailModalRef;
  }

  changePasswordPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.changePasswordModalRef = this.popupService.show(ChangePasswordPopupComponent,
      {id: 30, animated: true, class: 'manageFunds', initialState: {...initialState}});
    this.changePasswordModalRef.content.ref = this.changePasswordModalRef;
  }

  onlineOfflinePopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.onlineOfflineModalRef = this.popupService.show(OnlineOfflinePopupComponent,
      {id: 31, animated: true, class: 'yesNo', initialState: {...initialState}});
    this.onlineOfflineModalRef.content.ref = this.onlineOfflineModalRef;
  }

  cashPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.cashModalRef = this.popupService.show(CashPopupComponent,
      {id: 32, animated: true, class: 'yesNo', initialState: {...initialState}});
    this.cashModalRef.content.ref = this.cashModalRef;
  }

  SearchSingleProductPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.SearchSingleProductModalRef = this.popupService.show(SearchSingleProductPopupComponent,
      {id: 6, animated: true, initialState: {...initialState}});
    this.SearchSingleProductModalRef.content.ref = this.SearchSingleProductModalRef;
  }

  AssignRiderPopupComponent(params) {
    const initialState = {
      params: {...params},
    };
    this.assignRiderModalRef = this.popupService.show(AssignRiderPopupComponent,
      {id: 25, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
    this.assignRiderModalRef.content.ref = this.assignRiderModalRef;
  }

  // open loyalty history popup
  openLoyaltyHistoryPopup(params) {
    const initialState = { params };
    this.loyaltyHistoryModalRef = this.popupService.show(LoyaltyHistoryPopup, {id: 33, animated: true, class: 'modal-lg', initialState: {...initialState}});
    this.loyaltyHistoryModalRef.content.ref = this.loyaltyHistoryModalRef;
  }

  // popup close
  close(type, msg = 'Cross click') {
    if (type === 'photo') {
      this.OutletModalRef.hide();
    } else if (type === 'search') {
      this.SearchAreaModalRef.hide();
    }

  }

}

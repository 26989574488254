import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { API_URL, HttpRequestService } from 'src/app/core';
import { BrandDataSelectors, OutletDataSelectors } from 'src/app/redux/actions';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-outlet-created-successfully-popup',
  templateUrl: './outlet-created-successfully-popup.component.html',
  styleUrls: ['./outlet-created-successfully-popup.component.scss']
})
export class OutletCreatedSuccessfullyPopupComponent implements OnInit {

  constructor(
    private router: Router,
    private popupService: BsModalService,
    private store: Store<any>,
    private httpRequestService: HttpRequestService
  ) { }

  params;
  brandId;
  outletId;
  appid;
  loading= {api: false};
  // isDelivery: boolean = false;
  // isPickup: boolean = false;

  ngOnInit(): void {
    // const loginUser = JSON.parse(localStorage.getItem('userDetails'));
    // this.appid = loginUser.appId;

    this.brandId = this.params.brandId;
    this.outletId = this.params.outletId
   
    // this.store.pipe(select(BrandDataSelectors.detail)).subscribe((item) => {
    //   this.brandId = item._id;
    // });

    // this.store.pipe(select(OutletDataSelectors.detail)).subscribe((item) => {
    //   this.outletId = item._id;
    // });

    // this.getServices();
  
  }

  // getServices() {
  //   this.loading.api = true;
  //   const url = API_URL._GET_ALL_SERVICES;
  //   const params = { appid: this.appid };
  //   this.httpRequestService.getData(url, {}).subscribe(
  //     (resp: any) => {
  //       this.loading.api = false;
  //        const services = resp.data;
  //        services.map((service)=>{
  //          if(service.slug == 'delivery'){
  //           this.isDelivery = true;
  //          }else if(service.slug == 'pickup'){
  //            this.isPickup = true;
  //          }
  //        })
  //     }, (error) => {
  //       this.loading.api = false;
  //     }
  //   )
  // }

  setDeliverySettings(value){
    if(value == 1){
      this.router.navigate(['/delivery-settings/' + this.outletId ],{queryParams: {brandId: this.brandId}});
    }else{
      this.router.navigate(['/pickup-settings/' + this.outletId],{queryParams: {brandId: this.brandId}});
    }
    this.popupService.hide();
  }

  goToDashboard(){
    // this.router.navigate(['app/brand/' + this.brandId + '/dashboard']);

    this.router.navigate(['app/brand/' + this.brandId + '/outlet/' + this.outletId + '/dashboard']);
    this.popupService.hide();
  }
 


}

import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeclineScheduledOrderPopupComponent } from '../decline-scheduled-order-popup/decline-scheduled-order-popup.component';
import { AcceptScheduledOrderPopupComponent } from '../accept-scheduled-order-popup/accept-scheduled-order-popup.component';
import { API_URL, HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-order-history-popup',
  templateUrl: './order-history-popup.component.html',
  styleUrls: ['./order-history-popup.component.scss']
})
export class OrderHistoryPopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService
  ) { }

  params;
  currencycode;
  allOrders: any[] = [];
  isLoader: boolean = false;
  loading = {api: false,form: false };

  ngOnInit(): void {
    if(!this.params?.isStatic){
      this.getAllOrders();  
    }
    if(this.params.brandId){
      const brand = JSON.parse(localStorage.getItem('_brand'));
      if(brand && brand.length && this.params.brandId){
        const foundData = brand.find(brand => brand?._id === this.params.brandId);
        if(foundData){
          this.currencycode = foundData?.currency?.name;
        }
        }
    }
  }

  getAllOrders() {    
    this.loading.api = true;
    const url = API_URL._GET_ALL_ORDERS;
    let params: any ={
      // brand: this.params?.brandId,
      // id: this.params?.event?._id,
      // orderId: this.params?.event?.orderId
      orderId: this.params?.event?.orderId.split('#').pop()
    };

    this.httpRequestService.getData(url, params).subscribe(
      (resp: any) => {
        resp.data.orderId = '#'+resp?.data?.orderId;
        
        this.params.data = resp.data;
        // this.allCounts = {count: resp.data?.total};
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    );
  }

  cancelOrder(){
    this.popupService.show(DeclineScheduledOrderPopupComponent);
    this.popupService.hide(this.params?.data?._id);
  }

  acceptOrder(){
    this.popupService.show(AcceptScheduledOrderPopupComponent);
    this.popupService.hide(this.params?.data?._id);

  }
}

<!-- <div> -->
<div class="manageFunds" id="Transactions">
  <div class="modal-header">
    <h5 class="modal-title">Menu Items</h5>
    <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
  </div>

  <app-loader *ngIf="loading.api"></app-loader>

  <div class="modal-body" *ngIf="allManagers?.length > 0 && !loading.api">
    <div class="transactionBody">
      <div infiniteScroll [scrollWindow]="true" [infiniteScrollThrottle]="throttle"
      [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance" [fromRoot]="true"
      [infiniteScrollContainer]="'.transactionBody'" (scrolled)="onScrollDown($event)">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let agent of allManagers">
            <td>{{ agent.name }} </td>
            <td>
              <div class=""><button class="f14 blank-btn" [disabled]="loading.form"
                  [ngClass]="{'disabled': loading.form}" (click)="assignUser(agent._id)">Assign item</button></div>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>

  <div class="" *ngIf="allManagers?.length === 0">
    <p class="m-48 text-center text mt-3">No Data found</p>
  </div>

</div>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignItemPopupComponent} from './assign-item-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@app/directives';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const routes: Routes = [
    { path: '', component: AssignItemPopupComponent, 
}];

@NgModule({
  declarations: [
    AssignItemPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule
  ],
  exports: [
    AssignItemPopupComponent
  ],
})
export class AssignItemPopupModule {
}

import { Component, OnInit } from '@angular/core';
import { API_URL, HttpRequestService } from '@app/core';
import { SearchService } from '@app/shared';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-online-offline-popup',
  templateUrl: './online-offline-popup.component.html',
  styleUrls: ['./online-offline-popup.component.scss']
})
export class OnlineOfflinePopupComponent implements OnInit {

  constructor(
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService,
    private toastrService: ToastrService,
    private searchService: SearchService
  ) { }

  params;

  ngOnInit(): void {
  }

  yes(){
    const payload = {
      isActive: this.params?.isActive,
      type: 'website',
      brand: this.params?.brandId
    }
    const url = API_URL._POST_SALES_CHANNEL;
    this.httpRequestService.postData(url,payload).subscribe(
      (resp: any)=>{
        if(resp.data){
          this.searchService.onDelete(resp?.data?.isActive);
        }
        this.toastrService.success('Updated Successfully', 'Success');
        this.popupService.hide();
      },(error)=>{
      });
  }

  cancel(){
    this.popupService.hide();
  }
  

}

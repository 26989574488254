export class Linker {
  /**
   * Event link
   * @param type
   * @param extra
   */
  public static eventLink(eventId, type, extra?) {
    let url = `/dashboard/event/${eventId}/${type}`;
    url = (extra) ? `${url}/${extra}` : url;
    return url;
  }

  /**
   * vendor Event link
   * @param type
   * @param extra
   */
  public static vendorEventLink(eventId, type, extra?) {
    let url = `/vendor/event/${eventId}/${type}`;
    url = (extra) ? `${url}/${extra}` : url;
    return url;
  }

  /**
   * Dashboard link
   * @param type   includes('brand')
   */
  public static dashLink(type) {
    if ((type.link.includes('delivery-settings')) || (type.queryParam == 'loyaltyPointParam') || (type.queryParam == 'settingsParam') || (type.queryParam == 'cashbackParam') 
    || (type.link.includes('branding')) || 
    (type.link.includes('pickup-settings'))
     || (type.link.includes('reservation-settings'))
      || (type.link.includes('bulk-order-settings'))) {
      return `/${type.link}`;
    } else {
      return `/app/${type.link}`;
    }
  }

  /**
   * Dashboard Admin link
   */
  public static dashAdminLink(type) {
    if ((type.includes('admin-delivery-settings')) || (type.includes('admin-pickup-settings')) || (type.includes('admin-reservation-settings'))
      || (type.includes('admin-bulk-order-settings'))) {
      return `/${type}`;
    } else {
      return `/admin/${type}`;
    }
  }

  public static breadcrumbLink(type) {
    return `${type}`;
  }

  public static menuLink(type, id) {
    return `/app/brand/${id}/menu/${type}`;
  }

  /**
   * Vendor Link
   * @param type
   */
  public static vendorLink(type) {
    return `/vendor/${type}`;
  }

  /**
   * Vendor Events Link
   * @param type
   */
  public static vendorEventsLink(type) {
    return `/vendor/events/${type}`;
  }

  /**
   * Admin Link
   * @param type
   */
  public static adminLink(type) {
    return `/admin/${type}`;
  }

  /**
  * Merchant Link
  * @param type
  */
  public static merchantLink(type) {
    return `/merchant/${type}`;
  }

  /**
   * Admin Event link
   * @param type
   * @param extra
   */
  public static adminEventLink(eventId, type, extra?) {
    let url = `/admin/event/${eventId}/${type}`;
    url = (extra) ? `${url}/${extra}` : url;
    return url;
  }
}

import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BaseModifier } from 'popper.js';
import { Subscriber, Subscription } from 'rxjs';
import { API_URL, HttpRequestService, Utils } from 'src/app/core';
import { FormValidator } from 'src/validators';

@Component({
  selector: 'app-create-new-taxes-popup',
  templateUrl: './create-new-taxes-popup.component.html',
  styleUrls: ['./create-new-taxes-popup.component.scss']
})
export class CreateNewTaxesPopupComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private toastr: ToastrService,
    private bsModalService: BsModalService,
    private helperService: HelperService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    name: new FormControl('', [Validators.compose([Validators.required, FormValidator.isEmpty])]),
    services: new FormControl([], Validators.compose([Validators.required])),
    isIncluded: new FormControl('', [Validators.compose([Validators.required])]),
  });

  statusArray: any[] = [
    {
      name: 'True',
      value: true
    },
    {
      name: 'False ',
      value: false
    }
  ];
  params;
  selectedServices: any[] = [];
  brandId;
  allServices: any[] = [];
  loading = { api: false, form: false };

  ngOnInit(): void {
    this.brandId = this.params?.brandId;
    this.getAllServices();
  }

  onFocusOut(_event) {
    _event.target.value = parseInt(_event.target.value);
  }

  getAllServices() {
    this.loading.api = true;
    const url = API_URL._GET_ALL_SERVICES;
    this.httpRequestService.getData(url, {type: 'service'}).subscribe(
      (resp: any) => {
        this.loading.api = false;
        this.allServices = resp.data;
      }, (error) => {
        this.loading.api = false;
      });
  }

  onlyNumbers(event) {
    FormValidator.onlyNumbers(event);
  }

  selectAllOrUnselectAll(checked) {
    this.allServices.forEach(item => {
      if (!item.hasDeliverySetting)
        this.selectUnSelectItem(checked, item);
    });
  }

  selectUnSelectItem(check, service) {
    this.selectedServices = this.formGroup.get('services').value;
    if (Array.isArray(this.selectedServices)) {
      const foundIndex = this.isItemSelected(service._id)
      if (check) {
        if (foundIndex == -1) {
          this.selectedServices.push({ _id: service._id, name: service.name,type: service.type, slug: service.slug });
        }
      } else {
        if (foundIndex > -1) {
          this.selectedServices.splice(foundIndex, 1);
        }
      }
    } else {
      this.selectedServices = [{ _id: service._id, name: service.name,type: service.type, slug: service.slug }];
    }
    this.formGroup.get('services').patchValue(this.selectedServices);
  }

  //for single item checked
  isItemSelected(itemId): number {
    this.selectedServices = this.formGroup.get('services').value;
    const foundIndex = this.selectedServices.findIndex(item => item._id === itemId);
    return foundIndex;
  }

  get isCheckedAllItems() {
    let selected = false;
    for (let i = 0; i < this.allServices.length; i++) {
      this.selectedServices = this.formGroup.get('services').value;
      const foundIndex = this.selectedServices.findIndex(item => item._id === this.allServices[i]._id);
      if (foundIndex > -1) {
        selected = true;
      } else {
        selected = false;
        break;
      }
      if (!selected) {
        break;
      }
    }
    return selected;
  }

  submit() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_TAX;
      const payload = this.formGroup.value;
      payload.brandId = this.brandId;
      payload.isIncluded = payload.isIncluded === 'true';
      this.httpRequestService.postData(url, payload).subscribe(
        (resp: any) => {
          this.loading.form = false;
          this.toastr.success(`Tax saved successfully!`, `Success`, { timeOut: 1500 });
          this.helperService.refreshApi(resp.data);
          this.bsModalService.hide();
        }, (error) => {
          this.toastr.error(error.error.message, `Error`, { timeOut: 3000 });
          this.loading.form = false;
        }
      )
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  close() {
    this.bsModalService.hide();
  }
}

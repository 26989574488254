import {Action} from '@ngrx/store';

export const AREA_DATA_CONSTANTS = {
  SAVE_AREA_DATA: '[Area Detail] Save',
  RESET_AREA_DETAIL: '[Area Detail] Reset',
};


class SaveAreaData implements Action {
  readonly type = AREA_DATA_CONSTANTS.SAVE_AREA_DATA;
  constructor(public payload: any) {
  }
}

class ResetAreaData implements Action {
  readonly type = AREA_DATA_CONSTANTS.RESET_AREA_DETAIL;
}

export const AreaDataActions = {
  saveDetail: SaveAreaData,
  restDetails: ResetAreaData
};

export const AreaDataSelectors = {
  detail: (state: any) => state._area,
};

export type AreaDataActionTypes = SaveAreaData;

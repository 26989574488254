<!-- The Modal -->
<div>
    <!-- Modal Header -->
    <div class="modal-header">
        <h5 class="modal-title">Create New Charge</h5>
        <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>

    <app-loader *ngIf="loading.api"></app-loader>

    <!-- Modal body -->
    <div class="modal-body">
        <form class="fundsBody" [formGroup]="formGroup">

            <div class="form-in">
                <label class="f16 mb-2">Name</label>
                <input formControlName="name" type="text" placeholder="E.g. Charge">
                <div *ngIf="formGroup.get('name').invalid && formGroup.get('name').touched">
                    <span class="error" *ngIf="formGroup.get('name').hasError('required')">Charge name is
                        required</span>
                </div>
            </div>

            <div class="borderBottom" *ngIf="allServices.length">
                <p class="strong">Select Services</p>
                <div class="form-in">
                    <div class="checkBox"><input type="checkbox"
                            (change)="selectAllOrUnselectAll($event.target.checked)" [checked]="isCheckedAllItems"
                            id="outletsCheck"><label for="outletsCheck">Select
                            all Services</label></div>
                </div>
                <div class="form-in" *ngFor="let service of allServices; let index=index">
                    <div class="checkBox">
                        <input type="checkbox" (change)="selectUnSelectItem($event.target.checked, service)"
                            [checked]="isItemSelected(service._id) > -1" [value]="service.value" [id]="'service'+index">
                        <label for="{{'service'+index}}">{{ service?.name }} </label>
                    </div>
                </div>
                <div *ngIf="selectedServices.length === 0 && formGroup.controls['services'].touched">
                    <span class="error">Select at least one service.</span>
                </div>
            </div>
        </form>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer border-0">
        <button type="button" class="btn-white sm" (click)="close()">Cancel</button>
        <button type="button" [disabled]="loading.form" (click)="submit()" [ngClass]="{'loader': loading.form}" class="btn sm">Submit</button>
    </div>

</div>
<app-nav-bar></app-nav-bar>

<div class="All-in">
  <div id="loginSection" class="loginSection">
    <div class="heading">
      <h2 class="m-12"><span>Welcome to Delivery Papa</span> <span>Forgot Password</span></h2>
      <p class="m-32 sm"><span>Type your Password and New Password and update your credentials.</span> <span>We will
          send and email regarding to your password reset request</span></p>
    </div>

    <div class="form-sec loginForm">
      <form class="signup-form" [formGroup]="formGroup">
        <div class="form-in">
          <label>App Id (Optional)</label>
          <input class="form-input" type="number" formControlName="appId" placeholder="Ex. 987654321" />
        </div>

        <div class="form-in">
          <label>New Password</label>
          <div class="showpass">
            <input class="form-input" formControlName="password" [type]="show ? 'text' : 'password'"
              placeholder="Type new password" />
            <a href="javascript: //" class="toggle-password password-view" (click)="showHidePassword()">{{show ? 'Hide'
              : 'Show'}}</a>
          </div>

          <div
            [ngClass]="{'formMessage': (formGroup.get('password').errors?.minlength) && (formGroup.get('password').errors?.invalidPass)}"
            class="formMessage"
            *ngIf="formGroup.get('password').invalid && formGroup && formGroup.get('password').touched">
            <span class="error" *ngIf="formGroup.get('password').hasError('required')">Password is required</span>
            <span class="error" *ngIf="formGroup.get('password').errors?.minlength">Password must be at least 8
              characters</span>
            <span class="error" *ngIf="formGroup.get('password').errors?.invalidPass">Password must have [a-z],[0-9] & 1
              Capital, 1 Special character.</span>
            <span class="error" *ngIf="formGroup.get('password').errors?.maxlength">Password length should be less than
              100</span>
          </div>
        </div>

        <div class="form-in">
          <label>Confirm Password</label>
          <div class="showpass">
            <input class="form-input" formControlName="confirmPassword" [type]="showConfirm ? 'text' : 'password'"
              placeholder="Type confirm password" />
            <a href="javascript: //" class="toggle-password password-view" (click)="showHideCPassword()">{{showConfirm ?
              'Hide' : 'Show'}}</a>
          </div>

          <div *ngIf="formGroup.get('confirmPassword').invalid && formGroup.get('confirmPassword').touched">
            <span class="error" *ngIf="formGroup.get('confirmPassword').hasError('matchPassword')">Password not
              matched</span>
          </div>
        </div>

        <input class="form-input" formControlName="emailToken" type="text" placeholder="Ex. 12345" hidden />

        <div class="form-in form-btn">
          <button (click)="submit()" class="btn w-100" [ngClass]="{'loader': loading.form}">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
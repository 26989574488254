import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpRequestService, Utils } from 'src/app/core';
import { SearchService } from 'src/app/shared/search.service';

@Component({
  selector: 'app-search-single-product-popup',
  templateUrl: './search-single-product-popup.component.html',
  styleUrls: ['./search-single-product-popup.component.scss']
})
export class SearchSingleProductPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private httpRequestService: HttpRequestService,
    private searchService: SearchService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    items: new FormControl('', []),
    search: new FormControl('', [])
  });

  @ViewChild('searchBox', {static: true}) private searchBox: ElementRef;
  term = '';
  selectedOutlets: any[] = [];
  selectedArea: any[] = [];
  areaIndex;
  resData: any[] = [];
  params;
  lists: any[] = [];
  items: any[] = [];
  label: any;
  type: number;

  ngOnInit(): void {
    if(this.params.inputValue){
      this.formGroup.get('search').patchValue(this.params.inputValue);
    }
    this.label = this.params.label;
    this.type = this.params.type;
    this.lists = this.params.list;
    
    if(this.params.items){
      // this.items = this.params.items;
      this.selectedOutlets = this.params.items;
      if(this.selectedOutlets && this.selectedOutlets.length){
        this.formGroup.get('items').patchValue(this.selectedOutlets[0]._id);
      }
    }

    setTimeout(() => {
      this.searchBox.nativeElement.focus();
    }, 10)
  }

  onFocus(data) {
  }

  selectUnSelectItem(check, list) {
    this.selectedOutlets = this.formGroup.get('items').value;
    if (Array.isArray(this.selectedOutlets)) {
      const foundIndex = this.isItemSelected(list._id);
      if (check) {
        if (foundIndex == -1) {
          this.selectedOutlets = [{_id: list._id, name: list.name}];
        }
      }
    } else {
      this.selectedOutlets = [{_id: list._id, name: list.name}];
    }
  }


  isItemSelected(itemId): number {
    // this.selectedOutlets = this.formGroup.get('items').value;
    const foundIndex = this.selectedOutlets.findIndex(item => item._id === itemId);
    return foundIndex;
  }


  save() {
    let selectedOutlets = Utils.cloneObj(this.selectedOutlets);
    if (this.type === 7) {
      this.searchService.onGetCustomerProducts(selectedOutlets);
    }else{
      this.searchService.onGroupCustomer(selectedOutlets);
    }
    this.close();
  }

  close() {
    this.popupService.hide();
  }
}

<div class="">
  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div class="acceptBody">
        <h4>Set Preparation Time</h4>
        <div class="acceptIcon">
          <img class="img-fluid" src="assets/images/timerIcon.svg" />
        </div>
        <p class="text">How long will this take to prepare?</p>

        <div class="timeCounter">
          <input readonly type="number" (keypress)="onlyNumbers($event)" formControlName="time" id="partridge"
            value="20" autocomplete="off" />
          <div>
            <span class="error" *ngIf="formGroup.get('time').errors?.maxValue">Time should be less than or equals to
              1000</span>
            <span class="error" *ngIf="formGroup.get('time').errors?.minValue">Time should be greater than or equals to
              1</span>
          </div>
          <div class="">
            <button class="inc button" (click)="increment(formGroup.get('time').value)">+</button>

          </div>
          <div class="dec button" (click)="decrement(formGroup.get('time').value)">-</div>
          <div class="form-in mt-3">
            <div class="select-div">
              <select-picker formControlName="timeType" firstOption="Select any type" [options]="statusArray">
              </select-picker>
              <div *ngIf="formGroup.get('timeType').invalid && formGroup.get('timeType').touched">
                <span class="error" *ngIf="formGroup.get('timeType').hasError('required')">Type is required</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button class="btn next" (click)="onSubmit()" [disabled]="loading.form"
            [ngClass]="{'loader': loading.form}">Set</button>
        </div>
      </div>
    </form>
  </div>
</div>


<!-- <div class="modal acceptPop " id="acceptPop">
        <div class="modal-dialog">
          <div class="modal-content">
            
              <div class="modal-body">
                <form>
                <div class="acceptBody">
                  </form>
                    <h4>Set Preparation Time</h4>
                    <div class="acceptIcon"><img class="img-fluid" src="images/timerIcon.svg" />
                    
                    </div>
                    <p class="text">How long will this take to prepare?</p>
      
                    <div class="timeCounter">
                      <input type="text" name="partridge" id="partridge" value="20">
                    </div>
                  </form>
      
                  <div>
                    <button class="btn next">Done</button>
                  </div>
                </div>
              
              </div>
            
          </div>
        </div>
      </div> -->
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent, ResetPasswordComponent, SignupComponent} from './authentication/index';
import {ContactSalesComponent} from './public/contact-sales/contact-sales.component';
import {ContactThankyouComponent} from './public/contact-thankyou/contact-thankyou.component';
import {AuthGuard} from './guard/auth.guard';
import {LoginGuard} from './guard/login.guard';

const routes: Routes = [
  {path: '', canActivate: [LoginGuard], component: LoginComponent},
  {path: 'login', canActivate: [LoginGuard], component: LoginComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'contact-sales', component: ContactSalesComponent},
  {path: 'contact-thankyou', component: ContactThankyouComponent},
  {
    path: 'app',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/vendor.module').then(m => m.VendorModule),
    data: {role: 'vendor', breadcrumb: 'Vendor'}
  },
  {
    path: 'create-brand',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-brand/create-brand.module').then(m => m.CreateBrandModule)
  },
  {
    path: 'edit-brand/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-brand/create-brand.module').then(m => m.CreateBrandModule)
  },
  {
    path: 'create-outlet',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-outlet/create-outlet.module').then(m => m.CreateOutletModule)
  },
  {
    path: 'manual-outlet',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-outlet-manually/create-outlet-manually.module').then(m => m.CreateOutletManuallyModule)
  },
  {
    path: 'edit-manual-outlet/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-outlet-manually/create-outlet-manually.module').then(m => m.CreateOutletManuallyModule)
  },
  {
    path: 'edit-outlet/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-outlet/create-outlet.module').then(m => m.CreateOutletModule)
  },
  {
    path: 'delivery-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/delivery-settings/delivery-settings.module').then(m => m.DeliverySettingsModule)
  },
  {
    path: 'delivery-settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/delivery-settings/delivery-settings.module').then(m => m.DeliverySettingsModule)
  },
  {
    path: 'pickup-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/pickup-settings/pickup-settings.module').then(m => m.PickupSettingsModule)
  },
  {
    path: 'pickup-settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/pickup-settings/pickup-settings.module').then(m => m.PickupSettingsModule)
  },
  {
    path: 'bulk-order-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/bulk-order-settings/bulk-order-settings.module').then(m => m.BulkOrderSettingsModule)
  },
  {
    path: 'bulk-order-settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/bulk-order-settings/bulk-order-settings.module').then(m => m.BulkOrderSettingsModule)
  },
  {
    path: 'reservation-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/reservation-settings/reservation-settings.module').then(m => m.ReservationSettingsModule)
  },
  {
    path: 'reservation-settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/reservation-settings/reservation-settings.module').then(m => m.ReservationSettingsModule)
  },
  {
    path: 'discount-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-discount-settings/create-discount-settings.module')
      .then(m => m.CreateDiscountSettingsModule)
  },
  {
    path: 'discount-settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-discount-settings/create-discount-settings.module')
      .then(m => m.CreateDiscountSettingsModule)
  },
  {
    path: 'create-charge',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-charge/create-new-charge.module').then(m => m.CreateNewChargeModule)
  },
  {
    path: 'create-charge/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-charge/create-new-charge.module').then(m => m.CreateNewChargeModule)
  },
  {
    path: 'create-tax',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-tax/create-tax.module').then(m => m.CreateTaxModule)
  },
  {
    path: 'create-tax/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-tax/create-tax.module').then(m => m.CreateTaxModule)
  },
  // {
  //   path: 'create-category',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./layouts/create-category/create-category.module').then(m => m.CreateCategoryModule)
  // },
  // {
  //   path: 'create-category/:id',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./layouts/create-category/create-category.module').then(m => m.CreateCategoryModule)
  // },
  {
    path: 'website-sales',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/website-ordering/website-ordering.module')
      .then(m => m.WebsiteOrderingModule)
  },
  {
    path: 'whats-app',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/whats-app/whats-app.module').then(m => m.WhatsAppModule)
  },
  {
    path: 'messenger',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/messenger/messenger.module').then(m => m.MessengerModule)
  },
  {
    path: 'instagram',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/instagram/instagram.module').then(m => m.InstagramModule)
  },
  {
    path: 'google-my-account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/google-my-account/google-my-account.module').then(m => m.GoogleMyAccountModule)
  },
  {
    path: 'facebook',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/facebook/facebook.module').then(m => m.FacebookModule)
  },
  {
    path: 'tik-tok',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/sale-channels/tik-tok/tik-tok.module').then(m => m.TikTokModule)
  },
  {
    path: 'sms-marketing-compaign',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-sms-marketing-compaign/create-sms-marketing-compaign.module')
      .then(m => m.CreateSmsMarketingCompaignModule)
  },
  {
    path: 'social-media-compaign',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-social-media-compaign/create-social-media-compaign.module')
      .then(m => m.CreateSocialMediaCompaignModule)
  },
  {
    path: 'google-compaign',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-google-compaign/create-google-compaign.module')
      .then(m => m.CreateGoogleCompaignModule)
  },
  {
    path: 'qr-code',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-qr-code/create-qr-code.module')
      .then(m => m.CreateQrCodeModule)
  },
  {
    path: 'website-app',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-webside-app/create-webside-app.module')
      .then(m => m.CreateWebsideAppModule)
  },
  {
    path: 'third-part-orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-third-party-orders/create-third-party-orders.module')
      .then(m => m.CreateThirdPartyOrdersModule)
  },
  {
    path: 'menu-photo',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-professional-menu-photo/create-professional-menu-photo.module')
      .then(m => m.CreateProfessionalMenuPhotoModule)
  },
  {
    path: 'social-media-content',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-social-media-content/create-social-media.content.module')
      .then(m => m.CreateSocialMediaContentModule)
  },
  {
    path: 'digital-marketing-audit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-digital-marketing-audit/create-digital-marketing-audit.module')
      .then(m => m.CreateDigitalMarketingAuditModule)
  },
  {
    path: 'design-package',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-printed-dessign-package/create-printed.dessign-package.module')
      .then(m => m.CreatePrintedDessignPackageModule)
  },
  {
    path: 'dinein-compaign',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-dine-in-compaign/create-dine-in-compaign.module')
      .then(m => m.CreateDineInCompaignModule)
  },
  {
    path: 'automatic-reminder',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-automatic-reminder/create-automatic-reminder.module')
      .then(m => m.CreateAutomaticReminderModule)
  },

  {
    path: 'create-manager',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-user/create-user.module').then(m => m.CreateUserModule)
  },
  {
    path: 'edit-manager/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-user/create-user.module').then(m => m.CreateUserModule)
  },
  {
    path: 'cashback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-cashback/create-cashback.module').then(m => m.CreateCashbackModule)
  },
  {
    path: 'cashback/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-cashback/create-cashback.module').then(m => m.CreateCashbackModule)
  },
  {
    path: 'create-group-customer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/group-customers/create-group-customer/create-group-customer.module').then(m => m.CreateGroupCustomerModule)
  },
  {
    path: 'create-group-customer/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/group-customers/create-group-customer/create-group-customer.module').then(m => m.CreateGroupCustomerModule)
  },
  {
    path: 'loyalty-points',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-loyalty-points/create-loyalty-points.module').then(m => m.CreateLoyaltyPointsModule)
  },
  {
    path: 'loyalty-points/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-loyalty-points/create-loyalty-points.module').then(m => m.CreateLoyaltyPointsModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-general-settings/create-general-settings.module').then(m => m.CreateGeneralSettingsModule)
  },
  {
    path: 'settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-general-settings/create-general-settings.module').then(m => m.CreateGeneralSettingsModule)
  },
  {
    path: 'create-role',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-role/create-role.module').then(m => m.CreateRoleModule)
  },
  {
    path: 'edit-role/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-role/create-role.module').then(m => m.CreateRoleModule)
  },
  {
    path: 'create-dp-deal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-dp-deal/create-dp-deal.module').then(m => m.CreateDpDealModule)
  },
  {
    path: 'edit-dp-deal/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-dp-deal/create-dp-deal.module').then(m => m.CreateDpDealModule)
  },

  {
    path: 'create-area',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/areas/add-edit-area/add-edit-area.module').then(m => m.AddEditAreaModule)
  },
  {
    path: 'edit-area/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/areas/add-edit-area/add-edit-area.module').then(m => m.AddEditAreaModule)
  },

  {
    path: 'create-category',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-category/create-new-category.module').then(m => m.CreateNewCategoryModule)
  },
  {
    path: 'create-category/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-category/create-new-category.module').then(m => m.CreateNewCategoryModule)
  },

  {
    path: 'create-item',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-item/create-new-item.module').then(m => m.CreateNewItemModule)
  },
  {
    path: 'create-item/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-item/create-new-item.module').then(m => m.CreateNewItemModule)
  },

  {
    path: 'create-modifier',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-modifier/create-new-modifier.module').then(m => m.CreateNewModifierModule)
  },
  {
    path: 'create-modifier/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-new-modifier/create-new-modifier.module').then(m => m.CreateNewModifierModule)
  },
  {
    path: 'create-vip-card',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/digital-vip-card/create-digital-vip-card/create-digital-vip-card.module').then(m => m.CreateDigitalVipCardModule)
  },
  {
    path: 'create-vip-card/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/digital-vip-card/create-digital-vip-card/create-digital-vip-card.module').then(m => m.CreateDigitalVipCardModule)
  },
  {
    path: 'branding',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-branding/create-branding.module').then(m => m.CreateBrandingModule)
  },
  {
    path: 'branding/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/create-branding/create-branding.module').then(m => m.CreateBrandingModule)
  },
  {
    path: 'create-loyalty-points',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/loyalty-points/create-loyalty-points/create-loyalty-point.module').then(m => m.CreateLoyaltyPointModule)
  },
  {
    path: 'edit-loyalty-points/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/loyalty-points/create-loyalty-points/create-loyalty-point.module').then(m => m.CreateLoyaltyPointModule)
  },
  {
    path: 'create-loyalty-earning-pattern',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/loyalty-earning-patterns/create-loyalty-earning-pattern/create-loyalty-earning-pattern.module').then(m => m.CreateLoyaltyEarningPatternModule)
  },
  {
    path: 'edit-loyalty-earning-pattern/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/loyalty-earning-patterns/create-loyalty-earning-pattern/create-loyalty-earning-pattern.module').then(m => m.CreateLoyaltyEarningPatternModule)
  },

  {
    path: 'create-customer-group',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/user-groups/create-user-groups/create-user-groups.module').then(m => m.CreateUserGroupsModule)
  },
  {
    path: 'edit-customer-group/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/user-groups/create-user-groups/create-user-groups.module').then(m => m.CreateUserGroupsModule)
  },
  {
    path: 'create-rider',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/riders/create-riders/create-riders.module').then(m => m.CreateRidersModule)
  },
  {
    path: 'edit-rider/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/riders/create-riders/create-riders.module').then(m => m.CreateRidersModule)
  },
  {
    path: 'general-settings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/outlet-general-settings/outlet-general-settings.module').then(m => m.OutletGeneralSettingsModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: true,
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

 <!-- The Modal -->
<app-loader *ngIf="loading.api"></app-loader>

 <div *ngIf="!loading.api">
    <!-- Modal body -->

    <div class="modal-body">
        <div class="checkIcon"><img class="img-fluid" src="assets/images/checkThanku.svg" /></div>
        <h2 class="m-16">Awesome!</h2>
        <p class="m-32 mx-auto">Your outlet has been created successfully. Please add other details.</p>

        <a *ngIf="params.delivery" href="javascript: //"  class="btn sm" (click)="setDeliverySettings(1)">Set Delivery settings</a><br>
        <a *ngIf="params.pickup" href="javascript: //"  class="btn sm" (click)="setDeliverySettings(2)">Set Pickup settings</a><br>

        <a href="javascript: //" (click)="goToDashboard()">Go to dashboard</a>
    </div>
  </div>
  
<!-- The Modal -->
<div>      <!-- Modal Header -->
    <div class="modal-header">
      <h5 class="modal-title">Edit Menu Item</h5>
      <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>
  
    <!-- <app-loader *ngIf="loading.api"></app-loader> -->
    <!-- Modal body -->
    <div class="modal-body">
      <form class="fundsBody" [formGroup]="formGroup">
        <div class="form-in">
          <label>Price</label>
          <input type="number" min="0" (keypress)="onlyNumbers($event)" formControlName="price" placeholder="Enter price here"/>
          <div *ngIf="formGroup.get('price').invalid && formGroup.get('price').touched">
            <span class="error" *ngIf="formGroup.get('price').hasError('required')">Price is required</span>
          </div>
        </div>
            <div class="form-in">
              <label>Quantity</label>
              <input type="number" min="0" (keypress)="onlyNumbers($event)" formControlName="quantity" placeholder="Enter quantity here"/>
              <!-- <div *ngIf="formGroup.get('quantity').invalid && formGroup.get('quantity').touched">
                <span class="error" *ngIf="formGroup.get('quantity').hasError('required')">Quantity is required</span>
              </div> -->
            </div>
      </form>
    </div>
  
    <!-- Modal footer -->
    <div class="modal-footer border-0">
      <button type="button" class="btn-white sm" (click)="close()">Cancel</button>
      <button type="button" [disabled]="loading.form" (click)="updateMeniItem()" [ngClass]="{'loader': loading.form}" class="btn sm">Update Item</button>
    </div>
  
  </div>
  
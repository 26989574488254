<app-cnav-bar></app-cnav-bar>

<div class="All-in">

    <div class="Sign-up-sales">
  
      <div class="heading">
        <h2 class="m-12">Contact our sales team</h2>
        <p class="m-32">Our team is happy to answer your sales question. Fill out the form and we’ll be in touch as soon as possible.</p>
      </div>
  
      <div class="form-sec">
        <form class="signup-contact" [formGroup]="formGroup" (ngSubmit)="onContactSubmit()">
          <div class="form-in">
            <label>Company name</label>
            <input class="form-input" type="text" formControlName="companyName" placeholder="Ex. Food castle" />
            <div *ngIf="formGroup.get('companyName').invalid && formGroup.get('companyName').touched">
                <span class="error" *ngIf="formGroup.get('companyName').hasError('required')">Name is required</span>
                <span class="error" *ngIf="formGroup.get('companyName').errors.pattern">Only Alphanumeric characters are allowed</span>
            </div>
          </div>
    
          <div class="form-in">
            <label>Number of Outlets</label>
            <input class="form-input" type="number" formControlName="outlets" (keypress)="onlyNumbers($event)" placeholder="Ex. 5" min="0" />
            <div *ngIf="formGroup.get('outlets').invalid && formGroup.get('outlets').touched">
                <span class="error" *ngIf="formGroup.get('outlets').hasError('required')">No. of Outlets are required</span>
            </div>
          </div>
    
          <div class="form-in">
            <label>Email address</label>
            <input class="form-input" type="email" formControlName="email" placeholder="Ex. john@example.com" />
            <div *ngIf="formGroup.get('email').invalid && formGroup.get('email').touched">
                <span class="error" *ngIf="formGroup.get('email').hasError('required')">Email is required</span>
                <span class="error" *ngIf="formGroup.get('email').errors.invalidEmail">Valid email is required</span>
            </div>
          </div>
    
          <div class="form-in ">
            <label>Country</label>
    
            <div class="select-div">
                <select-picker formControlName="country" firstOption="Select any Country" [options]="allCountries" [search]="true"></select-picker>
              <div *ngIf="formGroup.get('country').invalid && formGroup.get('country').touched">
                <span class="error" *ngIf="formGroup.get('country').hasError('required')">Country is required</span>
            </div>
            </div>
          </div>
    
          <div class="form-in phone-number">
            <label>Mobile Number</label>
            
            <div class="select-div">
                <select-picker formControlName="code" firstOption="Select Code" [options]="allCountryCode" [search]="true"></select-picker>
                <div *ngIf="formGroup.get('code').invalid && formGroup.get('code').touched">
                    <span class="error" *ngIf="formGroup.get('code').hasError('required')">Country code is required</span>
                </div>
            </div>
            <input formControlName="phoneNumber" (keypress)="onlyNumbers($event)" class="form-input" type="text" placeholder="Mobile number" />
            <div *ngIf="formGroup.get('phoneNumber').invalid && formGroup.get('phoneNumber').touched">
                <span class="error" *ngIf="formGroup.get('phoneNumber').hasError('required')">Phone Number is required</span>
                <span class="error" *ngIf="formGroup.get('phoneNumber').errors.invalidPhone">Valid Phone Number is required</span>
            </div>
          </div>
  
          <div class="form-in form-btn">
            <button class="btn w-100" 
           
            >Submit</button>
            <!-- [ngClass]="{'loader': submitted}" -->
          </div>
    
        </form>
      </div>
  
    </div>
  
  
  </div>
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-discount-popup',
  templateUrl: './discount-popup.component.html',
  styleUrls: ['./discount-popup.component.scss']
})
export class DiscountPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService
  ) { }

  params;
  // isAutomatic: boolean = false;

  ngOnInit(): void {
  }
  // <!-- [queryParams]="{brandId: params?.brandId,isAutomatic: false}" -->

  getQueryParams(value) {
    let param: any = {};
    if(value){
      if(this.params.outletId){
        param.outletId = this.params.outletId;
      }
      param.brandId = this.params.brandId;
      param.isAutomatic = value == 1;
      return param;
    }else{
      return {}
    }
  }


  close(){
    this.popupService.hide();
  }

}

<!-- Modal body -->
<div>
    <div class="modal-body">
            <div class="salesModalBody">
                <h5>Connecting sales channels</h5>
                <p class="sm">Let's get you connected!</p>
                <p class="sm">Before we proceed, make sure you have ticked off these two points from the pre-live
                    checklist:</p>
                <p class="">Have you created and published your menu?</p>
                <p class="sm">If you haven't, <a href="javascript: //">click here!</a> After all, customers need to
                    choose
                    what they want to order from you.</p>

                <!-- <p class="">Are your pickup areas set?</p>
                <p class="sm"><a href="javascript: //" (click)="pickupSettings()">Click here</a> to define and share your pickup areas, so we
                    can
                    make sure customers from these areas can order from you!</p> -->
                <p class="sm">If your business is set up and ready, let's go!</p>

                <form [formGroup]="formGroup">
                    <div class="form-in">
                        <div class="checkBox">
                            <input type="checkbox" formControlName="showMessage" id="Cardondelivery">
                            <label for="Cardondelivery" class="f14">Don’t show this message again</label>
                        </div>
                    </div>
                    <button type="button" class="btn sm" (click)="submit()">Ok, I understand</button>
                </form>


            </div>
    </div>
</div>
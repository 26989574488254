<form [formGroup]="formGroup">
  <!-- Modal Header -->
  <div class="modal-header">
    <h5 class="modal-title">{{ label }} <span *ngIf="selectionText">({{selectionText}})</span></h5>
    <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="searchBrowse modal-search form-in">
      <input type="text" formControlName="search" (focus)=onFocus($event.target.value) placeholder="Search" #searchBox>
      <span class="magIcon"><img class="img-fluid" src="assets/images/magIcon.svg"></span>
    </div>
    <div class="borderBottom modal-in" *ngIf="lists && lists.length">
      <div class="form-in"  *ngFor="let list of lists | filter: formGroup.get('search')?.value; let indxItem=index;">
        <div class="checkBox">
          <input type="checkbox" (change)="selectUnSelectItem($event.target.checked, list)"
          [checked]="isItemSelected(list._id) > -1" [id]="'list'+indxItem">
          <label *ngIf="type != 2" for="{{'list'+indxItem}}">{{ list.name }}</label>
          <label *ngIf="type == 2" for="{{'list'+indxItem}}">{{ list.name }} ({{ list.service.name }}) </label>
        </div>
      </div>

      <!-- <div *ngIf="selectedOutlets.length === 0 && formGroup.controls['items'].touched">
        <span class="error"> Checkbox is required, select at least one value.</span>
    </div> -->

    </div>
    <div *ngIf="lists.length === 0">
      <p class="m-48 text-center text mt-3">No Data found</p>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer border-0">
    <button type="button" class="btn-white sm" data-dismiss="modal" (click)="close()">Cancel</button>
    <button type="button" class="btn sm" (click)="save()" [disabled]="maxElementCheckbox && selectedOutlets.length > maxElementCheckbox" [ngClass]="{'disabled': maxElementCheckbox && selectedOutlets.length > maxElementCheckbox}" >Save</button>
  </div>
</form>
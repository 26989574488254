import {
  Component,
  OnInit,
  forwardRef,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  Output, EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'select-picker',
  templateUrl: './select-picker.component.html',
  styleUrls: ['./select-picker.component.scss'],
  host: {
    '(change)': 'onChange($event.target.value)',
    '(blur)': 'onTouched()'
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectPickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectPickerComponent), multi: true }
  ]
})
export class SelectPickerComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, Validator {

  constructor() {
  }

  @Input() options: any[] = [];
  @Input() searchText = '';
  @Input() search = false;
  @Input() withObject = false;
  @Input() isMulti = false;
  @Input() classNames = '';
  @Input() withGroup = false;
  @Input() firstOption = '  ';

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('pickerBox', { static: false }) pickerBox: ElementRef;
  // tslint:disable-next-line:variable-name
  private values = [];
  private _value;
  private selectElement;

  ngOnInit(): void {
    
  }

  private onTouched = () => {
  };
  private onChange = (x: any) => {
  };
  private validateFn: any = () => {
  };

  // on changes
  ngOnChanges(changes: SimpleChanges): void {
    this.options = changes.options.currentValue;
    this.refreshPicker();
  }

  // after view initialize
  ngAfterViewInit(): void {
    // this.logger.log(this._value);
    this.selectElement = this.pickerBox.nativeElement;
    if ($(this.selectElement)) {
      $(this.selectElement).selectpicker();
    } else {
      setTimeout(() => {
        $(this.selectElement).selectpicker();
      }, 2000);
    }
  }

  // get current value
  get value(): any {
    return this._value;
  }

  // set current value
  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    this.setPickerValue(val);
  }

  // set selectPicket value
  setPickerValue(value): void {
    setTimeout(() => {
      this.selectElement.value = value;
      // this.logger.log(this.options)
      if (this.selectElement.value) {
        $(this.selectElement).closest('.fields').addClass('val-select');
      }
      this.refreshPicker();
    }, 100);
  }

  // refresh picker
  refreshPicker(): void {
    setTimeout(() => {
      $(this.selectElement).selectpicker('refresh');
    }, 10);
  }

  // validate select picker
  validate(c: AbstractControl): { [key: string]: any; } {
    return this.validateFn(c);
  }

  // register on change event
  registerOnChange(fn): void {
    this.onChange = fn;
  }

  // register on touch
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  sendEmit(id) {
    if (this.withObject) {
      for (let i = 0; i < this.options.length; i++) {
        if (id == this.options[i]._id) {
          const object = this.options[i];
          this.onSelect.emit(object);
          break;
        }
      }
    }
    else if(this.isMulti) {
      let values = $(this.selectElement).val();
      this.onSelect.emit(values)
    }
  }

  // Update the model and changes needed for the view here.
  writeValue(value): void {
    if (value) {
      this.value = value;
    }else{
      this.value = '';
    }
  }
}

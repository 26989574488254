import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @Input() accept = '';
  @Input() labelName: '';
  @Input() allowedExtension = [];
  @Input() uploaded: any[] = [];
  @Input() preview: boolean = true;
  @Input() canFeature: boolean = false;
  @Input() uploadType: string = 'image';
  @Input() type: string = 'eventImage';
  @Input() maxUpload: number = 10;
  @Input() fileSize: number = 5242880;
  @Input() fileSizeError = 'Please upload file less than 5MB';
  @Input() fileTypes: string[] = ['image/*'];
  @Input() position: string = 'bottom';
  @Input() name: string = '';
  @Input() isImageUpLoaded: boolean;
  @Input() selectDesign = 'logo';
  @Input() reset: boolean = false;
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() featured: EventEmitter<any> = new EventEmitter();
  @Output() onUploaded: EventEmitter<any> = new EventEmitter();

  appId;
  apiUrl = environment.API_END_POINT;
  filteredImage: any;
  uplaodUrl: string;
  formData: FormData;
  dragOver: boolean;
  files: UploadFile[];
  options: UploaderOptions;
  humanizeBytes: Function;
  uploadInput: EventEmitter<UploadInput>;
  fileType: any;
  constructor(
    private toastr: ToastrService,
    private logger: LoggerService) { }

  ngOnInit() {
    if (this.uploaded == undefined) {
      this.uploaded = [];
    }
    const loginUser = JSON.parse(localStorage.getItem('userDetails'));
    this.appId = loginUser.appId,
      this.filteredImage = this.allowedExtension.find(item => item == 'gif');
    this.files = [];
    this.options = {
      concurrency: 1,
      maxUploads: this.maxUpload,
    };
    this.humanizeBytes = humanizeBytes;
    this.uplaodUrl = `${this.apiUrl}common/uploadFile`;
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  ngOnChanges(changes: SimpleChanges) {
    const images = changes.uploaded?.currentValue;
    if (images && images.length) {
      this.uploaded = images;
    }
    if (changes.reset?.currentValue) {
      this.resetFiles();
    }
  }

  get isImage() {
    return (this.imageUrl && this.imageUrl.length) ? true : false;
  }

  get imageUrl() {
    return ((this.position == 'top' && this.uploaded.length) ? this.uploaded[this.uploaded.length - 1].location : (this.position == 'middle' && this.uploaded.length) ? this.uploaded[this.uploaded.length - 1].location : '');
  }

  /**
   * On upload output
   * @param output
   */
  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        const event: UploadInput = {
          type: 'uploadAll',
          url: this.uplaodUrl,
          method: 'POST',
          data: { type: this.uploadType },
          headers: { appid: this.appId }
        };
        if (this.files.length) {
          for (let _file of this.files) {
            let fileType = _file.type.split('/').pop().toLowerCase();
            if (!fileType) {
              fileType = _file.name.split('.').pop().toLowerCase();
            }
            if (!this.allowedExtension.includes(fileType)) {
              this.removeFile(_file.id);
              this.toastr.error('Wrong file format. Only Allowed ' + this.allowedExtension.join(', '), 'Error');
            }
            else if (_file.size > this.fileSize) {
              this.removeFile(_file.id);
              this.toastr.error(this.fileSizeError);
            }
            else {
              this.uploadInput.emit(event);
            }
          }
        }
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        this.files.forEach(file => {
          if (file.response) {
            let resp = file.response;
            if (resp.data) {
              let respData = resp.data;
              this.uploaded.push({ fileId: file.id, ...respData });
              this.onUploaded.emit(this.uploaded);
            }
          }
        });
        break;
    }

    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
  }

  /**
   * Replace file
   */
  replaceFile() {
    // if only one allowed, remove previously
    if (this.maxUpload == 1 && this.uploaded.length) {
      let file = this.uploaded[0];
      this.removeFile(file.fileId)
    }
  }

  /**
   * Cancel upload
   * @param id
   */
  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  /**
   * Remove file
   * @param id 
   */
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  // reset files
  resetFiles() {
    this.files = [];
    this.uploaded = [];
    this.removeAll();
  }

  /**
   * Remove all files
   * @param id 
   */
  removeAll(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  /**
   * Set Featured Image
   * @param imageId
   */
  setFeatured(singleImage) {
    this.uploaded.map(_image => { _image.isFeatured = false; return _image; });
    singleImage.isFeatured = true;
    this.featured.emit(singleImage);
  }

  /**
   * Delete single file
   * @param fileId
   */
  deleteFile(idx, fileId) {
    const confirmed = confirm('Are you sure, you want to delete this file?');
    if (confirmed) {
      // this.uploaded = this.uploaded.filter(_upload => _upload.id != fileId);
      // if(foundIndex){
      this.uploaded.splice(idx, 1);
      // }
      this.deleted.emit({ idx, fileId });
    }
    else {
      return false;
    }
  }

}

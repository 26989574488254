import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscriber } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Linker } from 'src/app/core/linker.functions';
import { BrandDataSelectors, OutletDataSelectors } from 'src/app/redux/actions';
import { IBreadCrumb } from './breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public breadcrumbs: IBreadCrumb[];
  brandId;
  outletId;
  brandName;
  isActivePlan: boolean = false;
  private subscriber: Subscriber<any> = new Subscriber<any>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private route: ActivatedRoute
  ) {

    this.router.events.pipe(
      // identify navigation end
      filter((event) => event instanceof NavigationEnd),
      // now query the activated route
      map(() => this.rootRoute(this.route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
    ).subscribe((route: ActivatedRoute) => {
      this.brandId = route.snapshot.paramMap.get('brandId');
      this.outletId = route.snapshot.paramMap.get('outletId');
    });

    this.store.pipe(select(BrandDataSelectors.detail)).subscribe((data) => {
      if (Object.keys(data).length) {

        if (data && data.length && this.brandId) {
          const foundData = data.find(brand => brand?._id === this.brandId);
          if(foundData){
            this.brandName = foundData.name;
          }
        }
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      this.breadcrumbs = this.breadcrumbs.map((bcrum) => {
        
        bcrum.url = bcrum.url.replace(':brandId', this.brandId);
        // bcrum.label = bcrum.label.replace(':brandId', this.brandId);
        bcrum.url = bcrum.url.replace(':outletId', this.outletId);
        return bcrum;
      });
    });
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  ngOnInit(): void {
    const userData = localStorage.getItem('userDetails');
    if (userData) {
      const loginUser = JSON.parse(userData);
      this.isActivePlan = loginUser.isPlanActive;
    }
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      // path = path.replace(lastRoutePart, label);
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      // label = route.snapshot.params[paramName];
      label = label;
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
    };
    
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  getItemLink(page) {
    if(this.isActivePlan){
      const link = Linker.breadcrumbLink(page)
      return link;
    }
  }

  ngOnDestroy(){
    if(this.subscriber){
      this.subscriber.unsubscribe();
    }
  }

}

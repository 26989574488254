import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { HelperService } from 'src/app/services';

@Component({
  selector: 'app-customer-detail-popup',
  templateUrl: './customer-detail-popup.component.html',
  styleUrls: ['./customer-detail-popup.component.scss']
})
export class CustomerDetailPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private toastr: ToastrService,
    private helperService: HelperService
  ) { }

  params;

  ngOnInit(): void {
  }

  close(){
    this.popupService.hide();
  }

}

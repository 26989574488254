import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService) {    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isTokenExpired()) {
      if(state.url !== '/app/plan-expired' && this.authService.isPlanExpired()){
        this.router.navigate(['app/plan-expired']);
      }

      return true;
    } else {
      this.authService.logout();
      localStorage.clear();
      this.router.navigate(['/'])
      return false;
    }
  }


}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import * as moment from 'moment';
import {Utils} from '../../core';
import {select, Store} from '@ngrx/store';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {AppliedFiltersSelectors} from '../../redux/actions';
import {DashboardActionService} from '../../services';
import {ListingFiltersComponent} from '../../overlays/listing-filters/listing-filters.component';
import {ListingColumnsComponent} from '../../overlays/listing-columns/listing-columns.component';
import {DATE_ATR} from './listing-table.model';
import {DpModalService} from 'src/app/services';
import {ModalService} from 'src/app/modals/modal.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-listing-table',
  templateUrl: './listing-table.component.html',
  styleUrls: ['./listing-table.component.scss']
})
export class ListingTableComponent implements OnChanges, OnInit, AfterViewInit {

  @Input() loading: any;
  @Input() allCounts: any = [];
  @Input() allRecords: any = [];
  @Input() allSettings: any = [];
  @Input() perPage: number = 10;
  @Input() tableName = 'table';
  @Input() noRecords = false;
  @Input() pagination: boolean = true;
  @Input() currency: string = '';
  
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() view: EventEmitter<any> = new EventEmitter();
  @Output() refund: EventEmitter<any> = new EventEmitter();
  @Output() info: EventEmitter<any> = new EventEmitter();
  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() reorder: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() onSwitch: EventEmitter<any> = new EventEmitter();
  @Output() refreshCharts: EventEmitter<any> = new EventEmitter();
  @Output() export: EventEmitter<any> = new EventEmitter();
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() columnClick: EventEmitter<any> = new EventEmitter();
  @Output() multiSelect: EventEmitter<any> = new EventEmitter();
  @Output() manageFunds: EventEmitter<any> = new EventEmitter();
  @Output() viewTransactions: EventEmitter<any> = new EventEmitter();
  @Output() viewRecord: EventEmitter<any> = new EventEmitter();
  @Output() refundRecord: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchBox') searchInput: ElementRef;

  Utils: any = Utils;
  filteredRecords: any = [];
  filteredStatus = 'all';
  isButtonDisabled = false;

  // all columns
  tabFilters: any;
  allColumns: any[] = [];
  allFilters: any[] = [];
  filterTabs: any[] = [];
  actionList: any[] = [];
  actionDrop: any[] = [];
  appliedFilters: any = [];
  visibleCharts: string[] = [];
  selectedRows: string[] = [];
  isStatic = false;
  showTable = true;
  isAllselected = false;
  expandedRecords = false;
  refreshSubscribe: Subscription;
  filterSubscription: Subscription;

  // per page and current page
  pageConfig: any;
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 10;
  pageLengths: any = [10, 20, 30, 40, 50];
  sorting: any = { sortBy: '', sortType: true };

  skip;

  // default settings
  settings: any = {
    perpage: 10,
    viewLink: '',                // link to view screen
    static: true,                // check if table is static
    hasSelection: true,          // if can select from checkbox
    hasDragDrop: false,          // if has drag & drop
    canAdd: true,                // if can add new
    addButtonText: 'Add',        // Default text for add button
    newRecordButton: 'true',     // if can add new
    canEdit: true,               // if can edit record
    canExport: false,            // if can export record
    canRefund: false,             // if can refund record
    canView: false,         // if can view record
    canDelete: true,             // if can delete record
    expandRows: false,           // show expand icon
    filterRows: true,            // show filter rows
    columnsBtn: true,            // show filter rows
    refreshRows: true,           // execute refresh rows
    showCharts: false,           // show row charts
    collapseRows: false,         // show collapsed icon
    hasActions: true,            // show action
    switchName: 'status',        // name of the switch
    chartsPrefix: 'singleChart',
    hasDropDown: false,
    canSearch: false,
    searchPlaceholder: 'Search',
    canManageFund: false,
    canViewTransactions: false,
  };

  constructor(
    private store: Store<any>,
    private modalService: DpModalService,
    private deletePopUpService: ModalService,
    private dashActions: DashboardActionService) {
  }

  ngOnInit() {
    this.onFilterRecords();
    this.onTableRefreshAction();
  }

  ngAfterViewInit() {
    if (this.settings.canSearch) {
      this.searchInit();
    }
  }

  /**
   * Search input Event
   */
  searchInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })// if character length greater then 2 or empty
      , filter(res => res.length > 2 || res.length == 0)
      // wait 300ms after each keystroke before considering the term
      , debounceTime(300),
      // ignore new term if same as previous term
      distinctUntilChanged(),
    ).subscribe((searchKeyword: string) => {
      this.search.emit(searchKeyword);
    });
  }

  /**
   * Receive new values
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if(changes.perPage && changes.perPage.currentValue) {
      this.changePerPage(changes.perPage.currentValue)
    }
    if (changes.allSettings) {
      this.allSettings = changes.allSettings.currentValue;
      this.assignSettings();
    }
    if (changes.allRecords) {
      this.allRecords = changes.allRecords.currentValue;
      this.filterByFirstTab();
    }
    if (changes.allCounts && !this.isStatic) {
      const allCounts = changes.allCounts.currentValue ? changes.allCounts.currentValue : {};
      this.tabFilters = allCounts.filters;
      this.totalItems = allCounts.count;
      this.skip = allCounts.skip;
      // this.currentPage = allCounts.currentPage ? allCounts.currency : this.currentPage;
      this.assignPaginate();
    }
    if (changes.noRecords) {
      this.showTable = !changes.noRecords.currentValue;
    }
  }

  // assign settings
  assignSettings() {
    const _settings = this.allSettings;
    this.settings = { ...this.settings, ..._settings };
    if (_settings.perpage) {
      this.itemsPerPage = _settings.perpage;
    }
    if (_settings.columns) {
      this.allColumns = _settings.columns.map((_column) => {
        _column.className = `column-${Utils.slugify(_column.name)}`;
        return _column;
      });
    }
    if (this.settings.static) {
      this.isStatic = true;
    }
    if (this.settings.filterTabs) {
      this.filterTabs = this.settings.filterTabs;
    }
    if (this.settings.actionList) {
      this.actionList = this.settings.actionList;
    }
    if (this.settings.actionDrop) {
      this.actionDrop = this.settings.actionDrop;
    }
    if (_settings.filters) {
      this.allFilters = _settings.filters;
    }
    // disable drag drop on small screen
    if (_settings.hasDragDrop && Utils.isSmallScreen()) {
      _settings.hasDragDrop = false;
    }

    // refresh records
    this.refreshRecords();
  }

  // assign pagination
  assignPaginate() {
    this.pageConfig = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.skip == 0 ? 1 : this.currentPage,
      totalItems: this.totalItems
    };
    this.currentPage = this.skip == 0 ? 1 : this.currentPage;
  }

  /**
   * Show/Hide Event chart
   * @param _id
   */
  showRowChart(_id) {
    const foundIndex = this.visibleCharts.indexOf(_id);
    if (foundIndex >= 0) {
      this.visibleCharts.splice(foundIndex, 1);
    } else {
      this.visibleCharts.push(_id);
    }
  }

  /**
   * Show/Hide Event chart
   * @param _id
   */
  selectRecord(_id) {
    const foundIndex = this.selectedRows.indexOf(_id);
    if (foundIndex >= 0) {
      this.selectedRows.splice(foundIndex, 1);
    } else {
      this.selectedRows.push(_id);
    }
    this.refreshCharts.next(this.selectedRows);
  }

  // if event is selected
  isSelectedRecord(_index) {
    if (this.selectedRows.length > 0) {
      const foundIndex = this.selectedRows.indexOf(_index);
      if (foundIndex >= 0) {
        return true;
      }
    }
    return false;
  }

  // show charts on event click
  isVisibleChart(_index) {
    if (this.visibleCharts.length > 0) {
      const foundIndex = this.visibleCharts.indexOf(_index);
      if (foundIndex >= 0) {
        return true;
      }
    }
    return false;
  }

  // filter by first tab
  filterByFirstTab() {
    if (this.filterTabs.length && this.isStatic) {
      const firstTab = this.filterTabs[0];
      this.filterRecordByTab(firstTab);
    } else {
      this.filteredRecords = this.allRecords;
    }
  }

  // filter by tab name
  filterByTabName(_filter) {
    if (this.filterTabs.length) {
      const foundTab = this.filterTabs.find(_tab => _tab.filter == _filter);
      if (foundTab) {
        this.filterRecordByTab(foundTab);
      }
    }
  }

  // filter record by tab
  filterRecordByTab(_tab) {
    const _filter = _tab.filter;
    const _filterBy = _tab.filterBy;
    this.filteredStatus = _filter;

    if (!this.isStatic) {
      this.currentPage = 1;
      this.appliedFilters[_filterBy] = _filter;
    } else {
      this.filteredRecords = (_filter == 'all') ? this.allRecords : this.allRecords.filter(_event => _event[_filterBy] == _filter);
    }
    this.refreshRecords();
  }

  // check if action list visible
  isActionListVisible(_action, _record) {
    return (_action.visible) ? _action.visible(_record) : true;
  }

  /**
   * Set current page
   * @param _page
   */
  setCurrentPage(_page) {
    this.currentPage = _page;
    this.refreshRecords();
  }

  // goto event
  gotoPage(event) {
    const _page = event.target.value;
    this.currentPage = _page;
    this.refreshRecords();
  }

  // change per page
  changePerPage(perpage) {
    this.itemsPerPage = perpage;
    this.currentPage = 1;
    this.refreshRecords();
  }

  // get view link
  getViewLink(record, column) {
    let clickLink = '';
    if (record[column.name].elink) {
      clickLink = record[column.name].elink.url;
    }
    return clickLink;
  }

  // get view link params
  getViewLinkParams(record, column) {
    let clickLink = {};
    if (record[column.name].elink) {
      clickLink = record[column.name].elink.data;
    }
    return clickLink;
  }

  // get column text
  getColumnText(record, column) {
    return (record[column.name] && record[column.name].title) ? record[column.name].title : (record[column.name]) ? record[column.name] : '';
  }

  /**
   * Current row count
   * @param _index
   */
  getRowCount(_index) {
    return (this.currentPage - 1) * this.itemsPerPage + _index + 1;
  }

  // check if tab is active
  isTabActive(_filter) {
    return (this.filteredStatus == _filter) ? true : false;
  }

  // count total value
  tabFilterCount(_tab) {
    const _filter = _tab.filter;
    const _filterBy = _tab.filterBy;
    if (!this.isStatic) {
      return (this.tabFilters) ? this.tabFilters[_tab.key] || 0 : 0;
    } else {
      return (this.allRecords) ? (_filter == 'all') ? this.allRecords.length : this.allRecords.filter(_event => _event[_filterBy] == _filter).length : 0;
    }
  }

  /**
   * Sort by Column Name
   * @param _column
   */
  sortColumnData(_column) {
    if (_column.sort) {
      const sortType = (this.sorting.sortType) ? false : true;
      this.sorting = { sortBy: _column.name, sortType };
      if (this.isStatic) {
        this.refreshRecords();
      } else {
        this.sortRecords();
      }
    }
  }

  // on action item click
  onActionItem(_record, _action) {
    const eventData = { id: _action.id, type: 'list', record: _record };
    if (_action.name == 'Delete') {
      this.deleteRecord(_record);
    }
    this.action.next(eventData);
  }

  // on action drop
  onActionDrop(_action) {
    const eventData = { id: _action.id, type: 'drop' };
    this.action.next(eventData);
  }

  // create new record
  openCreateRecord() {
    this.create.next(true);
  }

  // enable disable record
  enableDisable(singleRecord,type) {
    singleRecord[this.settings.switchName] = !singleRecord[this.settings.switchName];
    this.onSwitch.next({ function: this.executeSwitch, value: singleRecord ,type});
  }

  // execute switch
  executeSwitch = (singleRecord, isRevert) => {
    if (isRevert) {
      singleRecord[this.settings.switchName] = !singleRecord[this.settings.switchName];
    }
  }

  // on refresh button click
  onRefreshClick() {
    if (!this.isStatic) {
      this.refreshRecords();
    } else {
      this.refresh.next(true);
    }
  }

  // edit record
  editRecord(_event) {
    this.edit.next(_event);
  }

  // // view record
  // viewRecord(_event) {
  //   this.view.next(_event);
  // }

  // refundRecord(_event) {
  //   this.refund.next(_event);
  // }


  // open info popup
  openInfoPopup(record, name) {
    this.info.next({ record, name });
  }

  // delete record
  deleteRecord(_event) {
    const foundRecord = this.allRecords.findIndex(_record => _record._id == _event._id);
    if (foundRecord >= 0) {
      this.delete.next({execute: this.executeDelete, value: _event, index: foundRecord});
    }
  }

  // delete record
  executeDelete = (recordIndex) => {
    this.filteredRecords.splice(recordIndex, 1);
    // refresh to previous page if no record available in this page
    if(this.currentPage > 1 && !this.filteredRecords.length) {
      this.currentPage = this.currentPage - 1;
      this.refreshRecords();
    }
    // refresh if delete existing page data
    let totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    if(this.currentPage < totalPages) {
      this.refreshRecords();
    }
  }

  // refresh records
  refreshRecords() {
    // apply extra filter on dynamic
    if (!this.isStatic) {
      const recordSkip = (this.currentPage - 1) * this.itemsPerPage;
      this.appliedFilters = {
        ...this.appliedFilters,
        limit: this.itemsPerPage,
        perPage: this.itemsPerPage,
        page: this.currentPage,
        skip: recordSkip,
        sortBy: this.sorting.sortBy,
        sortType: this.sorting.sortType
      };

      this.refresh.next(this.appliedFilters);
    }
    this.assignPaginate();
  }

  /**
   * Filter Data
   */
  sortRecords() {
    // sort array
    const { sortBy, sortType } = this.sorting;
    const filteredRecords = this.filteredRecords.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : a[sortBy] === b[sortBy] ? 0 : -1);
    if (!sortType) {
      filteredRecords.reverse();
    }
    this.filteredRecords = filteredRecords;
  }

  // open task assign
  openTaskAssign(_name) {
    this.multiSelect.emit(this.selectedRows);
  }

  exportRecords() {
    this.export.next(this.selectedRows);
  }

  // refresh tables
  onTableRefreshAction() {
    this.refreshSubscribe = this.dashActions.onRefreshTable().subscribe(
      tableName => {
        if (tableName == this.tableName) {
          if (!this.isStatic) {
            this.refreshRecords();
          } else {
            this.refresh.next(true);
          }
        }
      }
    );
  }

  // expand records
  expandRecords() {
    this.expandedRecords = true;
    // select all events
    if (this.selectedRows.length == 0) {
      this.isAllselected = true;
      this.selectedRows = this.filteredRecords.map(_record => _record.id);
    }
    this.visibleCharts = this.selectedRows;
  }

  // collapse records
  collapseRecords() {
    this.expandedRecords = false;
    this.visibleCharts = [];
    // reset all selected
    if (this.isAllselected) {
      this.isAllselected = false;
      this.selectedRows = [];
    }
  }

  // select all records
  selectAllRecords() {
    if (this.isAllselected) {
      this.selectedRows = this.filteredRecords.map(_record => _record.id);
    } else {
      this.selectedRows = [];
    }
  }

  /**
   * Get record index based on pagination
   * @param index
   */
  getRecordIndex(index) {
    return (this.itemsPerPage * (this.currentPage - 1)) + index;
  }

  /**
   * Open Listing Filter
   */
  openListingFilter() {
    this.modalService.open('listingFilters', ListingFiltersComponent, { windowClass: 'listingFilters' }, {
      tableName: this.tableName,
      filters: this.allFilters
    });
  }

  /**
   * Open Listing Columns
   */
  openListingColumns() {
    this.modalService.open('listingColumns', ListingColumnsComponent, { windowClass: 'listingColumns w500' }, {
      tableName: this.tableName,
      data: { allColumns: this.allColumns }
    });
  }

  /**
   * Filter Records by Filters
   */
  onFilterRecords() {
    this.filterSubscription = this.store.pipe(select(AppliedFiltersSelectors.table_listing)).subscribe(extraFilters => {
      if (extraFilters[this.tableName]) {
        if (!this.isStatic) {
          this.currentPage = 1;
          const allExtraFilters = extraFilters[this.tableName];
          this.appliedFilters.filters = allExtraFilters;
          this.refreshRecords();
        } else {
          this.applyStaticFilters(extraFilters);
        }
      }
    });
  }

  /**
   * Apply static filters
   * @param extraFilters
   */
  applyStaticFilters(extraFilters) {
    if (extraFilters[this.tableName].length) {
      // fitered status records first
      this.filterByTabName(this.filteredStatus);

      const allExtraFilters = extraFilters[this.tableName];
      this.appliedFilters = allExtraFilters;
      const allFilteredRecords = this.filteredRecords;
      const filteredRecords = allFilteredRecords.filter(_record => {
        let availFilters = 0;
        let matchedRecords = 0;
        allExtraFilters.forEach((_filter, index) => {

          if (_filter.value || _filter.value > -1) {
            availFilters += 1;
            // filter type text
            if (_record[_filter.name]) {
              if (_filter.type === 'text' && _record[_filter.name].toLowerCase().indexOf(_filter.value.toLowerCase()) > -1) {
                matchedRecords += 1;
              } else if (['dropdownWithName', 'dropdown'].includes(_filter.type) && _record[_filter.name] == _filter.value) {

                matchedRecords += 1;
              } else if (_filter.type === 'number') {
                if (_filter.value != null && _filter.value !== '' && _filter.value > -1) {
                  matchedRecords += 1;
                }
              }
              else if (_filter.type === 'date') {

                const recordDate = moment(new Date(_record[_filter.name])).utc(true);
                if (_filter.atr === DATE_ATR.START) {
                  const filterDate = Utils.getUTCDate(_filter.value, DATE_ATR.START);
                  const isInRange = recordDate.isSameOrAfter(filterDate);
                  if (isInRange) {
                    matchedRecords += 1;
                  }
                } else {
                  if (_filter.atr === DATE_ATR.END) {
                    const filterDate = Utils.getUTCDate(_filter.value, DATE_ATR.START);
                    const isInRange = recordDate.isSameOrBefore(filterDate);
                    if (isInRange) {
                      matchedRecords += 1;
                    }
                  } else {
                    if (_filter.atr === DATE_ATR.EQUAL) {
                      const filterStartDate = Utils.getUTCDate(_filter.value, DATE_ATR.START);
                      const filterEndDate = Utils.getUTCDate(_filter.value, DATE_ATR.END);
                      const isInRange = recordDate.isBetween(filterStartDate, filterEndDate);
                      if (isInRange) {
                        matchedRecords += 1;
                      }
                      // const isInRange = recordDate.isBetween(_filter.value[0], _filter.value[1]);
                      // if (isInRange) {
                      //   matchedRecords += 1;
                      // }
                    }
                  }
                }
              } else if (_filter.type === 'activeswitch' && _record[_filter.name] == _filter.value) {
                matchedRecords += 1;
              }
            }
          }
        });
        return (matchedRecords == availFilters) ? _record : null;
      });

      this.filteredRecords = filteredRecords;

    } else {
      // reset applied filters
      this.appliedFilters = this.allFilters.map(_filter => {
        _filter.value = '';
        return _filter;
      });
      // fitered status records first
      this.filterByTabName(this.filteredStatus);
    }
  }


  hideShowOptionList(record): boolean {
    let isVisbale = false;
    for (let index = 0; index < this.actionList.length; index++) {
      const action = this.actionList[index];
      const isactive: boolean = (action.visible) ? action.visible(record) : true;
      if (isactive) {
        isVisbale = isactive;
        break;
      }
    }
    return isVisbale;
  }

  // on drop item set
  onRowReorder(event: CdkDragDrop<string[]>) {
    const currentIndex = this.getRecordIndex(event.currentIndex);
    const previousIndex = this.getRecordIndex(event.previousIndex);
    moveItemInArray(this.filteredRecords, previousIndex, currentIndex);
    const singleRecord = this.filteredRecords[currentIndex];
    const reorderData = {record: singleRecord, position: (currentIndex + 1)};
    this.reorder.next(reorderData);
  }

  /**
   * On Destroy component
   */
  ngOnDestroy() {
    if (this.refreshSubscribe) {
      this.refreshSubscribe.unsubscribe();
    }
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

}

<app-cnav-bar></app-cnav-bar>

<div class="All-in">

    <div class="contactThanku">
      <div class="contactThanku-in">
        <div class="ct-img"><img class="img-fluid" src="assets/images/checkThanku.svg"/></div>
        <h1 class="m-16">Thank you for contacting us!</h1>
        <p class="m-32">Our representative will get back to you within 24 hours and help you with your onboarding.</p>
        <a href="javascript: //" (click)="submit()" class="lineLink"><span>Get back to our homepage</span></a>
      </div>
    </div>
  
  
  </div>

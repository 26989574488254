import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiFileUploaderComponent } from './multi-file-uploader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';

@NgModule({
  declarations: [MultiFileUploaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxUploaderModule,
    ReactiveFormsModule
  ],
  exports: [MultiFileUploaderComponent]
})
export class MultiFileUploaderModule { }

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-cash-popup',
  templateUrl: './cash-popup.component.html',
  styleUrls: ['./cash-popup.component.scss']
})
export class CashPopupComponent implements OnInit {
  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private formBuilder: FormBuilder,
    // private toastrService: ToastrService,
    private helperService: HelperService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    amount: new FormControl('', [Validators.compose([Validators.required])]),
  },{validator: this.amountValidator.bind(this)});

  loading = { api: false, form: false };
  params;

  ngOnInit(): void {
  }

  amountValidator(control: AbstractControl) {
    const amount = +control.get('amount').value; // to get value in input tag
      if (amount > this.params?.toPay || amount < this.params?.toPay) {
        control.get('amount').setErrors({invalidAmount: true});
      } else {
          control.get('amount').setErrors(null);
        }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_CASH_COLLECT;
      const payload = this.formGroup.value;
      payload.id = this.params?.id;
      this.httpRequestService.postData(url, payload).subscribe(
        (resp: any) => {
          this.loading.form = false;
          this.helperService.refreshApi({ status: 'complete' });
          this.popupService.hide();
          this.popupService.hide(this.params?.orderId);
        }, (error) => {
          this.loading.form = false;
        }
      )
    } else {
      this.formGroup.markAllAsTouched();
    }
  }


}

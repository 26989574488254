<!-- The Modal -->
<div>
    <!-- Modal Header -->
    <div class="modal-header">
        <h5 class="modal-title">Edit Menu Category</h5>
        <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>

    <app-loader *ngIf="loading.api"></app-loader>

    <!-- Modal body -->
    <div class="modal-body pt-4">
        <div class="" id="WorkingHours">
            <!-- <div class="formoutletHeading">
                <h3 class="strong m-12">Operation Days & Hours</h3>
                <p class="mb-0">Set delivery hours for this outlet</p>
            </div> -->

            <form class="hoursForm" [formGroup]="formGroup">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-in">
                      <div class="radioBtn f14"><input type="radio" formControlName="type"
                          (change)="onAllDaysCheckboxChange($event.target.checked)" value="ALLDAYS" [checked]="isAllDay"
                          id="Cardondelivery" /><label>All days <span class="text-grey">(Mon-Sun)</span></label></div>
                    </div>
                  </div>
            
                  <div class="col-lg-6">
                    <div class="form-in">
                      <div class="radioBtn f14">
                        <input type="radio" value="CUSTOM" (change)="onAllCustomCheckboxChange($event.target.checked)"
                          formControlName="type" id="Cardondelivery1" />
                        <label for="Cardondelivery1">Custom</label>
                      </div>
                    </div>
                  </div>
                </div>
            
                <div class="">
                  <p class="strong f14 m-24">Hours</p>
                </div>
            
                <div *ngIf="formGroup.get('type').value === 'ALLDAYS'">
                  <div formArrayName="hours" *ngFor="let hour of formGroup.get('hours')['controls']; let i=index">
                    <div class="form-in" [formGroupName]="i">
                      <div class="row add-hours-sec">
            
                        <div class="col-md-7">
                          <div class="inputSM">
                            <div class="mr-2">
                              <div class="">
                                <!-- <timepicker formControlName="from"></timepicker> -->
                                <input type="time" formControlName="from" placeholder="10:30 AM">
                              </div>
                              <div *ngIf="hour.get('from')">
                                <div class="errorSpan" *ngIf="hour.get('from').invalid && hour.get('from').touched">
                                  <span class="error" *ngIf="hour.get('from').hasError('required')">Start time is required
                                  </span>
                                </div>
                              </div>
            
                            </div>
                            <p class="strong f13">to</p>
                            <div>
                              <div class="">
                                <input type="time" formControlName="to" placeholder="12:30 PM">
                              </div>
                              <div *ngIf="hour.get('to')">
                                <div class="errorSpan" *ngIf="hour.get('to').invalid && hour.get('to').touched">
                                  <span class="error" *ngIf="hour.get('to').hasError('required')">End time is required
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
            
                        <div class="col-md-2">
                          <span *ngIf="i>0">
                            <a class="delete-icon abs mx-2" href="javascript: //" (click)="removeHour(i)">
                              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384 384"
                                style="enable-background:new 0 0 384 384;" xml:space="preserve">
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                                      <polygon
                                        points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 " />
                                    </g>
                                  </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="f14 blank-btn" href="javascript: //" [disabled]="formGroup.get('hours').invalid"
                    [ngClass]="{'disabled': formGroup.get('hours').invalid}" (click)="addHour()">+ Add
                    hours</button>
                </div>
            
                <div formArrayName="days" *ngIf="formGroup.get('type').value === 'CUSTOM'">
                  <div class="form-in" *ngFor="let daySlot of days.controls; let dayIndex=index" [formGroupName]="dayIndex"
                    [ngClass]="{'borderIn': dayIndex > 0}">
                    <label class="labelFlex"> {{ daySlot.get('name').value }}
                      <div class="fw300">Available
                        <div class="toggleSwitch">
                          <input type="checkbox" formControlName="isAvailable"
                            (change)="onAvailabeleChange($event.target.checked,dayIndex)" /><span></span>
                        </div>
                      </div>
                    </label>
            
                    <div formArrayName="hours"
                      [ngClass]="{'d-block': daySlot.get('isAvailable')?.value == true, 'd-none': daySlot.get('isAvailable')?.value == false }">
                      <div *ngFor="let service of daySlot.get('hours').controls; let timeSlotIndex=index"
                        [formGroupName]="timeSlotIndex">
                        <div class="row add-hours-sec">
                          <div class="col-md-7">
                            <div class="inputSM">
                              <div class="mr-2">
                                <div class="">
                                  <input type="time" formControlName="from" placeholder="10:30 AM">
                                </div>
                                <div *ngIf="service.get('from')">
                                  <div *ngIf="service.get('from').invalid && service.get('from').touched">
                                    <span class="error" *ngIf="service.get('from').hasError('required')">Start Time is
                                      required</span>
                                  </div>
                                </div>
                              </div>
                              <p class="strong f13">to</p>
                              <div>
                                <div class="">
                                  <input type="time" formControlName="to" placeholder="12:30 PM">
                                </div>
                                <div *ngIf="service.get('to')">
                                  <div *ngIf="service.get('to').invalid && service.get('to').touched">
                                    <span class="error" *ngIf="service.get('to').hasError('required')">End Time is required</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-md-2">
                            <span>
                              <a *ngIf="timeSlotIndex>0" class="delete-icon abs mx-2" href="javascript: //"
                                (click)="removeTimeSlotFormGroup(dayIndex,timeSlotIndex)">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384 384"
                                  style="enable-background:new 0 0 384 384;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M64,341.333C64,364.907,83.093,384,106.667,384h170.667C300.907,384,320,364.907,320,341.333v-256H64V341.333z" />
                                        <polygon
                                          points="266.667,21.333 245.333,0 138.667,0 117.333,21.333 42.667,21.333 42.667,64 341.333,64 341.333,21.333 " />
                                      </g>
                                    </g>
                                  </g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                  <g></g>
                                </svg>
                              </a>
                            </span>
                          </div>
                        </div>
            
                      </div>
                      <button class="f14 blank-btn" href="javascript: //" [ngClass]="{'disabled': daySlot.get('hours').invalid}"
                        (click)="addNewTimeSlotFormGroup(dayIndex)">+ Add hours</button>
                    </div>
                  </div>
            
                </div>
                            <!-- <div *ngIf="atleastOneAvailable">
                          <span class="error">Select atleast one day for your availability</span>
                        </div> -->
            
              </form>
            <!-- <a href="javascript: //" (click)="onSubmit()" [ngClass]="{'loader': loading.form}" class="btn next fixedBtn">Next</a> -->
        </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer border-0">
        <button type="button" class="btn-white sm" (click)="close()">Cancel</button>
        <button type="button" [disabled]="loading.form" (click)="onSubmit()" [ngClass]="{'loader': loading.form}" class="btn sm">Update
            Category</button>
    </div>

</div>
import { Component, OnInit } from '@angular/core';
import { API_URL, HttpRequestService } from '@app/core';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-item-popup',
  templateUrl: './assign-item-popup.component.html',
  styleUrls: ['./assign-item-popup.component.scss']
})
export class AssignItemPopupComponent implements OnInit {
  params;
  allManagers: any[] = [];
  loading = { api: false, form: false };
  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 1.5;

  limit = 10;
  skip = 0;
  itemsPerPage = 10;
  itemsToLoad: number = 10;
  totalItems: number = 0;
  isFullListDisplayed: boolean = false;

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private helperService: HelperService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.loading.api = true;
    if (this.params.brandId) {
      this.getAllManagers();
    }
  }

  getAllManagers() {
    const url = API_URL._GET_MENU_ITEMS;
    this.httpRequestService.getData(url, { brand: this.params?.brandId, limit: this.limit, skip: this.skip,skipCategory: this.params?.categoryId, isActive: true, type: 'product' }).subscribe((resp: any) => {
      resp.data.items.forEach(item => {
        this.allManagers.push(item);
      });
      this.totalItems = resp.data.total;
      this.loading.api = false;
    }, (error) => {
      this.loading.api = false;
    }
    );
  }

  assignUser(id) {
    this.loading.form = true;
    const url = API_URL._POST_ASSIGN_MENU_ITEM;
    const payload = {
      category: this.params?.categoryId,
      item: id,
      brand: this.params?.brandId,
      service: this.params?.serviceId
    }
    this.httpRequestService.postData(url, payload).subscribe((resp: any) => {
      this.loading.form = false;
      this.allManagers = this.allManagers.filter(_manager => _manager._id !== resp.data?._id);
      this.toastrService.success('Item assigned successfully');
      this.helperService.refreshApi({ url: API_URL._GET_ALL_MENUS, data: resp.data, menuIndex: this.params.menuIndex });
      this.popupService.hide();
    }, (error) => {
      this.loading.form = false;
    }
    );
  }

  onScrollDown(evt) {
    if(this.totalItems){  
      if (this.skip <= this.totalItems) {
        this.skip += this.itemsToLoad;  
        this.limit = 10;
        this.getAllManagers();
      }else{
        this.isFullListDisplayed = true;
      }
    }
  }

  close() {
    this.popupService.hide();
  }

}

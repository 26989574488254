<select [class]="'cs-select-effect selectpicker ' + classNames" [title]="firstOption" [attr.data-live-search]="(search)"
  [attr.data-live-search-placeholder]="searchText" data-dropup-auto="false" [attr.multiple]="isMulti? '':null" #pickerBox
  (change)="sendEmit($event.target.value)">
  <ng-container *ngIf="!withGroup">
    <option *ngFor="let option of options" [value]="(option.id)? option.id : (option._id) ? option._id : option.value">
      {{option.name}}</option>
  </ng-container>

  <ng-container *ngIf="withGroup">
    <optgroup *ngFor="let group of options" label="{{group.groupName}}">
      <option *ngFor="let option of group.options" [value]="(option.id)? option.id : option.value">{{option.name}}
      </option>
    </optgroup>
  </ng-container>
</select>
<!-- <div> -->
  <div class="manageFunds" id="Transactions">
  <div class="modal-header">
    <h5 class="modal-title">Transactions</h5>
    <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
  </div>

<app-loader *ngIf="loading.api"></app-loader>

  <div class="modal-body" *ngIf="walletData?.length > 0 && !loading.api">
    <div class="transactionBody">
      <table>
        <thead>
        <tr>
          <!-- <th>ID</th> -->
          <th>DATE</th>
          <th>REASON</th>
          <th>AMOUNT</th>
          <th>TYPE</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let wallet of walletData">
          <!-- <td>Paid for <span class="text-blue"> {{ wallet._id }} </span></td> -->
          <td>{{ wallet.createdAt | date: 'd/M/yyyy'}} </td>
          <!-- <td>{{ wallet.reason }} </td> -->
          <td>Paid for <span class="text-blue"> {{ wallet.type === 'debit' ? wallet.orderId : wallet.reason }} </span></td>
          <td>{{currencycode}} {{ wallet.amount }} </td>
          <td> {{wallet.type === 'credit' ? 'Credit' : 'Debit'}} </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class=""
         *ngIf="walletData?.length === 0">
      <p class="m-48 text-center text mt-3">No Data found</p>
    </div>
  
</div>

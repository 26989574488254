<div id="cancelPop">
          <div class="modal-body">
            <div class="declineBody">
              <form [formGroup]="formGroup">
  
                <h6 class="m-8">Why you decline order?</h6>
                <p class="f13 m-24">Select reason below</p>
  
                <div class="form-in" *ngFor="let reason of DeclineOrderReasons">
                  <div class="checkBox"><input type="checkbox" (change)="selectUnSelectItem($event.target.checked, reason)" 
                    [checked]="isItemSelected(reason.id) > -1" [id]="reason.id"><label for="{{reason.id}}">{{ reason.name }}</label>
                  </div>
                  <div class="form-in" *ngIf="reason.isTrue === true">
                    <textarea formControlName="other" type="text" placeholder="E.g. Reason"></textarea>
                    <div *ngIf="formGroup.get('other').invalid && formGroup.get('other').touched">
                        <span class="error" *ngIf="formGroup.get('other').hasError('required')">Reason is
                            required</span>
                    </div>
                </div>
                </div>

                <div *ngIf="selectedReasons.length === 0 && formGroup.controls['reasons'].touched">
                    <span class="error"> Reason is required, select at least one reason.</span>
                </div>
  
                <div class="form-in mb-0 form-btn mt-2">
                  <button class="btn sm" [disabled]="loading.form" [ngClass]="{'loader': loading.form}" (click)="onSubmit()">Confirm</button>
                </div>
  
              </form>
            </div>
          </div>
  </div>
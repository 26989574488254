import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {API_URL, HttpRequestService, Utils} from 'src/app/core';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../shared';
import { HelperService } from 'src/app/services';

@Component({
  selector: 'app-add-money-wallet-popup',
  templateUrl: './add-money-wallet-popup.component.html',
  styleUrls: ['./add-money-wallet-popup.component.scss']
})
export class AddMoneyWalletPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private helperService: HelperService
  ) {
  }

  formGroup: FormGroup = this.formBuilder.group({
    reason: new FormControl('', [Validators.compose([Validators.required])]),
    amount: new FormControl('', [Validators.compose([Validators.required])]),
    type: new FormControl('', [Validators.compose([Validators.required, Validators.min(0.1)])]),
  }, {validator: this.amountValidator.bind(this)});

  loading = {api: false, form: false};
  params;
  currencycode;
  brandId: string;
  walletData: any[] = [];
  allTypes: any[] = [
    {id: 'credit', name: 'Add Fund'},
    {id: 'debit', name: 'Reduce Fund'},
  ];

  ngOnInit(): void {
    this.brandId = this.params?.brandId;
    const brand = JSON.parse(localStorage.getItem('_brand'));
    if (brand && brand.length && this.params?.brandId) {
      const foundData = brand.find(brand => brand?._id === this.params?.brandId);
      if (foundData) {
        this.currencycode = foundData?.currency?.name;
      }
    }
    
  }

  updateBalance() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_WALLET_DATA;
      const formData = Utils.cloneObj(this.formGroup.value);
      const payload: any = {id: this.params.walletId, type: formData.type, amount: +formData.amount, reason: formData.reason, brand: this.brandId};
      this.httpRequestService.postData(url, payload).subscribe(
      (resp) => {
        this.loading.form = false;
        if (payload.type === 'debit') {
          this.toastr.success('Fund debited form wallet successfully.');
        }
        if (payload.type === 'credit') {
          this.toastr.success('Fund credited form wallet successfully.');
        }
        this.helperService.refreshApi(API_URL._GET_WALLET_DATA);
        this.popupService.hide();
      }, (error) => {
        this.loading.form = false;
      });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  amountValidator(control: AbstractControl) {
    const type = control.get('type').value; // to get value in input tag
    const amount = +control.get('amount').value; // to get value in input tag
    if (type === 'debit') {
      if (amount > this.params.balance) {
        control.get('amount').setErrors({invalidAmount: true});
      } else {
        if (amount < 0) {
          control.get('amount').setErrors({minAmount: true});
        } else {
          control.get('amount').setErrors(null);
          return null;
        }
      }
    } else {
      if (type === 'credit') {
        control.get('amount').setErrors(null);
        if (amount < 0) {
          control.get('amount').setErrors({minAmount: true});
        } else {
          control.get('amount').setErrors(null);
          return null;
        }
      }
    }
  }

  close() {
    this.popupService.hide();
  }

}

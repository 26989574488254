import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-create-cuisine-popup',
  templateUrl: './create-cuisine-popup.component.html',
  styleUrls: ['./create-cuisine-popup.component.scss']
})
export class CreateCuisinePopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService
  ) { }

  params;
  formGroup: FormGroup = this.formBuilder.group({
    name:      new FormControl('', [Validators.compose([Validators.required])]),
  });

  ngOnInit() {
  if(this.params?.type === 1){
    this.formGroup.patchValue(this.params.record);
  }
  
  }

  submit(){
    this.popupService.hide();
    if(this.formGroup.valid){
      const url = '';
      const params = this.formGroup.value;
      this.httpRequestService.postData(url,params).subscribe(
        (resp: any)=>{
        }
      )
    }else{
      this.formGroup.markAllAsTouched();
    }
  }

  close(){
    this.popupService.hide();
  }

}

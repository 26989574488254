<div class="modalDetails">
    <div class="modal-header">
        <h5 class="modal-title">Loyality History ({{params.userName}})</h5>
        <button type="button" class="close" (click)="close()">&times;</button>
    </div>

    <div class="modal-body">
        <app-listing-table
            tableName="loyaltyHistory"
            [loading]="loading"
            [allCounts]="allCounts"
            [allRecords]="allRecords"
            [allSettings]="tableSettings"
            (refresh)="refreshRecords($event)"
        >
        </app-listing-table>
    </div>
</div>
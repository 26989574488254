import {SETTINGS_DATA_CONSTANTS, SettingsDataActionTypes} from '../actions';


const initialState = {};

export function SettingsDataReducer(state = initialState, action: SettingsDataActionTypes) {
  switch (action.type) {
    case SETTINGS_DATA_CONSTANTS.SAVE_SETTINGS_DATA:
      return action.payload

    case SETTINGS_DATA_CONSTANTS.RESET_SETTINGS_DETAIL:
      return initialState;

    default:
      return state;
  }
}

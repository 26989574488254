import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeclineScheduledOrderPopupComponent } from '../decline-scheduled-order-popup/decline-scheduled-order-popup.component';
import { AcceptScheduledOrderPopupComponent } from '../accept-scheduled-order-popup/accept-scheduled-order-popup.component';
import { API_URL, HttpRequestService } from 'src/app/core';
import { MarkStatusReadyPopupComponent } from '../mark-status-ready-popup/mark-status-ready-popup.component';
import { CashPopupComponent } from '../cash-popup/cash-popup.component';
import { AssignRiderPopupComponent } from '../assign-rider-popup/assign-rider-popup.component';

@Component({
  selector: 'app-new-scheduled-order-popup',
  templateUrl: './new-scheduled-order-popup.component.html',
  styleUrls: ['./new-scheduled-order-popup.component.scss']
})
export class NewScheduledOrderPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
  ) { }

  allScheduledOrders: any[] = [];
  params;
  allPaymentMethods;
  currencycode;
  loading = { api: false, form: false };
  allOrders: any[] = [];
  permissions: any[] = [];
  isAcceptRejectAvailable: boolean = false;

  ngOnInit(): void {

    if (!this.params?.isStatic) {
      this.getAllOrders();
    }
    if (this.params.brandId) {
      const brand = JSON.parse(localStorage.getItem('_brand'));
      if (brand && brand.length && this.params.brandId) {
        const foundData = brand.find(brand => brand?._id === this.params.brandId);
        if (foundData) {
          this.currencycode = foundData?.currency?.name;
        }
      }
    }
    const allSettings = JSON.parse(localStorage.getItem('_settings'));
    const userDetail = JSON.parse(localStorage.getItem('userDetails'));
    this.permissions = userDetail?.role?.permissions.map(_permission => _permission?.slug) || [];
    if (this.permissions.length) {
      this.permissions = [...this.permissions, 'orders-actions'];
      this.isAcceptRejectAvailable = this.permissions.includes('orders-actions');
    }
  }

  getAllOrders() {
    this.loading.api = true;
    const url = API_URL._GET_ALL_ORDERS;
    let params = {
      // brand: this.params?.brandId,
      // outlet: this.params?.outletId,
      // id: this.params?.data?.actionId || this.params?.data?.orderId,
      orderId: this.params?.orderId
    };
    this.httpRequestService.getData(url, params).subscribe(
      (resp: any) => {
        this.params.data = resp.data;
        // this.allCounts = {count: resp.data?.total};
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    );
  }

  cancelOrder() {
    const initialState = {
      params: { ...{ id: this.params?.data?._id, status: this.params?.data?.status, orderId: this.params?.data?.orderId } },
    };
    this.popupService.show(DeclineScheduledOrderPopupComponent,
      { id: 16, animated: true, class: 'cancelPop', initialState: { ...initialState } });
    this.popupService.hide(this.params?.data?.orderId);
  }

  acceptOrder() {
    const initialState = {
      params: { ...{ id: this.params?.data?._id, status: this.params?.data?.status, orderId: this.params?.data?.orderId, averagePreparationTime: this.params?.data?.averagePreparationTime } },
    };
    this.popupService.show(AcceptScheduledOrderPopupComponent,
      { id: 16, animated: true, class: 'acceptPop', initialState: { ...initialState } });
    this.popupService.hide(this.params?.data?.orderId);

  }

  markAsReady(title) {
    if ((((title === 'delivered' || title === 'served')) || (title === 'picked' && this.params?.data?.type === 'pickup') || (title === 'picked' && this.params?.data?.bulkType === 'takeaway')) && this.params.data.payMethod === 'cod') {
      const initialState = {
        params: { ...{ id: this.params?.data?._id, currencyCode: this.currencycode, toPay: this.params?.data?.toPay, status: this.params?.data?.status, type: this.params?.data?.type, bulkType: this.params?.data?.bulkType, orderId: this.params?.data?.orderId, title } },
      };
      this.popupService.show(CashPopupComponent,
        { id: 18, animated: true, class: 'yesNo', initialState: { ...initialState } });
      this.popupService.hide(this.params?.data?.orderId);
    } else {
      if (title === 'picked' && (this.params?.data?.type === 'delivery' || this.params?.data?.bulkType === 'delivery')) {
        const initialState = {
          params: { ...{ id: this.params?.data?._id, brandId: this.params.brandId, outletId: this.params.outletId, orderId: this.params?.data?.orderId, status: this.params?.data?.status, type: this.params?.data?.type, bulkType: this.params?.data?.bulkType, title } },
        };
        this.popupService.show(MarkStatusReadyPopupComponent,
          { id: 18, animated: true, class: 'yesNo', initialState: { ...initialState } });
        // this.popupService.show(AssignRiderPopupComponent,
        //   {id: 25, animated: true, class: 'manageFunds modal-lg', initialState: {...initialState}});
        this.popupService.hide(this.params?.data?.orderId);
      } else {
        const initialState = {
          params: { ...{ id: this.params?.data?._id, status: this.params?.data?.status, type: this.params?.data?.type, bulkType: this.params?.data?.bulkType, title, orderId: this.params?.data?.orderId } },
        };
        this.popupService.show(MarkStatusReadyPopupComponent,
          { id: 18, animated: true, class: 'yesNo', initialState: { ...initialState } });
        this.popupService.hide(this.params?.data?.orderId);
      }
    }
  }
}

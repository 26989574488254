<!-- The Modal -->
<!-- Modal Header -->
<div class="modal-header">
  <h5 class="modal-title">Refund {{ params.data.orderId }}, Order amount: {{currencycode}} {{ params.data.total }}</h5>
  <button type="button" class="close" (click)="close()">&times;</button>
</div>

<!-- Modal body -->
<div class="modal-body">
  <div class="ordeRefundBody">
    <form [formGroup]="formGroup">
      <div class="form-in">
        <label>Amount</label>
        <input type="number" formControlName="amount" (keypress)="onlyNumbers($event)" placeholder="Eg. 100" />
        <div *ngIf="formGroup.get('amount').invalid && formGroup.get('amount').touched">
          <span class="error" *ngIf="formGroup.get('amount').hasError('required')">Amount is required</span>
          <span class="error" *ngIf="formGroup.get('amount').errors?.invalidAmount">Amount should be greater than order amount</span>
        </div>
      </div>

      <div class="form-in">
        <label>Transfer Type</label>
        <div class="select-div">
          <select-picker formControlName="type" firstOption="Select any type" [options]="allTransferTypes">
          </select-picker>
          <div *ngIf="formGroup.get('type').invalid && formGroup.get('type').touched">
            <span class="error" *ngIf="formGroup.get('type').hasError('required')">Transfer Type is
              required</span>
          </div>
        </div>
      </div>

      <div class="form-in">
        <label>Refund Reason</label>
        <div class="select-div">
          <select-picker formControlName="reason" firstOption="Select any reason" [options]="allRefundReason">
          </select-picker>
          <div *ngIf="formGroup.get('reason').invalid && formGroup.get('reason').touched">
            <span class="error" *ngIf="formGroup.get('reason').hasError('required')">Refund Reason is
              required</span>
          </div>
        </div>
      </div>

      <div class="form-in">
        <label>Other</label>
        <textarea formControlName="notes" rows="4"></textarea>
        <!-- <div *ngIf="formGroup.get('other').invalid && formGroup.get('other').touched">
                    <span class="error" *ngIf="formGroup.get('other').hasError('required')">Name is required</span>
                </div> -->
      </div>

      <div class="form-in btn-grp">
        <a href="javascript: //" class="btn-white sm" (click)="close()">Cancel</a>
        <button class="btn sm" [disabled]="loading.form" [ngClass]="{'loader': loading.form}" (click)="submit()">Submit details</button>
      </div>
    </form>
  </div>
</div>
<app-nav-bar></app-nav-bar>

<div class="All-in">
  <div id="loginSection" class="loginSection">
    <div class="heading">
      <h2 class="m-12"><span>Welcome to Delivery Papa</span> <span>Forgot Password</span></h2>
      <p class="m-32 sm"><span>Type your login information and we will take you to your dashboard right away.</span>
        <span>We will send and email regarding to your password reset request</span>
      </p>
    </div>

    <div class="form-sec loginForm">
      <form class="signup-form" [formGroup]="formGroup">
        <div class="form-in">
          <label>App Id (Optional)</label>
          <input class="form-input" type="number" formControlName="appId" placeholder="Ex. 987654321" />
        </div>
        
        <div class="form-in">
          <label>Email Address</label>
          <input class="form-input" formControlName="email" type="text" placeholder="Ex. you@example.com" />
          <div *ngIf="formGroup.get('email').invalid && formGroup.get('email').touched">
            <span class="error" *ngIf="formGroup.get('email').hasError('required')">Email is required</span>
            <span class="error" *ngIf="formGroup.get('email').errors.invalidEmail">Valid email is required</span>
          </div>
        </div>

        <div class="form-in">
          <label>Password</label>
          <div class="showpass">
            <input class="form-input" formControlName="password" [type]="show ? 'text' : 'password'"
              placeholder="Type your password" />
            <a href="javascript:;" class="toggle-password password-view" (click)="showHidePassword()">{{show ? 'Hide' :
              'Show'}}</a>
          </div>
          <div
            [ngClass]="{'formMessage': (formGroup.get('password').errors?.minlength) && (formGroup.get('password').errors?.invalidPass)}"
            class="formMessage"
            *ngIf="formGroup.get('password').invalid && formGroup && formGroup.get('password').touched">
            <span class="error" *ngIf="formGroup.get('password').hasError('required')">Password is required</span>
          </div>
        </div>

        <div class="form-in form-btn">
          <button (click)="login()" class="btn w-100" [ngClass]="{'loader': loading.form}">Login</button>

        </div>
        <a href="javascript: //" (click)="forgotPassword()" class=" blank-btn text-center d-block w-100">Forgot
          password?</a>
      </form>

      <form class="forgot-form" [formGroup]="forgotFormGroup">
        <div class="form-in">
          <label>App Id (Optional)</label>
          <input class="form-input" type="number" formControlName="appId" placeholder="Ex. 987654321" />
        </div>

        <div class="form-in">
          <label>Email Address</label>
          <input class="form-input" type="text" formControlName="email" placeholder="Ex. you@example.com" />
          <span class="success d-none">An email has been sent to your mail</span>

          <div *ngIf="forgotFormGroup.get('email').invalid && forgotFormGroup.get('email').touched">
            <span class="error" *ngIf="forgotFormGroup.get('email').hasError('required')">Email is required</span>
            <span class="error" *ngIf="forgotFormGroup.get('email').errors?.invalidEmail">Valid email is required</span>
          </div>
        </div>

        <div class="form-in form-btn">
          <button (click)="onForgotPasswordSubmit()" [ngClass]="{'loader': loading.form}"
            class="btn w-100">Submit</button>
        </div>
        <a href="javascript: //" (click)="signIn()" class="blank-btn text-center d-block w-100">Sign In</a>
      </form>
    </div>
  </div>
</div>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchAreaPopupComponent} from './search-area-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@app/directives';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

const routes: Routes = [
    { path: '', component: SearchAreaPopupComponent, 
}];

@NgModule({
  declarations: [
    SearchAreaPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    Ng2SearchPipeModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    SearchAreaPopupComponent
  ],
})
export class SearchAreaPopupModule {
}

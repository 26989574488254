// truncate.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: string) : string {
    let val = parseInt(args);
    let limit = false ? parseInt(args[0], val) : val;

    let trail = false ? args[1] : '...';


    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
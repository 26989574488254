import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AcceptScheduledOrderPopupComponent} from './accept-scheduled-order-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule, SelectPickerModule } from '../../directives';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
    { path: '', component: AcceptScheduledOrderPopupComponent, 
}];

@NgModule({
  declarations: [
    AcceptScheduledOrderPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    SelectPickerModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    AcceptScheduledOrderPopupComponent
  ],
})
export class AcceptScheduledOrderPopupModule {
}

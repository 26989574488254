<!-- The Modal -->
<div>
    <!-- Modal Header -->
    <div class="modal-header">
        <h5 class="modal-title">Create New Customer Group</h5>
        <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>

    <app-loader *ngIf="loading.api"></app-loader>

    <!-- Modal body -->
    <div class="modal-body">
        <form class="fundsBody" [formGroup]="formGroup">

            <div class="form-in">
                <label class="f16 mb-2">Name</label>
                <input formControlName="name" type="text" placeholder="E.g. Group">
                <div *ngIf="formGroup.get('name').invalid && formGroup.get('name').touched">
                    <span class="error" *ngIf="formGroup.get('name').hasError('required')">Name is
                        required</span>
                </div>
            </div>

            <div class="form-in">
                <label>Select Customer</label>
                <div class="select-div">
                    <div class="selectChip">
                        <ng-select formControlName="users" firstOption="Select customer" [multiple]="true"
                            [items]="allUsers" [closeOnSelect]="false" bindLabel="name" bindValue="_id" name="values">
                        </ng-select>
                    </div>
                    <div *ngIf="formGroup.get('users').invalid && formGroup.get('users').touched">
                        <span class="error" *ngIf="formGroup.get('users').hasError('required')">Customer is
                            required</span>
                    </div>
                </div>
            </div>

            <div class="form-in">
                <label>Type</label>
                <div class="select-div">
                    <select-picker formControlName="type" firstOption="Select any type" [options]="customerGroupType"></select-picker>
                    <div *ngIf="formGroup.get('type').invalid && formGroup.get('type').touched">
                        <span class="error"
                            *ngIf="formGroup.get('type').hasError('required')">Type is
                            required</span>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer border-0">
        <button type="button" class="btn-white sm" (click)="close()">Cancel</button>
        <button type="button" (click)="submit()" [disabled]="loading.form" [ngClass]="{'loader': loading.form}" class="btn sm">Submit</button>
    </div>

</div>
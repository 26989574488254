import {Component, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {API_URL, HttpRequestService} from 'src/app/core';
import { Utils } from '../../core/utility.functions';
@Component({
  selector: 'app-view-transaction-popup',
  templateUrl: './view-transaction-popup.component.html',
  styleUrls: ['./view-transaction-popup.component.scss']
})
export class ViewTransactionPopupComponent implements OnInit {
  params;
  walletData: any[] = [];
  loading = {api: false};
  currencycode;

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService
  ) {
  }

  ngOnInit(): void {
    const brand = JSON.parse(localStorage.getItem('_brand'));
    if (brand && brand.length && this.params?.brandId) {
      const foundData = brand.find(brand => brand?._id === this.params?.brandId);
      if (foundData) {
        this.currencycode = foundData?.currency?.name;
      }
    }
    this.getSingleTransaction();
  }

  getSingleTransaction() {
    this.loading.api = true;
    const url = API_URL._GET_USER_VIEW_TRANSACTION;
    const params: any = {
      wallet: this.params.walletId,
      limit: 10000,
      skip: 0,
    };
    this.httpRequestService.getData(url, params).subscribe((resp: any) => {
        this.walletData = this.parseRecords(resp.data.transactions);
        this.loading.api = false;
      }, (error) => {
        this.loading.api = false;
      }
    );
  }

  parseRecords(allRecords) {
    return allRecords.map(record => {
      record.amount = Utils.toPrice(record?.amount);
      return record;
    });
  }

  close() {
    this.popupService.hide();
  }
}

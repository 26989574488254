import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidator } from '../../../validators/index';
import { API_URL } from '../../core/api-end-points';
import { HttpRequestService } from '../../core/http-request.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss']
})
export class ContactSalesComponent implements OnInit {
  cnamePattern = "[a-zA-Z0-9]*";

  submitted: boolean = false;
  allCountries: any[] = [];

  allCountryCode: any[] =[];

  formGroup: FormGroup = this.formBuilder.group({
    companyName: new FormControl('', [Validators.compose([Validators.required, Validators.pattern(this.cnamePattern)])]),
    outlets:     new FormControl('', [Validators.compose([Validators.required])]),
    email:       new FormControl('', [Validators.compose([Validators.required,FormValidator.validEmail])]),
    country:     new FormControl('', [Validators.compose([Validators.required])]),
    code:        new FormControl('',Validators.compose([Validators.required])),
    phoneNumber: new FormControl('', Validators.compose([Validators.required, FormValidator.validPhone]))
  });

  constructor(
    private formBuilder: FormBuilder,
    private httpRequestService : HttpRequestService,
    private route: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getCountries();
    this.getCountryCode();
  }

  getCountries(){
    const url = API_URL._GET_COUNTRY_CODE;
    this.httpRequestService.getData(url,{}).subscribe(
      (resp: any)=> {
        this.allCountries = resp.country;
      }
    ),(error)=> {
    }
  }

  getCountryCode(){
    const url = API_URL._GET_COUNTRY_CODE;
    this.httpRequestService.getData(url,{}).subscribe(
      (resp: any)=> {
        this.allCountryCode = this.parseRecord(resp.countryCode);
      }
    ),(error)=> {
    }
  }

  parseRecord(allRecords){
    return allRecords.map(record => {
      record.name = `+${record.dilerCode}(${record.ISOCode})`;
      return record;
    });
  }

  onlyNumbers(event){
    FormValidator.onlyNumbers(event);
  }

  onContactSubmit(){
    this.submitted = true;
    if(this.formGroup.invalid){
      this.formGroup.markAllAsTouched();
    }
    const param = this.formGroup.value;
    const url = API_URL._GET_COUNTRY_CODE;
    this.httpRequestService.postData(url,param).subscribe(
      (resp: any)=> {
        this.submitted = false;
        this.route.navigate(['/contact-thankyou']);
      },(error)=> {

      });
    

  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoyaltyHistoryPopup } from './loyality-history-popup.component';
import { ListingTableModule, LoaderModule, SelectPickerModule } from '@app/directives';

const routes: Routes = [
  { path: '', component: LoyaltyHistoryPopup }
];

@NgModule({
  declarations: [
    LoyaltyHistoryPopup
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    ListingTableModule,
    SelectPickerModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    LoyaltyHistoryPopup
  ],
})
export class LoyaltyHistoryPopupModule {
}

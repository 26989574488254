import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { API_URL, HttpRequestService, Utils } from 'src/app/core';
import { Store, select } from '@ngrx/store';
import { StepDataActions, StepDataSelectors } from '../../redux/actions';
import { ActivatedRoute } from '@angular/router';
import { Subscriber } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@app/services';

@Component({
  selector: 'app-edit-menu-category-popup',
  templateUrl: './edit-menu-category-popup.component.html',
  styleUrls: ['./edit-menu-category-popup.component.scss']
})
export class EditMenuCategoryPopupComponent implements OnInit {
  formGroup: FormGroup = this.formBuilder.group({
    type: new FormControl('CUSTOM', []),
    hours: this.formBuilder.array([
      this.addHoursForm()
    ]),
    days: this.formBuilder.array([]),
  });

  addHoursForm(): FormGroup {
    return this.formBuilder.group({
      from: new FormControl('', []),
      to: new FormControl('', []),
    });
  }

  params;
  loading = { api: false, form: false }
  step: number;
  allDay: any[] = [
    {
      id: 1,
      name: 'MONDAY',
      isAvailable: false,
      hours: []
    },
    {
      id: 2,
      name: 'TUESDAY',
      isAvailable: false,
      hours: []
    },
    {
      id: 3,
      name: 'WEDNESDAY',
      isAvailable: false,
      hours: []
    },
    {
      id: 4,
      name: 'THURSDAY',
      isAvailable: false,
      hours: []
    },
    {
      id: 5,
      name: 'FRIDAY',
      isAvailable: false,
      hours: []
    },
    {
      id: 6,
      name: 'SATURDAY',
      isAvailable: false,
      hours: []
    },
    {
      id: 7,
      name: 'SUNDAY',
      isAvailable: false,
      hours: []
    },
  ];

  get days(): FormArray {
    return this.formGroup.get('days') as FormArray;
  }

  atleastOneAvailable: boolean = false;
  outletId;
  selectedArray: any[] = [];
  editMode: boolean = false;
  brandId;
  id;
  private subscriber: Subscriber<any> = new Subscriber<any>();

  constructor(
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private store: Store<any>,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private bsModalService: BsModalService,
    private helperService: HelperService
  ) {
  }

  ngOnInit(): void {
    const formData = this.params?.item?.availableHours;
    if (formData) {
      if (formData.type) {
        this.formGroup.get('type').patchValue(formData.type)
      }
      this.formGroup.patchValue(formData);
      if (formData.hours && formData.hours.length) {
        this.formGroup.setControl('hours', this.setExistingHours(formData.hours));
      }
      if (formData.days && formData.days.length) {
        formData.days.forEach((day, index) => {
          this.createDaySlotsForm(day, index);
        });
      }
      else {
        this.allDay.forEach((day, index) => {
          this.createDaySlotsForm(day, index);
        });
      }
    }else {
      this.allDay.forEach(day => {
        this.createDaySlotsForm(day);
      });
    }
  }

  setExistingHours(hours): FormArray {
    const formArray = new FormArray([]);
    hours.forEach(hour => {
      formArray.push(this.formBuilder.group({
        from: new FormControl(hour.from, []),
        to: new FormControl(hour.to, [])
      }));
    });
    return formArray;
  }

  getData() {
    if (this.id) {
      this.editMode = true;
    }
  }

  createDaySlotsForm(data?, index?) {
    const dayForm: FormGroup = this.formBuilder.group({
      name: new FormControl('', []),
      isAvailable: new FormControl(false, []),
      hours: this.formBuilder.array([]),
    });

    if (data) {
      if (data.name) {
        dayForm.get('name').patchValue(data.name);
      }

      if (data.isAvailable == true) {
        this.selectedArray.push(index);
        dayForm.get('isAvailable').patchValue(data.isAvailable);
      } else {
        dayForm.get('isAvailable').patchValue(false);
      }

      if (data.hours) {
        const hours: FormArray = dayForm.get('hours') as FormArray;
        while (hours.length) {
          hours.removeAt(0);
        }

        data.hours.forEach(item => {
          const slotForm = this.createTimeSlotFormGroup(item);
          hours.push(slotForm);
        });
      }
      this.days.push(dayForm);
    }
  }

  addHour() {
    (<FormArray>this.formGroup.get('hours')).push(this.addHoursForm());
  }

  removeHour(i: number) {
    (<FormArray>this.formGroup.get('hours')).removeAt(i);
  }

  createTimeSlotFormGroup(data?) {
    const slotForm = this.formBuilder.group({
      from: new FormControl('', [Validators.compose([Validators.required])]),
      to: new FormControl('', [Validators.compose([Validators.required])]),
    });

    if (data) {
      if (data.from) {
        slotForm.get('from').patchValue(data.from);
      } else {
        slotForm.get('from').patchValue('');
      }
      if (data.to) {
        slotForm.get('to').patchValue(data.to);
      } else {
        slotForm.get('to').patchValue('');
      }
    }
    return slotForm;
  }

  addNewTimeSlotFormGroup(dayFormIndex) {
    const dayForm: FormGroup = this.days.at(dayFormIndex) as FormGroup;
    const hours: FormArray = dayForm.get('hours') as FormArray;
    const form = hours.controls[dayFormIndex] as FormGroup;
    if (form) {
      // form.get('from').setValidators([Validators.required]);
      // form.get('from').updateValueAndValidity({ onlySelf: true });
      // form.get('to').setValidators([Validators.required]);
      // form.get('to').updateValueAndValidity({ onlySelf: true });
    }
    hours.push(this.createTimeSlotFormGroup());
  }

  removeTimeSlotFormGroup(dayFormIndex, slotFormIndex) {
    const dayForm: FormGroup = this.days.at(dayFormIndex) as FormGroup;
    const hours: FormArray = dayForm.get('hours') as FormArray;
    hours.removeAt(slotFormIndex);
  }

  onAllDaysCheckboxChange(checked) {
    const form = this.formGroup.get('hours') as FormArray;
    for (let i = 0; i < form.controls.length; i++) {
      const hourControl = form.controls[i];
      if (checked) {
        hourControl.get('from').setValidators([Validators.compose([Validators.required])]);
        hourControl.get('from').updateValueAndValidity({ onlySelf: true });
        hourControl.get('to').setValidators([Validators.compose([Validators.required])]);
        hourControl.get('to').updateValueAndValidity({ onlySelf: true });
      }
    }
    for (let i = 0; i < this.days.controls.length; i++) {
      const formGroup: FormGroup = this.days.controls[i] as FormGroup;
      const data: FormArray = formGroup.get('hours') as FormArray;
      for (let i = 0; i < data.controls.length; i++) {
        const hourControl = data.controls[i];
        if (checked) {
          hourControl.get('from').setValidators([]);
          hourControl.get('from').updateValueAndValidity({ onlySelf: true });
          hourControl.get('to').setValidators([]);
          hourControl.get('to').updateValueAndValidity({ onlySelf: true });

        }
      }
    }
  }

  onAvailabeleChange(checked, dayIndex, slotIndex) {
    const indexFound = this.itemSelected(dayIndex);
    if (checked) {
      this.addNewTimeSlotFormGroup(dayIndex);
      for (let i = 0; i < this.days.controls.length; i++) {
        const formGroup: FormGroup = this.days.controls[i] as FormGroup;
        if(formGroup.get('isAvailable').value === true){
          this.atleastOneAvailable = false;
        }
        const formArray = formGroup.get('hours') as FormArray;
        const form = formArray.controls[i] as FormGroup;

        if (form) {
          // form.get('from').setValidators([Validators.required]);
          // form.get('from').updateValueAndValidity({ onlySelf: true });
          // form.get('to').setValidators([Validators.required]);
          // form.get('to').updateValueAndValidity({ onlySelf: true });

        }
      }
      if (indexFound == -1) {
        this.selectedArray.push(dayIndex);
      }
    } else {
      for (let i = 0; i < this.days.controls.length; i++) {
        const formGroup: FormGroup = this.days.controls[i] as FormGroup;
        const formArray = formGroup.get('hours') as FormArray;
        const form = formArray.controls[i] as FormGroup;
        this.removeTimeSlotFormGroup(dayIndex, i);

        if (form) {
          // form.get('from').clearValidators();
          // form.get('from').setValidators([]);
          // form.get('from').updateValueAndValidity({ onlySelf: true });

          // form.get('to').clearValidators();
          // form.get('to').setValidators([]);
          // form.get('to').updateValueAndValidity({ onlySelf: true });

        }
      }

      if (indexFound > -1) {
        this.selectedArray.splice(indexFound, 1);
      }
    }
  }

  itemSelected(id) {
    const findIndex = this.selectedArray.findIndex(itemid => itemid == id);
    return findIndex;
  }

  get isAllDay(): boolean {
    let isAll = false;
    if (this.formGroup.get('days')) {
      for (let i = 0; i < this.days.controls.length; i++) {
        const formGroup: FormGroup = this.days.controls[i] as FormGroup;
        isAll = formGroup.get('isAvailable').value;
        if (!isAll) {
          break;
        }
      }
    }
    return isAll;
  }

  onAllCustomCheckboxChange(checked) {
    if (this.formGroup.get('days')) {
      for (let i = 0; i < this.days.controls.length; i++) {
        const formGroup: FormGroup = this.days.controls[i] as FormGroup;
        const data = formGroup.get('hours').value;
        if (!data.length) {
          if (checked) {
            formGroup.get('isAvailable').patchValue(false);
          } else {
          }
        }
      }
    }
    const form = this.formGroup.get('hours') as FormArray;
    for (let i = 0; i < form.controls.length; i++) {
      const hourControl = form.controls[i];
      if (checked) {
        hourControl.get('from').clearValidators();
        hourControl.get('from').setValidators([]);
        hourControl.get('from').updateValueAndValidity({ onlySelf: true });

        hourControl.get('to').clearValidators();
        hourControl.get('to').setValidators([]);
        hourControl.get('to').updateValueAndValidity({ onlySelf: true });

        hourControl.clearValidators();
        hourControl.setValidators([]);
        hourControl.updateValueAndValidity({onlySelf: true});

        form.clearValidators();
        form.setValidators([]);
        form.updateValueAndValidity({onlySelf: true});
      }
    }
  }

  onSubmit() {
    const availableHours = {
      type: this.formGroup.get('type').value,
      days: this.formGroup.get('days').value,
      hours: this.formGroup.get('hours').value
    }

    const availableForm = availableHours.days.every(day=> day.isAvailable === false);
    if(availableForm && availableHours.type === 'CUSTOM'){
      this.atleastOneAvailable = true;
      this.toastr.error(`Select atleast one day for your availability`, `Error`, { timeOut: 1500 });
    }else{
      this.atleastOneAvailable = false;
    }
    if (this.formGroup.get('type').value === 'CUSTOM') {
      if (availableHours.hours) {
        delete availableHours.hours;
      }
    } else {
      if (availableHours.days) {
        delete availableHours.days;
      }
    }
    const payload = {
      availableHours: availableHours,
      id: this.params?.item?.menuId,
    }
    Utils.logFormErrors(this.formGroup);
    if (this.formGroup.valid && !this.atleastOneAvailable) {
      this.loading.form = true;
      const url = API_URL._POST_OUTLET_MENU_TIME;
      this.httpRequestService.postData(url, payload).subscribe(
        (resp: any) => {
          this.toastr.success('Category Updated successfully');
        this.helperService.refreshApi(API_URL._GET_OUTLET_MENUS);
          this.bsModalService.hide();
          this.loading.form = false;
        }, (error) => {
          this.loading.form = false;
        }
      )
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  close(){
    this.bsModalService.hide();
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CurrentLocationPickerButtonModule, GooglePlaceSearchModule, NumberOnlyModule, SelectPickerModule, FileUploaderModule, BreadcrumbModule } from './directives';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AgmCoreModule } from '@agm/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { SharedModule } from './shared/shared.module';
import { JwtInterceptor } from './core';
import { BrandDataReducer, CartDataReducer, OutletDataReducer, LocationDetailReducer, ShopDataReducer, WhatsAppStepDataReducer, InstaStepDataReducer, StepDataReducer, AreaDataReducer, AppliedFiltersReducers, SettingsDataReducer } from './redux/reducers';
import {
  OutletCreatedSuccessfullyPopupComponent
} from './modals';
import { FooterComponent } from './common';
import { AuthenticationModule } from './authentication/authentication.module';
import { ContactSalesModule } from './public/contact-sales/contact-sales.module';
import { ContactThankYourModule } from './public/contact-thankyou/contact-thankyou.module';
import { DiscountPopupComponent } from './modals/discount-popup/discount-popup.component';
import { SalesChannelPopupComponent } from './modals/sales-channel-popup/sales-channel-popup.component';
// import { ViewTransactionPopupComponent } from './modals/view-transaction-popup/view-transaction-popup.component';
import { AddMoneyWalletPopupComponent } from './modals/add-money-wallet-popup/add-money-wallet-popup.component';
import { DeletePopupComponent } from './modals/delete-popup/delete-popup.component';
import { AcceptScheduledOrderPopupModule } from './modals/accept-scheduled-order-popup/accept-scheduled-order-popup.module';
import { DeclineScheduledOrderPopupComponent } from './modals/decline-scheduled-order-popup/decline-scheduled-order-popup.component';
import { MarkStatusReadyPopupComponent } from './modals/mark-status-ready-popup/mark-status-ready-popup.component';
import { CopyPopupComponent } from './modals/copy-popup/copy-popup.component';
import { MerchantInvoicePopupComponent } from './modals/merchant-invoice-popup/merchant-invoice-popup.component';
import { CreateCuisinePopupComponent } from './modals/create-cuisine-popup/create-cuisine-popup.component';
import { ManageRefundPopupComponent } from './modals/manage-refund-popup/manage-refund-popup.component';
import { ViewTransactionPopupModule } from './modals/view-transaction-popup/view-transaction-popup.module';
import { AssignItemPopupModule } from './modals/assign-item-popup/assign-item-popup.module';
import { EditMenuCategoryPopupComponent } from './modals/edit-menu-category-popup/edit-menu-category-popup.component';
import { EditMenuItemPopupComponent } from './modals/edit-menu-item-popup/edit-menu-item-popup.component';
import { CreateChargePopupModule } from './modals/create-charge-popup/create-charge-popup.module';
import { CreateCustomerGroupPopupModule } from './modals/create-customer-group-popup/create-customer-group.module';
import { SearchAreaPopupModule } from './modals/search-area-popup/search-area-popup.module';
import { CreateNewTaxesPopupModule } from './modals/create-new-taxes-popup/create-new-taxes-popup.module';
import { CustomerDetailPopupModule } from './modals/customer-detail-popup/customer-detail-popup.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordPopupModule } from './modals/change-password-popup/change-password-popup.module';
import { NewScheduledOrderPopupModule } from './modals/new-scheduled-order-popup/new-scheduled-order-popup.module';
import { OrderHistoryPopupModule } from './modals/order-history-popup/order-history.popup.module';
import { OnlineOfflinePopupComponent } from './modals/online-offline-popup/online-offline-popup.component';
import { CashPopupModule } from './modals/cash-popup/cash-popup.module';
import { SearchSingleProductPopupModule } from './modals/search-single-product-popup/search-single-product-popup.module';
import { MultiFileUploaderModule } from './directives/multi-file-uploader/multi-file-uploader.module';
import { AssignRiderPopupModule } from './modals/assign-rider-popup/assign-rider-popup.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoyaltyHistoryPopupModule } from './modals/loyality-history-popup/loyality-history-popup.module';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const stateKeys = ['_appliedFilters', '_outlet', '_whatsAppStep', '_instaStep', '_brand', '_step', '_settings'];
  return localStorageSync({ keys: stateKeys, rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    OutletCreatedSuccessfullyPopupComponent,
    DiscountPopupComponent,
    SalesChannelPopupComponent,
    // ViewTransactionPopupComponent,
    AddMoneyWalletPopupComponent,
    DeletePopupComponent,
    DeclineScheduledOrderPopupComponent,
    MarkStatusReadyPopupComponent,
    CopyPopupComponent,
    MerchantInvoicePopupComponent,
    CreateCuisinePopupComponent,
    ManageRefundPopupComponent,
    EditMenuCategoryPopupComponent,
    EditMenuItemPopupComponent,
    OnlineOfflinePopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SelectPickerModule,
    FileUploaderModule,
    MultiFileUploaderModule,
    BreadcrumbModule,
    HttpClientModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true,
      progressBar: true,
      timeOut: 2000
    }),
    NgxSkeletonLoaderModule,
    NumberOnlyModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3x8w1JU8eFUvupNJn4Q26E7aotCjytxU',
      libraries: ['places']
    }),
    StoreModule.forRoot({}, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: true
      }
    }),
    StoreDevtoolsModule.instrument(),
    StoreModule.forFeature('_settings', SettingsDataReducer),
    StoreModule.forFeature('_brand', BrandDataReducer),
    StoreModule.forFeature('_outlet', OutletDataReducer),
    StoreModule.forFeature('_appliedFilters', AppliedFiltersReducers),
    StoreModule.forFeature('_instaStep', InstaStepDataReducer),
    StoreModule.forFeature('_whatsAppStep', WhatsAppStepDataReducer),
    StoreModule.forFeature('_step', StepDataReducer),
    CurrentLocationPickerButtonModule,
    GooglePlaceSearchModule,
    TrimValueAccessorModule,
    SharedModule,
    AuthenticationModule,
    ContactThankYourModule,
    ContactSalesModule,
    ViewTransactionPopupModule,
    AssignItemPopupModule,
    CreateChargePopupModule,
    CreateCustomerGroupPopupModule,
    SearchAreaPopupModule,
    CreateNewTaxesPopupModule,
    CustomerDetailPopupModule,
    NgbTooltipModule,
    ChangePasswordPopupModule,
    LoyaltyHistoryPopupModule,
    AcceptScheduledOrderPopupModule,
    NewScheduledOrderPopupModule,
    OrderHistoryPopupModule,
    CashPopupModule,
    SearchSingleProductPopupModule,
    AssignRiderPopupModule,
    InfiniteScrollModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
  ],
  entryComponents: [
    OutletCreatedSuccessfullyPopupComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

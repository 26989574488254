import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalService} from 'ngx-bootstrap/modal';
import {API_URL, HttpRequestService, Utils} from 'src/app/core';
import {ToastrService} from 'ngx-toastr';
import {SearchService} from '../../shared';
import { HelperService } from 'src/app/services';
import { FormValidator } from 'src/validators';

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change-password-popup.component.html',
  styleUrls: ['./change-password-popup.component.scss']
})
export class ChangePasswordPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private helperService: HelperService
  ) {
  }

  formGroup: FormGroup = this.formBuilder.group({
    oldPassword: new FormControl('', [Validators.compose([Validators.required])]),
    password: new FormControl('', [Validators.compose([Validators.required,Validators.minLength(8),Validators.maxLength(100),FormValidator.validPassword])]),
  });

  showNewPassword: boolean = false;
  show: boolean = false;
  loading = {api: false, form: false};
  params;
  walletData: any[] = [];

  ngOnInit(): void {
  }

  updatePassword() {
    if (this.formGroup.valid) {
      this.loading.form = true;
      const url = API_URL._POST_CHANGE_PASSWORD;
      const payload = this.formGroup.value;
      this.httpRequestService.postData(url, payload).subscribe(
      (resp) => {
        this.loading.form = false;
        this.toastr.success('Password updated successfully');
        this.popupService.hide();
      }, (error) => {
        this.loading.form = false;
        this.toastr.error(error?.error?.message);
      });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  showHidePassword() {
    this.show = !this.show;
  }

  showHideNewPassword(){
    this.showNewPassword = !this.showNewPassword;
  }

  close() {
    this.popupService.hide();
  }


}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from '../core';

@Injectable()
export class EventsResolver implements Resolve<Observable<any>> {
  services: any[] = [];
  constructor(
    private router: Router,
    private store: Store<any>,
    private toastr: ToastrService,
    private httpRequestService: HttpRequestService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const loginUser = JSON.parse(localStorage.getItem('userDetails'));
    const appid = loginUser.appId;
    const url = API_URL._GET_ALL_SERVICES;
    const params = { appid };
    return this.httpRequestService.getData(url,params).pipe(
      map((resp: any) => {
        if(resp.data.services) {
          this.services  = resp.data;
          // const eventCache = {eventId: eventData.id, data: eventData, updated: moment().unix()};
        }
        else {
          // this.router.navigate([`app/brand`]);

          // this.toastr.error(resp.message, `Failed`, {timeOut: 5000});
        }
      })
    );
  }
}


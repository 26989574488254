import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingTableComponent } from './listing-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../loader/loader.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TruncatePipeModule} from '../../pipes';

@NgModule({
  declarations: [
    ListingTableComponent,
  ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgbModule,
        LoaderModule,
        NgxPaginationModule,
        DragDropModule,
        TruncatePipeModule
    ],
  exports: [
    ListingTableComponent,
  ]
})
export class ListingTableModule { }

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HttpRequestService, Utils } from 'src/app/core';
import { SearchService } from 'src/app/shared/search.service';

@Component({
  selector: 'app-search-area-popup',
  templateUrl: './search-area-popup.component.html',
  styleUrls: ['./search-area-popup.component.scss']
})
export class SearchAreaPopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private toastr: ToastrService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    items: new FormControl([], []),
    search: new FormControl('', [])
  });

  @ViewChild('searchBox', {static: true}) private searchBox: ElementRef;
  term = '';
  selectedOutlets: any[] = [];
  selectedArea: any[] = [];
  areaIndex;
  resData: any[] = [];
  params;
  lists: any[] = [];
  items: any[] = [];
  label: any;
  selectionText: any;
  type: number;
  index: number;
  maxElementCheckbox: any;

  ngOnInit(): void {
    if(this.params.inputValue){
      this.formGroup.get('search').patchValue(this.params.inputValue);
    }
    this.maxElementCheckbox = this.params.maxElementCheckbox;
    this.label = this.params.label;
    this.selectionText = this.params.selectionText;
    this.type = this.params.type;
    if(this.params.id){
      for(let i=0;i<this.params.list.length;i++){
      this.lists = this.params.list[i].filter(list=> list.outlet == this.params.id);
      }
    }else{
    this.lists = this.params.list;
    }
    this.index = this.params.index;
    
    if(this.params.items){
      // this.items = this.params.items;
      this.selectedOutlets = this.params.items;
      this.formGroup.patchValue(this.items);
    }

    setTimeout(() => {
      this.searchBox.nativeElement.focus();
    }, 10)
  }

  onFocus(data) {
  }

  selectUnSelectItem(check, list) {
    // this.selectedOutlets = this.formGroup.get('items').value;
    if (Array.isArray(this.selectedOutlets)) {
      const foundIndex = this.isItemSelected(list._id);
      if (check) {
        if (foundIndex == -1) {
          this.selectedOutlets.push({_id: list._id, name: list.name});
          if(this.maxElementCheckbox && this.selectedOutlets.length > this.maxElementCheckbox){
            this.toastr.error('Do not select more than 5 groups');
          }
        }
      } else {
        if (foundIndex > -1) {
          this.selectedOutlets.splice(foundIndex, 1);
        }
      }
    } else {
      this.selectedOutlets = [{_id: list._id, name: list.name}];
    }
  }

  isItemSelected(itemId): number {
    // this.selectedOutlets = this.formGroup.get('items').value;
    const foundIndex = this.selectedOutlets.findIndex(item => item._id === itemId);
    return foundIndex;
  }


  save() {
    //type 1 select outlet areas
    //type 2 applies to specific category
    //type 3 applies to specific product and customer buys and spends (search product)
    //type 4 customer eligibility Specific group of customers
    //type 5 customer eligibility specific cutomers

    let selectedOutlets = Utils.cloneObj(this.selectedOutlets);

    if (this.type === 1) {
      this.searchService.onSearchAreaOutlet({ value: selectedOutlets, index: this.index });
    } else if (this.type === 2) {
      this.searchService.onCategory(selectedOutlets);
    } else if (this.type === 3) {
      this.searchService.onProduct(selectedOutlets);
    } else if (this.type === 4) {
      this.searchService.onGroupCustomer(selectedOutlets);
    } else if (this.type === 5) {
      this.searchService.onCustomer(selectedOutlets);
    } else if (this.type === 6) {
      this.searchService.onBuyCustomerProducts(selectedOutlets);
    } else if (this.type === 7) {
      this.searchService.onGetCustomerProducts(selectedOutlets);
    }else if (this.type === 8) {
      this.searchService.onCards(selectedOutlets);
    }
    this.close();
  }

  close() {
    this.popupService.hide();
  }
}

import {Action} from '@ngrx/store';

export const SETTINGS_DATA_CONSTANTS = {
  SAVE_SETTINGS_DATA: '[Settings Detail] Save',
  RESET_SETTINGS_DETAIL: '[Settings Detail] Reset',
};


class SaveSettingsData implements Action {
  readonly type = SETTINGS_DATA_CONSTANTS.SAVE_SETTINGS_DATA;
  constructor(public payload: any) {
  }
}

class ResetSettingsData implements Action {
  readonly type = SETTINGS_DATA_CONSTANTS.RESET_SETTINGS_DETAIL;
}

export const SettingsDataActions = {
  saveDetail: SaveSettingsData,
  restDetails: ResetSettingsData
};




export const SettingsDataSelectors = {
  detail: (state: any) => state._settings,
};

export type SettingsDataActionTypes = SaveSettingsData;

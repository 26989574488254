<div class="fileUpload" *ngIf="selectDesign == 'logo'">
    <p>When you finish filling out the file,
        <br>Drag & Drop your file or
        <button class="blank-btn">Browse</button>
    </p>

    <input class="file-upload-input hideFeild" type="file" [attr.accept]="accept" [accept]="fileTypes" ngFileSelect
        [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" (click)="replaceFile()"
        multiple>
</div>

<!-- <div class="forkIcon" *ngIf="position == 'top' && isImage">
    <img class="img-fluid" src="{{imageUrl}}" />
  </div>
  
  <div class="forkIcon" *ngIf="!isImage">
    <img class="img-fluid" src="assets/images/spoonFork.svg" />
  </div> -->
<!-- <div class="fullWIdth-photo-uploader">
    <div class="photo_uploader_wrapper list-style">
      <div class="photo_uploader_box" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
        <figure class="upload-icon">
          <img src="assets/images/image-icon.svg" alt="" />
        </figure>
        <ng-container [ngSwitch]="uploadType">
          <ng-container *ngSwitchCase="'image'">
            <div class="upload-content">
              <h6><strong>Upload photo</strong> or just drag and drop</h6>
              <p>Add at least 1 photo (Up to 2MB, Resolution 800x600)</p>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'csv'">
            <h6><strong>Upload CSV file</strong> or just drag and drop</h6>
          </ng-container>
        </ng-container>
     
        <input class="file-upload-input" 
        type="file"
        [attr.accept]="accept"
        [accept]="fileTypes" 
        ngFileSelect [options]="options" 
        (uploadOutput)="onUploadOutput($event)" 
        [uploadInput]="uploadInput"
        multiple>
      </div>
    </div>
  </div> -->

<div class="upload-item" *ngFor="let f of files; let i = index;">
    <div class="upload-item-content">
        <div class="filename">
            <div class="filename-left">
                icon copy
                <!-- <i class="ionicon ion-ios-copy"></i> -->
                <span>{{ f.name }}</span>
            </div>
            <div class="filename-right">
                <i class="ionicon ion-close-round" (click)="cancelUpload(f.id)"></i>
            </div>
        </div>
        <div class="progress-content">
            <div class="progress">
                <span class="progress-bar" [style.width]="f?.progress?.data?.percentage + '%'"
                    [class.is-done]="f?.progress?.data?.percentage === 100"></span>
            </div>
        </div>
        <div class="progress-text-content">
            <span class="progress-text" [class.is-done]="f?.progress?.data?.percentage === 100">
                <span>{{ f.progress?.data?.percentage }}% </span>
                <span *ngIf="f.progress?.data?.percentage !== 100">Uploading...</span>
                <span *ngIf="f.progress?.data?.percentage === 100">Done</span>
            </span>
            <span class="speed-and-eta-text"
                *ngIf="f.progress?.data?.percentage !== 0 && f.progress?.data?.percentage !== 100">
                <span>{{ f.progress?.data?.speedHuman }} </span>
                <span>ETA {{ f.progress?.data?.etaHuman }}</span>
            </span>
        </div>
    </div>
</div>

<div class="alert alert-info" *ngIf="uploaded && uploaded.length">
    Files uploaded: ({{uploaded.length}})
  </div>



<!-- <div class="coverPics mt-4" *ngIf="position == 'bottom' && preview && uploaded && uploaded.length">
    <div class="form-row">
        <div class="col-lg-3 col-md-6" [ngClass]="{'featured': singleFile.isFeatured}"
            *ngFor="let singleFile of uploaded;  let idx = index">
            <div class="coverPic">
                <img class="img-fluid" width="50px" height="50px" src="{{singleFile.location}}" />
                <a href="javascript: //" (click)="deleteFile(idx, singleFile._id)" class="closeIcon"></a>
                <a href="javascript:void(0);" class="ic img-featured" (click)="setFeatured(singleFile)"
                    *ngIf="!singleFile.isFeatured && canFeature"></a>
            </div>
        </div>

    </div>
</div> -->
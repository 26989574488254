import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { API_URL, HttpRequestService } from '@app/core';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-rider-popup',
  templateUrl: './assign-rider-popup.component.html',
  styleUrls: ['./assign-rider-popup.component.scss']
})
export class AssignRiderPopupComponent implements OnInit {
  params;
  allRiders: any[] = [];
  isQuiqUp: boolean = false;
  quiqUpData;
  loading = { api: false, form: false };
  riderType: any[]= [
    {
      id: 'self',
      name: 'Self'
    },
    {
      id: 'quiqup',
      name: 'Quiqup'
    }
  ];

  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 1.5;

  limit = 10;
  skip = 0;
  itemsPerPage = 10;
  itemsToLoad: number = 10;
  totalItems: number = 0;
  isFullListDisplayed: boolean = false;

  constructor(
    private popupService: BsModalService,
    private httpRequestService: HttpRequestService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
  }

  formGroup: FormGroup = this.formBuilder.group({
    type: new FormControl('self', [Validators.compose([Validators.required])]),
  });

  ngOnInit(): void {
    this.loading.api = true;
    if (this.params.brandId && this.params.outletId) {
      this.getAllRiders();
    }
  }

  getAllRiders() {
    if(this.isQuiqUp){
      this.allRiders = [];
    }
    // this.loading.api = true;
    const url = API_URL._GET_RIDERS;
    this.httpRequestService.getData(url, { brand: this.params?.brandId,outlet: this.params?.outletId, isAvailable: true, limit: this.limit, skip: this.skip,showCount: true, type: this.formGroup.get('type').value }).subscribe((resp: any) => {
      resp.data.riders.forEach(rider => {
        if (this.isRiderExistInArray(rider._id)) {
          this.allRiders.push(rider);
        }
      });
      this.totalItems = resp.data.total;
      this.loading.api = false;
    }, (error) => {
      this.loading.api = false;
    }
    );
  }

  isRiderExistInArray(riderId): boolean {
    const foundIndex = this.allRiders.findIndex(rider => rider._id === riderId);
    return foundIndex < 0;
  }

  assignRider(id) {
    this.loading.form = true;
    const url = API_URL._POST_ASSIGN_RIDER;
    const payload = {
      orderId: this.params?.id,
      riderId: id,
      brand: this.params?.brandId,
      outlet: this.params.outletId
    }
    this.httpRequestService.postData(url, payload).subscribe((resp: any) => {
      this.loading.form = false;
      this.allRiders = this.allRiders.filter(_manager => _manager._id !== resp.data?._id);
      this.toastrService.success('Rider assigned successfully');
      this.helperService.refreshApi({status: 'completed'});
      this.popupService.hide();
    }, (error) => {
      this.loading.form = false;
    }
    );
  }

  getQuiqupRetrieveJobs(){
    this.loading.api = true;
    this.skip = 0;
    this.allRiders = [];
    const url = API_URL._GET_QUIQUP_JOBS;
    const payload = {
      orderId: this.params?.orderId,
    }
    this.httpRequestService.getData(url, payload).subscribe((resp: any) => {
      this.loading.api = false;
      if(typeof resp.data.message !== null && typeof resp.data.message !== undefined){
        this.quiqUpData = resp.data;
      }
    }, (error) => {
      this.loading.api = false;
    }
    );
  }

  createQuiqupRider() {
    this.loading.api = true;
    const url = API_URL._POST_QUIQUP_JOBS;
    const payload = {
      orderId: this.params?.orderId,
    }
    this.httpRequestService.postData(url, payload).subscribe((resp: any) => {
      this.loading.api = false;
      if(resp.statusCode === 200){
        this.toastrService.success('Rider created successfully');
        this.getQuiqupRetrieveJobs();
      }
    }, (error) => {
      this.loading.api = false;
    }
    );
  }

  onScrollDown(evt) {
    if(this.totalItems){  
      if (this.skip <= this.totalItems) {
        this.skip += this.itemsToLoad;  
        this.limit = 10;
        this.getAllRiders();
      }else{
        this.isFullListDisplayed = true;
      }
    }
  }

  riderChange(value){
    this.loading.api = true;
    if(value === 'Self'){
      this.isQuiqUp = false;
      this.getAllRiders();
    }else{
      this.isQuiqUp = true;
      this.getQuiqupRetrieveJobs();
    }
  }

  close() {
    this.popupService.hide();
  }
}

<div class="modalDetails">
    <div class="modal-header">
        <h5 class="modal-title">{{ params?.name }}</h5>
        <button type="button" class="close" (click)="close()">&times;</button>
    </div>

    <div class="modal-body">
        <form class="modalDetails-in">
            <div>
                <p class="sm">Name:</p>
                <p class="text">{{ params?.name}}</p>
            </div>
            <div>
                <p class="sm">Contact Number:</p>
                <p class="text">({{ params?.phoneCode }}) {{ params?.phone }}</p>
            </div>
            <div>
                <p class="sm">Total Orders:</p>
                <p class="text">{{ params?.totalOrders }}</p>
            </div>
            <div>
                <p class="sm">Balance:</p>
                <p class="text">{{ params?.currency }} {{ params?.balance }}
                </p>
            </div>
            <div *ngIf="params?.points">
                <p class="sm">Total Points:</p>
                <p class="text">{{ params?.points }}
                </p>
            </div>
        </form>
    </div>
</div>
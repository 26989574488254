import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { API_URL, HttpRequestService } from 'src/app/core';
import { FormValidator } from 'src/validators';

@Component({
  selector: 'app-manage-refund-popup',
  templateUrl: './manage-refund-popup.component.html',
  styleUrls: ['./manage-refund-popup.component.scss']
})
export class ManageRefundPopupComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private toastrService: ToastrService,
    private popupService: BsModalService,
    private helperService: HelperService)
   { }

  params;
    
  formGroup: FormGroup = this.formBuilder.group({
    amount:      new FormControl('', [Validators.compose([Validators.required])]),
    type: new FormControl('',Validators.compose([Validators.required])),
    reason: new FormControl('',[Validators.compose([Validators.required])]),
    notes: new FormControl('',[])
  },{validator: this.amountValidator.bind(this)});

  amountValidator(control: AbstractControl) {
    const amount = +control.get('amount').value; // to get value in input tag
      if (amount > this.params?.data?.toPay) {
        control.get('amount').setErrors({invalidAmount: true});
      } else {
        control.get('amount').setErrors(null);
      }
  }

  loading= {api: false, form: false};
  allRefundReason: any[] = [];
  currencycode;
  allTransferTypes: any[] = [
    {id: 'instant', name: 'Instant'},
    {id: 'scheduled', name: 'Scheduled'},
  ];

  ngOnInit(): void {
    const allSettings = JSON.parse(localStorage.getItem('_settings'));
    this.allRefundReason = allSettings.refundReasons;
    
    if(this.params.brandId){
      const brand = JSON.parse(localStorage.getItem('_brand'));
      if(brand && brand.length && this.params.brandId){
        const foundData = brand.find(brand => brand?._id === this.params.brandId);
        if(foundData){
          this.currencycode = foundData?.currency?.name;
        }
        }
    }
  }

  onlyNumbers(event){
    FormValidator.onlyNumbersWithDecimal(event);
  }

  submit(){
    if(this.formGroup.valid){
      this.loading.form = true;
      const url = API_URL._POST_ORDER_REFUND;
      const payload = this.formGroup.value;
      payload.amount = +payload.amount;
      payload.id = this.params.data._id;
      this.httpRequestService.postData(url,payload).subscribe(
        (resp: any)=>{
          this.loading.form = false;
          this.helperService.refreshApi(API_URL._GET_ALL_ORDERS);
          this.toastrService.success('Refund updated successfully');
          this.popupService.hide();
        },(error)=>{
          this.loading.form = false;

        });
    }else{
      this.formGroup.markAllAsTouched();
    }
  }

  close(){
    this.popupService.hide();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesServiceService {
  baseUrl = environment.API_END_POINT;

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

  upload(file: FileList,brand): Observable<HttpEvent<any>> {
    const requestParams = {brand: brand};
    const formData: FormData = new FormData();
    for(let i=0;i<file.length;i++){
      formData.append('file', file[i]);
    }
    const req = new HttpRequest('POST', `${this.baseUrl}vendor/outlet/uploadFile?brand=${brand}`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }
}

<div>
  <div class="modal-header">
    <h5>Copy to</h5>
  </div>

  <div class="modal-body">
    <div class="copyModalIn">
      <form [formGroup]="formGroup">

        <div class="form-in" *ngFor="let service of services; let sIndex=index">
          <div class="checkBox f14">
            <input type="checkbox" (change)="isCheckedAllItems($event.target.checked,service)" [id]="'service' + sIndex">
            <label for="{{'service'+sIndex}}">{{ service.name }}</label>
          </div>
        </div>
        <div class="mb-3" *ngIf="selectedServices.length === 0 && formGroup.controls['services'].touched">
          <span class="error">Select at least one service.</span>
        </div>

        <div class="form-in form-btn">
          <button class="btn-white next" (click)="cancel()">Cancel</button>
          <button class="btn next ml-3" [disabled]="loading.form" (click)="submit()" [ngClass]="{'loader':loading.form}">Copy</button>
        </div>
      </form>
    </div>
  </div>
</div>
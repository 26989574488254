import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  private logger: any;

  constructor(
    private http: HttpClient
  ) {
  }

  apiEndPoint = environment.API_END_POINT;

  /*
   * Handle rest get requests
   * log action into console
  */
  

  getData(url, filterParams): Observable<any> {
    const endPoint = this.apiEndPoint + url;
    return this.http.get(endPoint,{params: filterParams});
  }

  /*
   * Handle rest post requests
   * log action into console
  */
  postData(url, payload): Observable<any> {   
    const endPoint = this.apiEndPoint + url;
    return this.http.post(endPoint, payload)
  }

  /*
   * Handle rest put requests
   * log action into console
  */
  putData(url, payload): Observable<any> {
    const endPoint = this.apiEndPoint + url;
    return this.http.put(endPoint, payload);
  }


  /*
   * Handle rest delete requests
   * log action into console
  */
  deleteData(url, payload): Observable<any> {
    const endPoint = this.apiEndPoint + url;
    return this.http.delete(endPoint, payload);
  }

  /*
   * Handle rest get requests
   * log action into console
  */
  getDataWithoutHeader(url, filterParams): Observable<any> {
    const endPoint = 'https://maps.googleapis.com/maps/api/geocode/json';
    const requestHeaders: HttpHeaders = new HttpHeaders().append('skipHeaders', 'true');
    return this.http.get(endPoint, {
      params: filterParams,
      headers: requestHeaders
    });
  }


  /*
   * Handle error function
   * log action into console
  */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      return throwError(`${operation} failed: ${error}`);
    };
  }

  /*
   * Log a HeroService message with the MessageService
  */
  private log(message: string): void {
    this.logger.info(' Plan Subscription Service: ' + message);
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignRiderPopupComponent} from './assign-rider-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule, SelectPickerModule } from '@app/directives';
import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const routes: Routes = [
    { path: '', component: AssignRiderPopupComponent, 
}];

@NgModule({
  declarations: [
    AssignRiderPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    SelectPickerModule,
    NgxPaginationModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule
  ],
  exports: [
    AssignRiderPopupComponent
  ],
})
export class AssignRiderPopupModule {
}

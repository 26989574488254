import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../modals/modal.service';
import { AuthService } from '../../services';
import { Router } from '@angular/router';
import { HttpRequestService, LoggerService } from '../../core';
import { select, Store } from '@ngrx/store';
import { Subscriber } from 'rxjs';
import { logger } from 'codelyzer/util/logger';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  constructor(
    private popupService: ModalService,
    private authService: AuthService,
    private router: Router,
    private loggerService: LoggerService,
    private httpService: HttpRequestService,
    private store: Store,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  private subscriber: Subscriber<any> = new Subscriber<any>();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
    this.changeDetector.detach();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewTransactionPopupComponent} from './view-transaction-popup.component';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@app/directives';

const routes: Routes = [
    { path: '', component: ViewTransactionPopupComponent, 
}];

@NgModule({
  declarations: [
    ViewTransactionPopupComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    ViewTransactionPopupComponent
  ],
//   entryComponents: [
//     ViewTransactionPopupComponent
//   ]
})
export class ViewTransactionPopupModule {
}

<div id="orderModal">
  <app-loader *ngIf="loading.api"></app-loader>

  <form>
    <div class="modal-header">
      <h5 class="modal-title">Order Details <span *ngIf="params?.data?.isPosOrder">(POS Order)</span></h5>
    </div>
    <div class="modal-body">
      <div class="orderBody">
        <div class="OrderNumberBody">
          <h5>{{this.params?.data?.orderId}} <span class="OrderTime">
              {{params?.data?.createdAt | date: 'fullDate'}} {{params?.data?.createdAt | date:' HH:mm a' : 'UTC'}}</span></h5>
          <div>
            <label class="orderLabel lg"
            [ngClass]="{'scheduled': params?.data?.isScheduled, 'placed': params?.data?.status === 'PLACED', 'pending': params?.data?.status === 'pending', 'preparing': params?.data?.status === 'PREPARING',
            'packed': params?.data?.status === 'PACKED', 'transit': params?.data?.status === 'TRANSIT', 'picked': params?.data?.status === 'PICKED', 'delivered': params?.data?.status === 'DELIVERED',
            'cancelled': params?.data?.status === 'cancelled', 'confirmed': params?.data?.status === 'CONFIRMED' }">
              {{params?.data?.status?.toUpperCase()}}</label>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type.toUpperCase() == 'DELIVERY'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Delivery Address</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Customer contact</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>

          <div class="custContact" *ngIf="params?.data?.isScheduled">
            <p class="sm mb-0">Scheduled</p>
            <p class="text mb-0">{{params?.data?.estimateDelivery | date: 'fullDate'}} {{params?.data?.estimateDelivery
              | date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.name">
            <p class="sm mb-0">Assigned Rider</p>
            <p class="text mb-0">{{ params?.data?.rider?.name }}</p>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.phone">
            <p class="sm mb-0">Assigned Rider Number</p>
            <p class="text mb-0">({{params?.data?.rider?.phoneCode}}) {{ params?.data?.rider?.phone }}</p>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type.toUpperCase() == 'DINEIN'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">No. of guests</p>
            <p class="text mb-0">{{ params?.data?.dineIn?.guests }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Table Number</p>
            <p class="text mb-0">{{ params?.data?.dineIn?.tableNumber }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type.toUpperCase() == 'RESERVATION'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.reservation?.fullName }}</p>
          </div>

           <div class="custContact">
            <p class="sm mb-0">Email Id</p>
            <p class="text mb-0">{{params?.data?.reservation?.email}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.reservation?.phoneCode}}) {{ params?.data?.reservation?.phoneNumber }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">No. of guests</p>
            <p class="text mb-0">{{ params?.data?.reservation?.guests }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Reservation Date</p>
            <p class="text mb-0"> {{params?.data?.reservation.selectedTime | date: 'fullDate'}} {{params?.data?.reservation.selectedTime | date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Reservation Till Date</p>
            <p class="text mb-0"> {{params?.data?.reservation?.reservedTill | date: 'fullDate'}} {{params?.data?.reservedTill?.selectedTime | date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>

          <!-- <div class="deliveryAddress">
            <p class="sm mb-0">Date</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div> -->

        </div>

        <div class="orderAddress" *ngIf="params?.data?.type.toUpperCase() == 'BULKORDER'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <div class="deliveryAddress">
            <p class="sm mb-0">Address</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Type</p>
            <p class="text mb-0">{{params?.data?.bulkType | titlecase}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Delivery Time</p>
            <p class="text mb-0">{{params?.data?.deliveryTime | date: 'fullDate'}} {{params?.data?.deliveryTime |  date:' HH:mm a' : 'UTC'}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.name">
            <p class="sm mb-0">Assigned Rider</p>
            <p class="text mb-0">{{ params?.data?.rider?.name }}</p>
          </div>

          <div class="custContact" *ngIf="params?.data?.rider?.phone">
            <p class="sm mb-0">Assigned Rider Number</p>
            <p class="text mb-0">({{params?.data?.rider?.phoneCode}}) {{ params?.data?.rider?.phone }}</p>
          </div>

        </div>

        <div class="orderAddress" *ngIf="params?.data?.type.toUpperCase() == 'PICKUP'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <!-- <div class="deliveryAddress">
            <p class="sm mb-0">Address</p>
            <p class="text mb-0">{{ params?.data?.deliveryAddress?.location?.name }}</p>
          </div> -->

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
        </div>

        <div class="orderAddress" *ngIf="params?.data?.type.toUpperCase() == 'CURBSIDE'">
          <div class="deliveryAddress">
            <p class="sm mb-0">Name</p>
            <p class="text mb-0">{{ params?.data?.user?.name }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Contact Number</p>
            <p class="text mb-0">({{params?.data?.user?.phoneCode}}) {{ params?.data?.user?.phone }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Parking number</p>
            <p class="text mb-0">{{params?.data?.curbside?.parkingNumber}}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Vehicle number</p>
            <p class="text mb-0">{{ params?.data?.curbside?.vehicleNumber }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Vehicle color</p>
            <p class="text mb-0">{{ params?.data?.curbside?.vehicleColor }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Vehicle model</p>
            <p class="text mb-0">{{ params?.data?.curbside?.vehicleModel }}</p>
          </div>

          <div class="custContact">
            <p class="sm mb-0">Amount/payment</p>
            <div class="orderQuantity"><span>{{params?.data?.items && params?.data?.items.length ? params?.data?.items.length : 0}} Items</span> {{currencycode}}
              {{params?.data?.total | number : '1.2-2'}}
              <div class="unpaidCash"><b [ngClass]="{'discount': params?.data?.payStatus === 'paid'}">{{params?.data?.payStatus}}</b>
                ({{ params?.data?.payMethod == 'cod' ? 'COD' : params?.data?.payMethod == 'card' ? 'CARD' :
                params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : '---'
                }})</div>
            </div>
          </div>
        </div>

        <h5 *ngIf="params?.data?.items && params?.data?.items.length">Order Details</h5>

        <div class="orderSum borderDetail" *ngFor="let item of params?.data?.items">
          <div class="orderSumIn">
            <div class="itemName" [ngClass]="{'Veg': item.isVeg, 'nonVeg': !item.isVeg}">
              <p>{{item?.name}} <span>{{item?.description}}</span></p>
            </div>

            <div class="itemQuatity">
              <p>x{{ item?.quantity }}</p>
            </div>

            <div class="itemPrice">
              <p>{{currencycode}} {{item?.basePrice | number : '1.2-2'}}</p>
            </div>
          </div>

          <div class="orderExtras" *ngFor="let modifier of item.modifierGroups">
            <span class="extraH">{{modifier?.name}}</span>
            <p *ngFor="let mod of modifier?.modifiers">
              <span class="modifiers-title">{{mod?.name}}</span>
              <span class="extraH">x{{mod.quantity}}</span>
              <span>{{currencycode}} {{mod?.price | number : '1.2-2'}}</span></p>
          </div>

          <!-- <div class="orderExtras" *ngFor="let modifier of item.modifierGroups">
            <span class="extraH">{{modifier?.name}}</span>
            <p *ngFor="let mod of modifier?.modifiers">{{mod?.name}}<span>x{{mod.quantity}}</span><span>{{currencycode}} {{mod?.price}}</span></p>
          </div> -->
        </div>

        <div class="instructionSec borderDetail">
          <p class="f14 text mb-1">Cooking instructions</p>
          <div class="instructionDiv"><span><img class="img-fluid" src="assets/images/quotes.svg" /></span>
            {{params?.data?.notes || 'No instructions' }}</div>
        </div>

        <div class="instructionSec borderDetail" *ngIf="params?.data?.type === 'DELIVERY'">
          <p class="f14 text mb-1">Delivery instructions</p>
          <div class="instructionDiv"><span><img class="img-fluid" src="assets/images/quotes.svg" /></span>
            {{params?.data?.deliveryNotes || 'No Instructions' }}</div>
        </div>

        <div class="instructionSec borderDetail" *ngIf="params?.data?.isCuterly">
          <p class="f14 text mb-1">Cutlery</p>
          <div class="instructionDiv"><span><img class="img-fluid" src="assets/images/quotes.svg" /></span>
            {{params?.data?.isCuterly }}</div>
        </div>

        <div class="subtotal borderDetail" *ngIf="params?.data?.items && params?.data?.items.length">
          <p class="text">Subtotal <span>{{currencycode}} {{params?.data?.subTotal | number : '1.2-2'}}</span></p>

          <div class="subtotalIn">
            <!-- <p>Packing charges <span>{{currencycode}} {{params?.data?.subTotal}}</span></p> -->
            <p *ngIf="params?.data?.type === 'DELIVERY'">Delivery charges <span>{{currencycode}} {{params?.data?.totalDelivery | number : '1.2-2'}}</span></p>
            <p class="discount" *ngIf="params?.data?.totalDiscount">Discount <span>- {{currencycode}} {{params?.data?.totalDiscount | number : '1.2-2'}}</span></p>
            <p>Extra Charges <span>{{currencycode}} {{params?.data?.totalCharges | number : '1.2-2'}}</span></p>
            <p>Tax <span>{{currencycode}} {{params?.data?.totalTaxes | number : '1.2-2'}}</span></p>
          </div>
        </div>

        <div class="totalDiv borderDetail" *ngIf="params?.data?.items && params?.data?.items.length">
          <div class="totalDivIn">
            <h4>Total <span class="info">(Inclusive of all taxes)</span></h4>
            <h4>{{currencycode}} {{params?.data?.total | number : '1.2-2'}}</h4>
          </div>
        </div>

        <div class="paymentType">
          <p class="text">Payment type <span class="text-green">({{ params?.data?.payMethod == 'cod' ? 'COD' :
              params?.data?.payMethod == 'card' ? 'CARD' :
              params?.data?.payMethod == 'online' ? 'ONLINE' : params?.data?.payMethod == 'wallet' ? 'WALLET' : params?.data?.payMethod == 'offline' ? 'OFFLINE' : '---'
              }})</span></p>
        </div>
      </div>
    </div>
  </form>
</div>
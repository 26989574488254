<!-- The Modal -->
<div>      <!-- Modal Header -->
  <div class="modal-header">
    <h5 class="modal-title">Add money to wallet</h5>
    <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <form class="fundsBody" [formGroup]="formGroup">
      <div class="form-in">
        <label>Reason</label>
        <input type="text" formControlName="reason" placeholder="Eg. Refund for order 1001"/>
        <div *ngIf="formGroup.get('reason').invalid && formGroup.get('reason').touched">
          <span class="error" *ngIf="formGroup.get('reason').hasError('required')">Reason is required</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-in">
            <label>Type</label>
            <div class="select-div">
              <select-picker formControlName="type" firstOption="Select Type" [options]="allTypes"></select-picker>
              <div *ngIf="formGroup.get('type').invalid && formGroup.get('type').touched">
                <span class="error" *ngIf="formGroup.get('type').hasError('required')">Type is required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-in">
              <label>Amount</label>
              <div class="currencyLabel">
              <input type="number" min="0.1" formControlName="amount" placeholder="Eg. 2"/>
              <span>{{currencycode}}</span>
              </div>
              <div *ngIf="formGroup.get('amount').invalid && formGroup.get('amount').touched">
                <span class="error" *ngIf="formGroup.get('amount').hasError('required')">Amount is required</span>
                <span class="error" *ngIf="formGroup.get('amount').errors?.minAmount">Amount must be greater than 0 </span>
                <span class="error" *ngIf="formGroup.get('amount').errors?.invalidAmount">
                  Debit amount should not be greater than wallet amount.
                </span>
              </div>
          </div>
      </div>

        <!-- <div class="col-md-6">
          <div class="form-in">
            <label>Amount</label>
            <input type="number" min="0.1" formControlName="amount" placeholder="Enter amount here"/>
          

          </div>
        </div> -->
      </div>
    </form>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer border-0">
    <button type="button" class="btn-white sm" (click)="close()">Cancel</button>
    <button type="button" [disabled]="loading.form" (click)="updateBalance()" [ngClass]="{'loader': loading.form}" class="btn sm">Update balance</button>
  </div>

</div>

<div class="manageFunds" id="Transactions">
    <div class="modal-header">
        <h5 class="modal-title">Riders</h5>
        <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
    </div>

    <app-loader *ngIf="loading.api"></app-loader>

    <div class="modal-body">
        <form [formGroup]="formGroup">
            <div class="rider-choose">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text mb-3">Choose your rider type:</p>
                    </div>

                    <div class="col-md-3" *ngFor="let rider of riderType">
                        <div class="form-in">
                            <div class="radioBtn">
                                <input name="type" type="radio" [value]="rider.id" (change)="riderChange(rider.name)"
                                    [id]="'rider'+indxItem" formControlName="type">
                                <label>{{ rider.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="transactionBody pt-0" *ngIf="allRiders?.length > 0 && !loading.api && !this.isQuiqUp">
            <div infiniteScroll [scrollWindow]="true" [infiniteScrollThrottle]="throttle"
                [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance"
                [fromRoot]="true" [infiniteScrollContainer]="'.transactionBody'" (scrolled)="onScrollDown($event)">
                <table>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No.</th>
                            <th>Free/Busy</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let agent of allRiders">
                            <td>#{{ this.params?.orderId }}</td>
                            <td><img class="img-fluid" width="50px" height="50px"
                                    [src]="agent?.profilePicture?.location" #img
                                    (error)="img.src='assets/images/userIcon.svg'" alt="img"> </td>
                            <td>{{ agent.name }} </td>
                            <td>{{ agent.email }} </td>
                            <td>{{agent.phoneCode}}{{ agent.phone }} </td>
                            <td>
                                <div class="orderStatus">
                                    <label class="orderLabel"
                                        [ngClass]="{'curbside': agent.isFree === true,'declined': agent.isFree === false}">{{
                                        agent.isFree ? 'Free' : 'Busy' }}</label>
                                </div>
                            </td>
                            <td>
                                <div class=""><button class="f14 blank-btn" [disabled]="loading.form || !(agent.isFree)"
                                        [ngClass]="{'disabled': loading.form || !(agent.isFree)}"
                                        (click)="assignRider(agent._id)">Assign
                                        Rider</button></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="this.isQuiqUp && quiqUpData?.job?.jobId">
            <ul class="list-ul">
                <li><span>Job Id: </span>
                    <div>
                        <p>{{ quiqUpData?.job?.jobId }}</p>
                    </div>
                </li>
                <li><span>Order Id:</span>
                    <div>
                        <p>#{{ quiqUpData?.job?.orderId }}</p>
                    </div>
                </li>
                <li><span>Status:</span>
                    <div>
                        <p *ngFor="let time of quiqUpData?.job?.timeline">{{ time.status }} - {{ time.date | date:
                            'mediumDate' }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="" *ngIf="allRiders?.length === 0 && !quiqUpData?.job?.jobId">
            <p class="m-48 text-center text mt-3">No Rider available <button class="" *ngIf="this.isQuiqUp"
                    (click)="createQuiqupRider()">Create Rider</button></p>
        </div>
    </div>
</div>
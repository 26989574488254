import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-merchant-invoice-popup',
  templateUrl: './merchant-invoice-popup.component.html',
  styleUrls: ['./merchant-invoice-popup.component.scss']
})
export class MerchantInvoicePopupComponent implements OnInit {

  constructor(
    private popupService: BsModalService
  ) { }

  params;
  allTransactions: any[] = [];
  currencycode;

  ngOnInit(): void {
    if(this.params.brandId){
      const brand = JSON.parse(localStorage.getItem('_brand'));
      if(brand && brand.length && this.params.brandId){
        const foundData = brand.find(brand => brand?._id === this.params.brandId);
        if(foundData){
          this.currencycode = foundData?.currency?.name;
        }
        }
    }
    this.allTransactions = this.params.transactions;
  }

  close(){
    this.popupService.hide();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpRequestService } from 'src/app/core';

@Component({
  selector: 'app-sales-channel-popup',
  templateUrl: './sales-channel-popup.component.html',
  styleUrls: ['./sales-channel-popup.component.scss']
})
export class SalesChannelPopupComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private httpRequestService: HttpRequestService,
    private popupService: BsModalService
  ) { }

  formGroup: FormGroup = this.formBuilder.group({
    showMessage:   new FormControl(false, []),
  });

  params;

  ngOnInit(): void {
  }

  submit(){
    const type = this.params.type;
      if (type === 1) {                   //type 1 Website Ordering
        this.router.navigate(['/website-sales'],{queryParams: {brandId: this.params?.brandId}});
      }else  if (type === 2) {            //type 2 Instagram
        this.router.navigate(['/instagram'],{queryParams: {brandId: this.params?.brandId}});
      }else  if (type === 3) {                   //type 3 Facebook
        this.router.navigate(['/facebook'],{queryParams: {brandId: this.params?.brandId}});
      }else  if (type === 4) {                   //type 4 Whats App
        this.router.navigate(['/whats-app'],{queryParams: {brandId: this.params?.brandId}});
      }else  if (type === 5) {                   //type 5 Messenger
        this.router.navigate(['/messenger'],{queryParams: {brandId: this.params?.brandId}});
      }else  if (type === 6) {                   //type 6 Google My Business
        this.router.navigate(['/google-my-account'],{queryParams: {brandId: this.params?.brandId}});
      }else{
        this.router.navigate(['tik-tok'],{queryParams: {brandId: this.params?.brandId}});
      }
      this.popupService.hide();
  }

  // pickupSettings(){
  //   this.router.navigate(['/pickup-settings'], {queryParams: {brandId: this.params.brandId}});
  //   this.popupService.hide();
  // }

}

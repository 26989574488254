<div class="">
    <div class="modal-body">
        <form [formGroup]="formGroup">
            <div class="acceptBody">
                <h4>Cash to collect: {{ params?.currencyCode }} {{ params?.toPay }}</h4>
                <!-- <div class="acceptIcon">
                    <img class="img-fluid" src="assets/images/timerIcon.svg" />
                </div> -->
                <p class="text">Amount</p>
                <div class="col-md-6 margindiv">

                    <div class="form-in">
                        <div class="currencyLabel">
                            <input type="number" formControlName="amount"
                                placeholder="ex. 30" /><span>{{this.params?.currencyCode}}</span>

                        </div>
                        <div>
                            <span class="error"
                                *ngIf="formGroup.get('amount').errors?.required && formGroup.get('amount').touched ">Amount
                                is required</span>
                            <span class="error" *ngIf="formGroup.get('amount').errors?.invalidAmount && formGroup.get('amount').touched">Amount should be equal to the payable amount</span>
                            
                        </div>
                    </div>
                </div>
                <div>
                    <button class="btn next" (click)="onSubmit()" [disabled]="loading.form"
                        [ngClass]="{'loader': loading.form}">Collect</button>
                </div>
            </div>
        </form>
    </div>
</div>
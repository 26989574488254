import { Component, OnInit } from '@angular/core';
import { Router, Routes } from '@angular/router';

@Component({
  selector: 'app-contact-thankyou',
  templateUrl: './contact-thankyou.component.html',
  styleUrls: ['./contact-thankyou.component.scss']
})
export class ContactThankyouComponent implements OnInit {

  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  submit(){
    this.route.navigate(['/login']);
  }

}
